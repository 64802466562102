.highcharts-gauge-main-number {
  font-family: "Poppins SemiBold", sans-serif !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 36px !important;
  letter-spacing: -0.404406px !important;
  color: #2C405A;
}

.highcharts-gauge-main-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #696974;
  border-radius: 4px
}

.highcharts-gauge-small-title {
  font-family: "Poppins SemiBold", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px
}

.highcharts-gauge-loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
