@use '@angular/material' as mat;
@use "projects/shared/src/assets/styles/components/colors" as colors;

@mixin toolbar-theme($theme, $toolbar-height) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $card-background: mat.get-color-from-palette($background, card);
  $border: 1px solid mat.get-color-from-palette($foreground, divider);
  $text-color: mat.get-color-from-palette($foreground, text);

  mat-toolbar.main-toolbar {
    padding: 0;
    background-color: $card-background !important;
    box-shadow: colors.$black-08 0 3px 4px;
    position: sticky;
    top: 0;
    z-index: 1000;

    mat-toolbar-row {
      padding: 0 16px;
      min-height: $toolbar-height;

      mat-nav-list.list-horizontal {
        padding: 0;
        width: 100%;

        .mat-list-item {
          display: inline-block;
          height: auto;
          width: auto;

          &:first-child {
            &.mat-list-item-content {
              padding: 0 5px 0 0;
            }
          }

          &:last-child {
            &.mat-list-item-content {
              padding: 0 0 0 5px;
            }

            a {
              color: $text-color;
              text-decoration: none;
            }
          }


          &:not(:first-child):not(:last-child) {
            &.mat-list-item-content {
              padding: 0 5px 0 5px;
            }
          }
        }

        a {
          padding: 0;
          color: $text-color;
          font-size: 14px;
          text-decoration: underline;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .arrow {
        color: $text-color;
        font-size: 14px;
      }

      header {
        box-shadow: none !important;
      }
    }
  }
}
