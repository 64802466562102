@use '@angular/material' as mat;

@mixin sim-info-labels($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $chip-background: mat.get-color-from-palette($foreground, disabled);
  $chip-text-color: mat.get-color-from-palette($background, card);

  .divider-tune {
    height: 24px;
    margin: 0 8px 0 4px;
  }
}
