@use 'projects/shared/src/assets/styles/components/colors' as c;

@mixin scrollbar($scroll-width: 7px, $border-radius: 20px) {
  ::-webkit-scrollbar {
    height: $scroll-width;
    width: $scroll-width;
  }

  ::-webkit-scrollbar-track {
    background: map-get(c.$scrollbar, 'track');
  }

  ::-webkit-scrollbar-thumb {
    background: map-get(c.$scrollbar, 'thumb');
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: map-get(c.$scrollbar, 'thumb-hover');
  }
}
