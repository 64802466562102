@use '@angular/material' as mat;

@mixin default-table-header($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $icon-color: mat.get-color-from-palette($foreground, icon);

  $size-base: 10px;

  .default-table-header-v1 {
    min-height: 4 * $size-base;
    margin-bottom: $size-base;

    .title-wrapper {
      height: 100%;
      flex-grow: 2;
    }

    .selected-from-text,
    .filtered-by-text {
      margin-left: $size-base * 2;
      font-weight: 500;
    }

    .system-button {
      &__icon {
        font-size: 24px;
        color: $icon-color;
      }

      .header-button-icon {
        color: inherit;
      }

      &.mat-stroked-button {
        background-color: mat.get-color-from-palette($background, card);
      }
    }

    .filtered-by-text {
      margin-right: $size-base;
    }

    mat-chip-list {
      display: inline-block;

      .mat-chip-remove {
        line-height: initial;
      }
    }

    .text-search-container {
      margin-right: 16px;
    }

    .action-label {
      margin-left: $size-base
    }

    .filter-label {
      margin-left: $size-base * .5;
    }
  }
}
