@use '@angular/material' as mat;
@use './colors' as colors;

@mixin table-component($theme, $typography) {
  $size-base: 10px;
  $cell-space: $size-base * 2 + 4px;
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $size-base: $size-base;
  $border: $size-base * 0.1 solid mat.get-color-from-palette($foreground, divider);
  $cell-padding: 0 $cell-space 0 0;
  $action-cell-padding: 0 $size-base;
  $side-columns-width: 58px;
  $expand-icon-cell-padding: 0 5px;

  $main-text-color:  mat.get-color-from-palette($foreground, text);
  $dark-secondary-text: mat.get-color-from-palette($primary, 275);
  $card-background: mat.get-color-from-palette($background, card);
  $sub-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $icons-color: colors.$black-60;
  $table-row-odd: colors.$background-local;
  $table-row-hover: mat.get-color-from-palette($background, disabled-list-option);
  $side-columns-border: solid 1px mat.get-color-from-palette($foreground, divider);
  $table-border: solid 1px colors.$black-12;
  $row-shadow: 0 2px 4px 0 mat.get-color-from-palette($foreground, divider), 0 -2px 6px 0 colors.colorWithOpacity(colors.$gray-100, colors.$opacity-02);
  $column-shadow-right: 3px 0 3px 0 mat.get-color-from-palette($foreground, divider);
  $column-shadow-left: -3px 0 5px 0 mat.get-color-from-palette($foreground, divider);

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
  }
  .bd-table,
   bd-lazy-table {
    display: block;
    min-width: 100%;
    padding: 0;
    border-radius: 8px;
    border: $table-border;
    overflow: hidden;

    .table-header {
      height: 4 * $size-base;
      margin-bottom: $size-base;

      .title-wrapper {
        height: 100%;
        flex-grow: 2;

        .title-text {
          color: $main-text-color;
        }
      }

      .selected-from-text,
      .filtered-by-text {
        color: $sub-text-color;
        margin-left: $size-base * 2;
      }

      .system-button {
        margin: 0 0.5 * $size-base;
        min-width: 40px;

        &:last-of-type {
          margin-right: 0;
        }
        &.mat-stroked-button {
          background-color: $card-background;
        }
      }

      .bulk-action-button {
        font-size: 20px;
        color: $sub-text-color;
      }

      .filtered-by-text {
        margin-right: $size-base;
      }

      .text-search-container {
        margin-right: 16px;
      }

      ::ng-deep .text-search-container .mat-form-field-wrapper {
        position: relative;
        bottom: -6px;
      }
    }

    .table-wrapper {
      overflow: auto;

      .mat-mdc-table {
        overflow-x: scroll;
        min-width: 100%;
        border-collapse: separate;

        .mat-mdc-table-sticky {
          background-color: inherit;
        }

        .mat-mdc-header-row {
          @include additional-cell-padding;
          .mat-mdc-header-cell {
            border-bottom: none;
            color: $main-text-color;
            padding: $cell-padding;
            white-space: nowrap;

            &:first-of-type {
              padding-left: $cell-space;
            }

            // TODO FIX OR REMOVE
            .mat-sort-header-button {
              text-align: left;
            }

            &.text-row-sub-header-cell {
              .sub-header {
                padding: 0;
                color: $sub-text-color;
                font-weight: normal;
              }

              ::ng-deep .mat-sort-header-content {
                flex-direction: column;
                align-items: flex-start;
              }
            }


            &.expand-arrow-row-header-cell {
              z-index: 3 !important;
              box-shadow: $column-shadow-right;
              border-right: $side-columns-border;
              min-width: $side-columns-width;
              width: $side-columns-width;
              padding: $expand-icon-cell-padding;
            }
            &.bulk-checkbox-row-header-cell {
              padding: 0 12px;
              width: $size-base * 4;
              border-right: $side-columns-border;
              border-bottom: $side-columns-border;
              background-color: $card-background;
              text-align: center;
              z-index: 3 !important;
            }

            &.bulk-checkbox-row-header-cell-with-button {
              width: $size-base * 4;
              text-align: right;

              .bulk-checkbox-row-header-cell-menu-button {
                margin-left: 19px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 44px;
                height: 40px;
                cursor: pointer;
                border-radius: 4px;

                &:hover {
                  background-color: colors.$black-08;
                }

                .open-checkbox-menu-icon {
                  color: colors.$black-60;
                }
              }
            }

            &.data-row-header-cell {
              border-bottom: $border;
              .data-row-header-cell-inner-flex-wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              }

              &.progress-bar-row-header-cell {
                min-width: $size-base * 10;
              }

              &.avatar-row-header-cell {
                width: $size-base * 4;
              }
            }

            &.actions-row-header-cell {
              border-bottom: $border;
              width: 0;
              background-color: $card-background;
              padding: 0 !important;
              z-index: 3 !important;
              border-left: $side-columns-border;
              text-align: right;
            }
          }

          .sticky-column {
            z-index: 3 !important;
          }
        }

        .mdc-data-table__row:last-child .mat-mdc-cell {
          border-bottom: $border;
        }

        .mat-mdc-row {
          @include additional-cell-padding;
          &:nth-child(odd) {
            background-color: $table-row-odd;
          }

          transition: box-shadow 0.1s ease-in;

          &.data-row {
            &.clickable {
              cursor: pointer;
            }

            &.selected {
              background-color: $table-row-hover;
            }
          }

          &.expanded-element-row {
            height: 0;
            border: none;
          }

          &:hover {
            box-shadow: $row-shadow;
            transform: scale(1);
            border-top: none;
            .mat-mdc-cell {
              &.expand-arrow-row-cell {
                .expand {
                  &.expand-open {
                    opacity: 1;
                    color: $main-text-color;
                  }
                }
              }

              &.bulk-checkbox-row-cell {
                box-shadow: $row-shadow;
                z-index: 2;
              }

              &.actions-row-cell {
                box-shadow: $row-shadow;
                z-index: 2;
                padding: $action-cell-padding !important;
              }
            }
          }

          .mat-mdc-cell {
            color: $dark-secondary-text;
            padding: $cell-padding;
            background: inherit;

            &:first-of-type {
              padding-left: $cell-space;
            }

            &.expand-arrow-row-cell,
            &.bulk-checkbox-row-cell {
              padding: 0 12px;
              text-align: center;
              border-right: $side-columns-border;

              &:hover {
                box-shadow: $row-shadow;
                z-index: 2;
              }
            }

            &.expand-arrow-row-cell {
              border-right: $side-columns-border;
              width: $side-columns-width;
              min-width: $side-columns-width;
              padding: $expand-icon-cell-padding;

              .expand {
                display: inline-block;
                cursor: pointer;
                transition: all 0.3s;

                &.expand-open {
                  color: $sub-text-color;
                }

                &.expand-close {
                  transform: rotate(180deg) translateY(5px);
                  color: $main-text-color;
                }
              }
            }

            &.expanded-element-row-cell {
              border-bottom: none;

              .expanded-element-row-cell-element-detail {
                overflow: hidden;
                display: flex;

                .expanded-element-row-cell-element-content {
                  padding: $size-base;
                  width: 100%;
                }
              }
            }

            &.actions-row-cell {
              padding: $action-cell-padding !important;
              width: 0;
              border-left: $side-columns-border;
              text-align: right;
            }
          }
        }

        .top-layer-vertical-shadow-right {
          box-shadow: $column-shadow-right;
        }

        .top-layer-vertical-shadow-left {
          box-shadow: $column-shadow-left;
        }
      }

      .expandable-table {
        tr.mat-mdc-row {
          background-color: $card-background;
          &:nth-child(odd) {
            background-color: $card-background;
          }
          &:nth-child(4n + 1) {
            background-color: $table-row-odd;
          }
          &:nth-child(4n + 2) {
            background-color: $table-row-odd;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .sticky-column {
      box-shadow: $column-shadow-right;
    }

    .mat-mdc-paginator {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .mat-mdc-paginator-container {
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        align-items: center;

        .mat-mdc-paginator-range-label,
        .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
        .mat-mdc-paginator-page-size-label {
          @include mat.typography-level($typography, 'body-2');
        }

        .mat-mdc-form-field-infix {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
          min-height: 36px !important;
        }
      }
    }



    .mat-sort-header-content {
      text-align: start;
    }

    .mat-card {
      border-radius: 0;
    }
  }

  .integrated-table-layout {
    border-left: none;
    border-right: none;
    border-radius: unset;
  }

  div[aria-labelledby*="mat-paginator-page-size-label-" i] {
    .mdc-list-item__primary-text {
      font-size: 14px !important;
    }
  }

  .action-label {
    margin-left: $size-base
  }

  .filter-label {
    margin-left: $size-base * .5;
  }

  .manage-plans {
    .table-header {
      margin-bottom: 2rem !important;
    }
    .mat-mdc-cell {
      &:first-child {
        width: 10px;
        color: $icons-color !important;
        font-size: 24px !important;
      }
      &:last-child {
        color: $icons-color !important;
        text-align: end;
      }
    }
    .blue-last-line {
      .mat-mdc-row:last-child {
        background-color: mat.get-color-from-palette($accent, 700) !important;
        cursor: pointer;
      }
    }
    .clean-table {
      tr, td {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  tr {
    &.disabled {
      background-color: colors.$background-row !important;

      .mat-cell {
        color: colors.$gray-38;
      }
    }
  }
}

@mixin additional-cell-padding {
  .additional-cell-padding {
    &:nth-child(2) {
      padding-left: 25px !important;
    }
  }
}
