@use "../components/colors" as colors;

body {
  margin: 0;
  background-color: colors.$background-100;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  border-collapse: collapse;
  outline: none;
}
