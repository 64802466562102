@use '@angular/material' as mat;

@mixin linked-offers($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $white: mat.get-color-from-palette($background, card);

  .sim-linked-offers-details-dialog {

    .mat-card {
      position: relative;
      padding: 0;
      line-height: 24px;
    }

    .mat-card-header {
      position: sticky;
      right: 0;
      top: 0;
      background: $white;
      display: block;
      z-index: 100;
      background: mat.get-color-from-palette($primary, 350);

      .mat-h5 {
        font-size: 14px;
        color: $secondary-text-color;
      }
    }

    .offer-details-title {
      color: mat.get-color-from-palette($primary, 250);
    }


    .mat-dialog-container {
      padding: 0;
      overflow-x: hidden;
    }
  }

  .mat-card-border {
    box-shadow: none!important;
    border: 1px solid mat.get-color-from-palette($primary, 150);
  }

  sim-linked-offers-select {
    .mat-column-__dragAndDropColumn__ {
      border-right: none !important;
      padding: 0 10px !important;
    }
  }

  .linked-offers-popover {
    color: mat.get-color-from-palette($primary, 275);
    line-height: 18px;
    min-width: 225px;
    padding: 16px !important;
    position: relative;

    &:before {
      display: block;
      content: "";
      position: absolute;
      left: 25px;
      bottom: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: $white transparent transparent;
      border-width: 8px 8px 0;
    }
  }
}
