@use 'projects/shared/src/assets/styles/components/colors' as colors;

sim-sub-account-details {
  bd-header {
    h2 {
      margin-top: 12px !important;
      font: 400 32px / 36px Open Sans, sans-serif !important;
    }
  }
}

sim-notification-channels-type {
  .default-table-header {
    &__row_additional_title {
      padding: 0 !important;
      border: 0 !important;
    }

    &__row {
      background-color: unset;

      &_head {
        padding: 0 !important;
        border: 0 !important;
      }
    }
  }

}

.flo-tooltip-custom-width {
  .mdc-tooltip__surface {
    max-width: 430px !important;
    padding-left: 21px !important;
  }
}

.cdk-overlay-container {
  .popover-pie-chart {
    padding: 20px !important;
    scroll-padding: unset !important;
    overflow-y: auto;
    max-height: 230px !important;
  }

  .attached_offer__list {
    color: colors.$black-60;
  }
}


sim-sub-account-contact-list,
sim-terms-of-use {
  .bd-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: unset;
  }
}

sim-range-filter {
  .list .mat-form-field-label {
    white-space: normal;
    line-height: 1.5;
  }
}

sim-manage-plans {
  bd-lazy-table {
    border: none !important;

    h1 {
      font-size: 24px
    }
  }

  .create-plan-button .flo-button {
    min-width: 0;
    padding: 0;
    width: 40px;

    mat-icon {
      margin: 0;
    }
  }
}

res-sim-profile-list {
  bd-table {
    padding: 0 !important;
  }
}

.order-list-filters .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  min-width: 24em;
}

sim-order-details .header,
sim-batch-operation-list-details .header h1 {
  sim-flo-truncate-tooltip {
    .text-value-truncated {
      font-size: 32px;
      line-height: 18px;

      &__icon {
        color: colors.$gray-87;
        width: 24px;
        height: 24px;
        margin-left: 5px;
      }
    }
  }
}

sim-batch-operation-list-details .header h1 {
  sim-flo-truncate-tooltip {
    .text-value-truncated {
      &__icon {
        margin-top: 5px;
      }
    }
  }
}

sim-sim-list,
sim-order-list,
sim-batch-operation-list,
lib-ra-rule-list,
sim-sub-account-list,
sim-fees-list {
  .default-table-header__row_head {
    display: none !important;
  }

  p {
    margin: unset !important;
  }
}

sim-terms-of-use {
  .default-table-header__row_actions {
    display: none !important;
  }
}

sim-batch-operation-list {
  bd-lazy-table {
    tr {
      cursor: pointer;
    }
  }
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  border-bottom: 1px solid colors.$gray-16;
}

sim-batch-operation-list-details {
  .default-table-header__row {
    background: white;

    &_head {
      max-height: initial;
      padding: 0;

      .title-wrapper {
        width: 100%;
      }
    }
  }

  lib-header-filter-chips {
    display: none;
  }

  lib-custom-header {
    .sub-title {
      order: 1;
      font-size: 12px !important;
      line-height: 12px !important;
      color: colors.$gray-60 !important;
      border: 1px solid colors.$gray-16;
      border-radius: 22px;
      padding: 0.313rem 0.5rem;
      margin-top: 7px;
    }
  }
}

sim-terms-of-use {
  .mat-row,
  .mat-cell {
    border-bottom: none !important;
  }
}


sim-sim-list {
  sim-flo-truncate-tooltip span,
  .mat-column-country bd-icon-column-cell {
    white-space: nowrap;
  }
}

sim-audit-trail {
  .default-table-header__row_head {
    max-height: 80px;
  }

  .mat-slide-toggle-label {
    align-items: baseline;
  }

  .mat-slide-toggle-bar {
    top: 4px;
  }
}

.filters-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}

lib-less-greater-side-filter {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    height: 3.2rem;
  }
}

sim-settings-users-list {
  tr.disabled {
    .role-label {
      background: colors.$gray-06 !important;
      padding: 8px !important;

      &:before {
        display: none;
      }
    }
  }
}

bd-nav-breadcrumbs {
  .mdc-list-item__primary-text {
    font-size: 10px !important;
    line-height: 12px !important;
  }
}

sim-usage-filter-item {
  .usage-value-input {
    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 0;
    }
  }
}

sim-device-operations-dialog {
  .mat-mdc-tab-header {
    border-bottom: 1px solid colors.$gray-16;
  }
}

.table-header-filter-panel-popover {
  overflow-y: auto;
}

sim-notification-logs {
  .default-table-header__row_head {
    min-height: 6.5em;
  }
}

lib-registration {
  .mat-mdc-form-field-infix {
    display: flex;
  }

  .mat-mdc-text-field-wrapper {
    height: auto !important;
  }
}

.form-disabled-styles {
  .mat-form-field-disabled .mdc-floating-label {
    color: colors.$black-60 !important;
  }

  .mat-mdc-select-disabled {
    color: colors.$black-87;
  }

  .mat-mdc-select-disabled .mat-mdc-select-arrow {
    color: colors.$black-16 !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    background: none;

    .mdc-text-field__input {
      color: colors.$black-87;
    }

    .mdc-line-ripple::before {
      border-color: colors.$black-16;
    }
  }
}

.field-label {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-floating-label:not(.mdc-floating-label--float-above ) {
      top: 20px;
    }
  }
}

.ec-minicart__icon {
  color: colors.$black-60 !important;
}

.ec-minicart__icon svg {
  width: 70% !important;
  height: 70% !important;
}

.ec-minicart__icon .icon-default path[stroke], html#ecwid_html body#ecwid_body .ec-minicart__icon .icon-default circle[stroke] {
  stroke: colors.$black-60 !important;
  transition: stroke .2s ease 0s
}

.ec-minicart--no-shape {
  padding: 0 0 1px 0 !important;
}

.nested-plan {
  .flo-button.flo-special-icon-text-btn {
    min-width: 40px !important;
    padding: 6px 7px !important;
  }
}

.mdc-dialog {
  &__actions {
    padding: 16px !important;
  }
}


.mat-mdc-autocomplete-panel {
  .mat-mdc-option.mdc-list-item {
    label {
      font-size: 16px;
    }
  }
}


.ec-minicart__counter {
  position: absolute;
  top: 0 !important;
  left: 22px !important;
  font-size: 9px !important;
  height: 14px;
  width: 14px;

  &::after {
    background-color: colors.$status-red-100 !important;
  }
}

sim-product-allocation-list {
  bd-lazy-table {
    border: 0;
  }

  .default-table-header {
    &__row {
      background-color: transparent;

      &_head {
        display: none !important;
      }
    }
  }
}

.select-options-with-info {
  .mdc-list-item__primary-text {
    display: flex;
    gap: 5px;
  }
}

sim-product-allocation-edit {
  .flo-dialog__header {
    justify-content: flex-start;
    padding: 10px 0;

    button {
      position: absolute;
      right: 16px;
    }

    flo-chip-text {
      margin-top: 3px;
    }
  }
}
