@use '@angular/material' as mat;
@use 'projects/shared/src/assets/styles/components/colors' as colors;

@mixin portal-toolbar-component($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, accent), 500);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $border: mat.get-color-from-palette($foreground, divider);
  $text: mat.get-color-from-palette($foreground, text);
  $size-base: 10px;

  .search-string-bold-color {
    color: $primary;
  }

  .main-toolbar {
    .drawer-button {
      margin: 0 20px;
    }

    .flo-toolbar-logo {
      height: 65%;
      align-self: center;
    }

    .search {
      &__field {
        width: 370px;
        justify-content: start;

        mat-icon {
          color: colors.$black-60;
        }
        .toolbar-search-close-icon {
          color: colors.$gray-38;
        }
      }

      &__input {
        width: 370px - 40px;
      }
    }

    .user-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 5px;

      .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &__avatar {
          vertical-align: middle;
          margin: 0 5px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        &__initials {
          margin: 0 5px;
        }

        &__name {
          .user-name {
            margin: 0;
            line-height: 20px;
            color: $secondary-text;
          }

          .user-company {
            margin: 0;
            line-height: 15px;
            color: $secondary-text;
          }

        }

        &__arrow {
          margin-left: 5px;
          color: $secondary-text;
        }
      }
    }

    .toolbar-menu-item {
      width: 193px;
      color: $secondary-text;
    }

    @media only screen and (max-width: 600px) {
      mat-toolbar-row {
        padding: 0 !important;
      }

      .sims-search {
        &__input {
          width: 100% !important;
        }
      }

      .user-menu {
        padding: 0 20px;
        margin: 0 20px !important;
      }
    }

    .search {
      &__container {
        margin-right: 10px;

        .table-header__button_default {
          position: relative;
          width: 1.5em;
          height: 1.5em;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .toolbar-search-optgroup {
    border-top: 1px solid $border;
    display: block;

    &:first-child {
      border-top: none;
    }

    .mat-optgroup-label {
      font-weight: 400;
      color: $text;
    }

    .mat-option {
      font-size: 16px;
    }
  }

  .user-menu-list {
    .mat-mdc-menu-item .mat-icon {
      width: 19px;
      margin-right: 10px;
    }
  }

  .user-info__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: colors.$primary-100;
    color: colors.$white-100;
    font-size: 16px;
  }
}
