@use "colors" as colors;

@mixin lib-bss-popover($theme) {
  .lib-bss-popover {
    padding: 22px !important;
    position: relative;
    font: 14px/21px Roboto, sans-serif;
    color: colors.$black-60;

    &:before {
      display: block;
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &.arrow-left-top {
      &:before {
        border-color: transparent transparent colors.$white-100;
        border-width: 0 8px 8px;
        left: 18px;
        top: -8px;
      }

      &.gap-small {
        &:before {
          left: 6px;
        }
      }
    }

    .title-text {
      color: colors.$black-87;
    }

    .warning-text {
      color: colors.$status-red-100;
    }
  }
}
