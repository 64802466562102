@use '@angular/material' as mat;
@use '../themes/typography/typography' as flo-typography;

@mixin sim-details-component($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $dark-secondary-text: mat.get-color-from-palette($primary, 275);
  $icon-color: mat.get-color-from-palette($foreground, icon);
  $table-row-odd: mat.get-color-from-palette($background, app-bar);

  $size-base: 10px;

  bd-header.sim-details-header {
    margin-bottom: 20px;
    display: block;

    nav.mat-tab-nav-bar {
      border-bottom-width: 0;
    }
  }

  .sim-details-header {
    &__info {
      padding: 0 10px;
    }

    &__icon {
      color: $icon-color;
    }

    .info-field-label {
      color: $secondary-text-color;
    }

    .info-field-value {
      color: $dark-secondary-text;
      font-weight: 500;
    }

    .flo-status {
      font-size: 14px;
      padding: 5px 10px;
    }

    .alias-name-field {
      @include flo-typography.set-font-style(flo-typography.$FONT_SECONDARY_32_36_400_1);
    }

    .expandable-msisdn-imsi-list {
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      transition: background-color .25s;

      &:hover {
        background-color: $table-row-odd;
        padding-bottom: 7px;
        border-bottom: 1px solid $divider-color;
      }
    }

    &__separator {
      padding: 0 8px;
      font-size: 20px;
      font-weight: 100;
      color: $divider-color;
    }

    .height64px {
      height: 64px;
    }

    .action-label {
      margin-left: $size-base;
    }

    .sim-alias {
      margin-right: 10px;
      font-size: 32px;
      font-family: Open Sans, sans-serif;
    }
  }
  .sim-details-msisdn-imsi-list.mat-mdc-menu-panel {
    max-width: none;
  }

  .sim-details-msisdn-imsi-list {
    &-item {
      height: 40px;
      padding: 8px 0;
      width: 390px;

      .sim-details-header__separator {
        padding: 0 8px;
      }

      &-text-imsi {
        padding-left: 20px;
      }

      &-text-msisdn {
        padding-right: 20px;
      }

      .info-field-label {
        color: $secondary-text-color;
      }

      .info-field-value {
        font-weight: 500;
        color: $dark-secondary-text;
      }
    }
  }

  .sim-imeisv {
    margin-top: 0.5em;
    margin-left: -12em;
    padding: 0.5em 1em;
  }
}
