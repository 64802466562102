@use '@angular/material' as mat;
@use "../../../assets/styles/components/colors" as colors;

@mixin flo-icon($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  $name: flo-icon;

  .#{$name} {
    display: inline-block;
    text-align: center;

    &--background--green {
      background-color: colors.$status-green-100;
    }

    &--background--red {
      background-color: mat.get-color-from-palette($warn);
    }

    &--background--gray {
      background-color: colors.$black-60;
    }

    &--background--orange {
      background-color: colors.$orange;
    }

    &--button {
      padding: 2.5px 10px;
      border-radius: 5px;
      cursor: pointer;
    }

    &--big {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      padding-top: 5px;

      .flo-icon__text {
        line-height: 35px;
        font-size: 18px;
      }
    }

    &--green svg path {
      background-color: colors.$status-green-100;
      fill: colors.$status-green-100;
      fill-opacity: 1;

      .#{$name}__text {
        fill: rgb(61, 213, 152);
        color: rgba(61, 213, 152);
      }
    }

    &--gray svg path {
      background-color: colors.$black-60;
      fill: colors.$black-60;
      fill-opacity: 1;

      .#{$name}__text {
        fill: colors.$black-60;
        color: colors.$black-60;
      }
    }

    &--white {
      color: colors.$white-100;
      fill: currentColor;
      fill-opacity: 1;

      .#{$name}__text {
        fill: colors.$white-100;
        color: colors.$white-100;
      }
    }

    &--red svg path {
      fill: mat.get-color-from-palette($warn);
      fill-opacity: 1;

      .flo-icon__text {
        fill: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn);
      }
    }

    &--light-red {
      background-color: rgba(254, 77, 151, .1);

      .flo-icon__text {
        fill: rgb(254, 77, 151);
      }
    }

    &--yellow svg path {
      fill: rgb(250, 197, 66);

      .flo-icon__text {
        fill: rgb(250, 197, 66);
        color: rgb(250, 197, 66);
      }
    }

    &--orange {
      background-color: rgba(255, 151, 74, .1);

      .flo-icon__text {
        fill: rgb(255, 151, 74);
      }
    }

    &--indigo {
      background-color: rgba(171, 125, 246, 0.1);

      .#{$name}__text {
        fill: rgb(171, 125, 246);
      }
    }

    &--blue {
      background-color: rgba(77, 124, 254, .1);

      .flo-icon__text {
        fill: rgb(77, 124, 254);
      }
    }

    &--light-blue {
      background-color: rgba(80, 181, 255, .1);

      .flo-icon__text {
        fill: rgb(80, 181, 255);
      }
    }

    &--without-inner-text {
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 5px;
      font-size: 16px;

      &.#{$name}--green {
        color: colors.$status-green-100;
      }

      &.#{$name}--gray {
        color: colors.$black-60;
      }

      &.#{$name}--red {
        color: mat.get-color-from-palette($warn);
      }

      &.#{$name}--yellow {
        color: rgb(250, 197, 66)
      }
    }
  }
}
