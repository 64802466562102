@use '@angular/material' as mat;
@use 'projects/shared/src/assets/styles/components/colors' as colors;

@mixin flo-select-search ($theme) {
  $background: map-get($theme, background);
  $menu-side-padding: 16px;

  .flo-select-search {
    align-items: center;
    background: mat.get-color-from-palette($background, card);
    border-bottom: 1px solid #cccccc;
    display: flex;
    position: sticky;
    gap: 4px;
    padding: 10px $menu-side-padding;
    top: 0;
    width: 100%;
    z-index: 1;

    .input {
      width: inherit;
      color: colors.$black-87;
      border: unset;
      height: 26px;
      &::placeholder {
        color: colors.$gray-38;
      }
    }

    .search-icon {
      color: colors.$gray-38;
      flex-shrink: 0;
      height: 20px;
      width: 20px;
    }

    .cancel-icon {
      flex-shrink: 0;
      line-height: 20px;
      width: 20px;
    }

    .small-icon-button {
      width: 20px !important;
      height: 20px !important;
      padding: 0 !important;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
    }

    .hidden {
      visibility: hidden;
    }
  }

  .thin-select {
    padding: 6px 16px;

    .input {
      font-size: 14px !important;
      height: 26px;
    }
  }

  .no-entries-found {
    padding: $menu-side-padding;
  }

  .create-new {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 1em;
    font-size: 16px;
    cursor: pointer;

    .plus {
      height: 25px;
      width: 30px;
      font-size: 24px;
      border-radius: 4px;
      color: colors.$white-100;
      background-color: colors.$primary-100;
      line-height: 1;
      text-align: center;
      vertical-align: center;
    }
  }
}





