@use "projects/shared/src/assets/styles/components/colors" as colors;

.flo-snack-bar {
  min-width: 10px;
  color: colors.$white-100 !important;

  .mat-button {
    color: colors.$white-100 !important;
  }

  &__success {
    background-color: #28a745 !important;
  }

  &__error {
    background-color: #d05764 !important;
  }

  &__info {
    background-color: #17a2b8 !important;
  }

  &__warning {
    background-color: #FFA87D !important;
  }
}
