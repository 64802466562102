@use '@angular/material' as mat;
@use 'colors' as colors;

@mixin billing-invoice($theme) {
  .billing-invoice {
    .billing-invoice__header {
      .mat-mdc-tab-link-container {
        padding: 0 15px 50px;

        .mat-mdc-tab-link {
          border-bottom: 1px solid colors.$gray-16;
        }
      }
    }
  }

  .eligible-billing-cycle-definitions {
    .info-card-wrapper {
      margin-bottom: 0 !important;
    }

    .button-update {
      position: relative;
      margin: -220px 15px 0 0;
      z-index: 2;
    }

    .bd-lazy-table {
      padding: 0;
      box-shadow: none;
      border-top: none;
      border-radius: 0;

      .default-table-header__row_head {
        padding: 0;
      }
    }
  }

  .billing-invoice-popover {
    padding: 22px !important;
    position: relative;
    font: 14px/21px Roboto, sans-serif;
    color: colors.$black-60;

    &:before {
      display: block;
      content: "";
      position: absolute;
      right: 25px;
      top: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent colors.$white-100;
      border-width: 0 8px 8px;
    }

    .warning-text {
      color: colors.$status-red-100;
    }
  }
}
