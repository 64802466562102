.flo-progress-bar {
  border-radius: 4px;

  .mdc-linear-progress__buffer-bar {
    background-color: #E2E2EA !important;
  }

  &--green {
   .mdc-linear-progress__bar-inner {
      border-color: #2ED47A !important;
    }
  }

  &--yellow {
    .mdc-linear-progress__bar-inner {
      border-color: #FACA00 !important;
    }
  }

  &--red {
    .mdc-linear-progress__bar-inner {
      border-color: #FC5A5A !important;
    }
  }
}
