@mixin sidebar-filters-dialog($theme) {
  $header-height: 64px;
  $filters-header-height: 64px;
  $filters-footer-height: 64px;
  $height: calc(100vh - #{$header-height});

  .sidebar-filters-dialog {
    height: $height;

    .mat-dialog-container {
      padding: 0;
      overflow-x: hidden;

      .mat-dialog-content {
        position: relative;
        margin: 0;
        padding: 0;
        overflow-y: auto;
        max-height: calc(#{$height} - #{$filters-header-height} - #{$filters-footer-height});
        height: calc(#{$height} - #{$filters-header-height} - #{$filters-footer-height});
      }
    }

    .filters-container {
      max-width: 340px;
      width: 100%;
      max-height: $height;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mat-card-actions {
        margin: 0;
        height: $filters-footer-height;
        padding: 10px;
      }
    }

    .filters-titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $filters-header-height;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.0015em;
      padding: 14px 20px;
    }

    .mat-card-header {
      display: block;
      margin: 0;
    }

    .mat-flat-button {
      padding: 0 30px;
    }

    .date-range-field {
      width: 100%;
    }
  }
}
