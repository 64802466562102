@use 'projects/shared/src/assets/styles/components/colors' as colors;
@use 'projects/shared/src/assets/styles/themes/typography/typography' as flo-typography;

.tabs-outline {
  position: relative !important;
  padding-left: 14px;

  .mdc-tab__text-label {
    @include flo-typography.set-font-style(flo-typography.$FONT_PRIMARY_16_24_400_05);
  }

  .mat-mdc-tab-link {
    position: relative;
    z-index: 2;
    max-width: 50%;

    &.mdc-tab {
      border-radius: 8px 8px 0 0;
      border: solid 1px colors.$black-12;
      border-bottom-color: transparent;
      display: block;
      margin: 0 8px;
      transition: background ease 0.25s;
      padding: 0 36px;
      height: 52px;

      &--inactive {
        border-bottom-color: transparent;
        color: colors.$black-60;

        &:hover {
          cursor: pointer;
        }
      }

      &--active {
        background: white;
        border-color: colors.$primary-100;
        border-bottom-color: colors.$white-100;
        color: colors.$primary-100;

        &:hover,
        &:active,
        &:visited {
          background: white;
          border-bottom-color: colors.$white-100;
          color: colors.$primary-100;
          cursor: default;
        }
      }

      &:first-child &__link {
        margin-left: 20px;
      }

    }
  }

  .mdc-tab-indicator {
    display: none;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: colors.$black-16;
    z-index: 1;
    height: 1px;
  }
}

.table-tabs-outline {
  position: relative;

  .tabs-outline {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  .default-table-header__row_head {
    max-height: 140px;
    min-height: 140px;
    align-items: flex-end !important;

    .title-wrapper {
      margin-bottom: 65px !important;
    }
  }

  .default-table-header__row {
    background: colors.$white-100;
  }

  .table-tabs-container {
    position: absolute;
    left: 20px;
    top: 108px;
  }
}
