@use '@angular/material' as mat;
@use '../themes/typography/typography' as flo-typography;
@use '../components/colors' as colors;

@mixin themed-colors($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .color-primary {
    color: mat.get-color-from-palette($accent, 500) !important;
  }

  .disabled-form-field-text-color {
    color: var(--mdc-filled-text-field-disabled-label-text-color);
  }

  .color-secondary {
    color: mat.get-color-from-palette($accent, 500) !important;
  }

  .color-warn {
    color: mat.get-color-from-palette($warn) !important;
  }

  .color-accent {
    color: mat.get-color-from-palette($accent) !important;
  }

  .color-white {
    color: colors.$white-100;
  }

  .fill-primary {
    fill: mat.get-color-from-palette($accent) !important;
  }

  .background-primary {
    background-color: mat.get-color-from-palette($background, background) !important;
  }

  .background-card {
    background-color: mat.get-color-from-palette($background, card) !important;
  }

  .app-bar-color {
    background-color: mat.get-color-from-palette($background, app-bar) !important;
  }

  .background-secondary {
    background-color: mat.get-color-from-palette($accent, 500) !important;
  }

  .background-warn {
    background-color: mat.get-color-from-palette($warn) !important;
  }

  .background-accent {
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .flo-icon-color {
    color: mat.get-color-from-palette($foreground, icon);
  }

  .flo-search-icon-color {
    color: colors.$gray-38 !important;
  }

  .flo-light-icon-color {
    color: mat.get-color-from-palette($foreground, icon-light);
  }

  .mat-secondary-text-color {
    color: mat.get-color-from-palette($foreground, secondary-text) !important;
  }

  .mat-dark-secondary-text-color {
    color: mat.get-contrast-color-from-palette($primary, 300);
  }

  .mat-dark-secondary-divider-color {
    border-top-color: colors.$gray-38;
  }

  .flo-border-color {
    border-color: mat.get-color-from-palette($foreground, border) !important;
  }

  .mat-divider-background {
    background-color: mat.get-color-from-palette($foreground, divider);
  }

  .mat-hint-text-color {
    color: mat.get-color-from-palette($foreground, hint-text);
  }

  .flo-breadcrumbs-color {
    color: mat.get-color-from-palette($foreground, dark-secondary-text);
  }

  .default-header-colored-button-icon svg path {
    fill: currentColor;
    fill-opacity: .87;
  }

  .flo-vertical-divider {
    background-color: mat.get-color-from-palette($foreground, divider);
    height: 36px;
    width: 1px
  }

  .flo-horizontal-divider {
    background-color: mat.get-color-from-palette($foreground, divider);
    height: 1px;
    width: 100%;
  }

  .middle-sized-icon {
      height: 17.5px !important;
      width: 17.5px !important;
  }

  .storytelling-icon {
    height: 205px !important;
    width: 370px !important;
    color: mat.get-color-from-palette($primary, 500);
  }
}

@mixin flo-tooltip($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $box-shadow-color: mat.get-color-from-palette($primary, 525);
  $text-color: mat.get-color-from-palette($foreground, dark-secondary-text);
  $icon-color: mat.get-color-from-palette($foreground, icon-light);

  ._flo-tooltip {
    background-color: mat.get-color-from-palette($background, card) !important;
    padding: 12px !important;
    color: $text-color !important;
    max-width: 370px !important;
    border-radius: 4px;
    word-wrap: normal;
    word-break: break-word;
    white-space: pre-line;
    @include flo-typography.set-font-style(flo-typography.$FONT_PRIMARY_14_24_400_025);
    box-shadow: 4px 4px 12px 6px $box-shadow-color;
  }

  .flo-tooltip-color {
    color: $text-color;
  }

  .flo-tooltip-icon-color {
    color: $icon-color;
  }

  .flo-tooltip-font {
    @include flo-typography.set-font-style(flo-typography.$FONT_PRIMARY_14_24_400_025);
  }

  .flo-truncate-tooltip {
    .mdc-tooltip__surface {
      background-color: mat.get-color-from-palette($background, card) !important;
      color: $text-color !important;
      border-radius: 4px;
      max-width: 225px;
      word-wrap: normal;
      word-break: break-word;
      @include flo-typography.set-font-style(flo-typography.$FONT_PRIMARY_14_24_400_025);
      box-shadow: 4px 4px 12px 6px $box-shadow-color;
      white-space: pre-line !important;
    }
  }

  .flo-custom-popup {
    @extend ._flo-tooltip;
  }

  .flo-sim-info-usage-tip {
    .mdc-tooltip__surface {
      @extend ._flo-tooltip;
    }
  }

  .flo-info-button {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  .whitespace-pre-line {
    white-space: pre-line;
  }
}

@mixin bd-card-header-title-theme($theme) {
  $primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $border: mat.get-color-from-palette($foreground, divider);

  .mat-mdc-card-header {
    width: 100%;

    .mat-mdc-card-header-text {
      width: 100%;
    }

    span.mat-card-header-avatar {
      color: $primary-color;
      font-size: 1.5rem;
      line-height: 30px;
      margin-left: 16px;
    }
  }

  sim-bd-icon-flat-button {
    .mat-flat-button {
      line-height: 21px !important;
      padding: 0 8px !important;
    }
  }

}

@mixin bd-dialog-actions-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary));
  $foreground: map-get($theme, foreground);
  $border-color: mat.get-color-from-palette($foreground, divider);
  $dialog-background: mat.get-color-from-palette(map-get($theme, background), dialog);
  $text-color: mat.get-color-from-palette(map-get($theme, accent), 500);

  sim-bd-dialog {
    .mat-dialog-actions {
      color: $text-color;
      border-top: 1px solid $border-color;
      background-color: $dialog-background;
      margin: 0 -24px -24px -24px;
      padding: 8px 24px 8px 96px !important;
    }
  }
}

@mixin bd-dialog-header-title-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary));
  $foreground: map-get($theme, foreground);
  $border-color: mat.get-color-from-palette($foreground, divider);
  $dialog-background: mat.get-color-from-palette(map-get($theme, background), dialog);
  $text-color: mat.get-color-from-palette(map-get($theme, accent), 500);

  sim-bd-dialog {
    .mat-dialog-title {
      background-color: $primary;
      color: $text-color;
      margin: -24px -24px 5px -24px !important;
      padding: 12px 24px 12px 24px;

      .mat-card-header-text {
        margin: 0;

        .mat-card-title {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

@mixin full-height {
  height: 100%;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin full-height-block {
  @include full-height;
  display: block;
}

@mixin grow-hidden {
  @include grow;
  @include hidden;
}

@mixin grow-show {
  @include grow;
  @include show;
}

@mixin full-column {
  @include column;
  height: 100%;
}

@mixin hidden {
  overflow-y: hidden;
}

@mixin show {
  overflow-y: auto;
}

@mixin grow {
  flex-grow: 1;
}

@mixin grow-column {
  @include column;
  @include grow;
}

@mixin full-column-hidden {
  @include full-column;
  @include hidden;
}

@mixin grow-column-hidden {
  @include column;
  @include grow-hidden;
}

