.relative {
  position: relative;
}

.w-100 {
  width: 100% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-100 {
  height: 100% !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-1000 {
  max-width: 1000px;
}

.min-width-200 {
  min-width: 200px;
}

.absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr) );
  grid-gap: 24px;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr) );
    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr) );
    }
  }
}

.comma-divided-list ~ .comma-divided-list::before {
  content: ', ';
}
