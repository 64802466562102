@use '@angular/material' as mat;
@use "projects/shared/src/assets/styles/components/colors" as colors;

@mixin sidenav-theme($theme) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $item-height: 40px;
  $active-item-flag: false;

  $item-base-padding: 24px;
  $active-item-border: 5px;
  $transition: 0.3s ease-in-out;

  .mat-sidenav-container {
    height: 100%;

    mat-sidenav {
      box-shadow: colors.$black-08 5px 65px 30px;

      .sidenav {
        padding-bottom: 60px;

        mat-nav-list {
          padding-top: 0;
          display: flex;
          flex-direction: column;
          row-gap: 5px;

          .toggle-menu-btn-wrapper {
            margin-left: 5px;
          }

          .mdc-list-item {
            height: 40px;
            &__primary-text {
              display: flex;
              align-items: center;
            }
          }
        }

        &__logo {
          padding: 10px 24px;
          text-align: left;
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: mat.get-color-from-palette($foreground, divider);
          height: 65px;


          img {
            height: 45px;
            width: auto;
          }
        }

        &__category-label {
          height: $item-height;
          margin-bottom: 0;
          margin-top: 10px;
          line-height: $item-height;
          color: mat.get-color-from-palette($foreground, secondary-text) !important;
          letter-spacing: 1px;
        }

        &__item,
        &__sub-item {
          position: relative;
          box-sizing: border-box;
          box-shadow: none !important;

          .nav-link-wrapper {
            padding: 0 16px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          .mat-expansion-panel-body {
            padding: 0;
          }

          &--without-children {
            @extend .sidenav-typography;
            display: block;
            height: $item-height;
            margin-bottom: 5px;
            text-decoration: none;
            border-left-width: 0;
            border-left-style: solid;
            border-left-color: mat.get-color-from-palette($accent, 500);
            width: 200px;
            margin-left: 27px;
          }
        }

        &__item-icon {
          height: 24px;
          width: 24px;
          color: mat.get-color-from-palette($foreground, icon);
        }

        &__item-label {
          @extend .sidenav-typography;
          margin-left: 13px;
          color: mat.get-color-from-palette($foreground, text);
        }

        .active {
          //color: mat.get-color-from-palette($primary);
          background-color: rgba(mat.get-color-from-palette($primary), 0.08);

          .path-active {
            stroke: mat.get-color-from-palette($accent, 500);
          }

          .path-active-fill {
            fill: mat.get-color-from-palette($accent, 500);
          }

          :hover {
            background-color: rgba(mat.get-color-from-palette($primary), 0.08) !important;
          }
        }

        .dot-expansion-panel {
          background-color: mat.get-color-from-palette($accent, 500);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin-left: 5px;
        }

        .selected {
          background-color: transparent !important;

          .span-with-children {
            color: mat.get-color-from-palette($accent, 500);
          }

          .i-with-children {
            color: mat.get-color-from-palette($accent, 500) !important;
          }

          .path-active {
            stroke: mat.get-color-from-palette($accent, 500) !important;
          }

          .path-active-fill {
            fill: mat.get-color-from-palette($accent, 500) !important;
          }

          .closed-sidebar-description-panel {
            background-color: mat.get-color-from-palette($accent, 700) !important;
            margin-right: 0;
          }
        }

        .submenu-icon {
          svg {
            position: absolute;
            height: 53px;
            width: 53px;
          }
        }

        .closed-sidebar-description-panel {
          height: $item-height;
          padding: 0 16px;
        }

        .closed-sidebar-expansion-header {
          display: initial;
          flex-direction: initial;
          align-items: initial;
          padding: initial !important;
          margin-left: 0 !important;

          .mat-expansion-indicator {
            display: none;
          }
        }

        .mat-expansion-panel-header {
          padding: 0 16px;
        }

        .closed-sidebar-without-children {
          mat-icon {
            svg {
              width: 24px;
            }
          }
        }
        .nav-link-wrapper:hover:not(.active),
        .mat-mdc-list-item:hover:not(.active),
        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 6px !important;

        }

        .mat-mdc-list-item.active:hover {
          background-color: colors.$primary-100 !important;
          border-radius: 6px !important;
        }

        &.impersonated {
          margin-top: 41px;
        }
      }
    }
  }
}
