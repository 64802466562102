@use '@angular/material' as mat;
@use '../../abstract/mixins' as m;

@mixin batch-operation-list-details($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary-btn-color: mat.get-color-from-palette($primary, 300);
  $primary-btn-border-color: mat.get-color-from-palette($accent, 500);
  $accent-btn-color: mat.get-color-from-palette($accent, 350);
  $accent-color-semi-opacity: mat.get-color-from-palette($accent, 400);
  /*
  provide this kind of colours in the theme
   */
  $light-yellow-row-background: rgba(249, 187, 82, 0.08);
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $icon-color: mat.get-color-from-palette($foreground, icon);
  $card-background-color: mat.get-color-from-palette($background, card);

  .batch-operation-list-details {
    @include m.full-column-hidden;
    padding: 3px;

    lib-custom-header {
      margin-bottom: 0.5em;

      lib-header-end {
        .actions-container {
          display: flex;

          > .mat-raised-button {
            display: flex;
            align-items: center;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;

            &:not(:last-of-type) {
              margin-right: 8px;
            }

            &.btn-delete {
              color: $primary-btn-color;
              border-color: $primary-btn-border-color;
              display: flex;
              align-items: center;
            }

            &.btn-refresh {
              color: $accent-btn-color;
              border-color: $accent-color-semi-opacity;
              display: flex;
              align-items: center;
            }

            span {
              margin-left: 6px;
            }
          }
        }
      }
    }

    .header-additional-row {
      display: flex;
      align-items: center;
      height: 88px;
      width: 100%;
      padding: 0 1em;
      gap: 0.5em;
      margin-top: 0.5em;

      .header-additional-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 16px;
        margin-right: 16px;
        border-bottom: 1px solid $divider-color;
        width: 380px;

        .label {
          color: $secondary-text-color;
        }
      }
    }

    bd-lazy-table {
      flex-grow: 1;

      .status-column-container {
        display: flex;
        justify-content: flex-end;

        button {
          background: $accent-btn-color;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          padding: 7px 16px;
          cursor: pointer;
        }
      }
    }
  }
}
