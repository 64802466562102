@use '@angular/material' as mat;
@use 'colors' as colors;

@mixin filters($theme) {
  $primary-theme: map-get($theme, primary);
  $accent-theme: map-get($theme, accent);
  $white-color: mat.get-color-from-palette($primary-theme, 50);
  $list-option-border: mat.get-color-from-palette($primary-theme, 450);
  $light-gray-color: colors.$background-local;
  $selection-list-border: mat.get-color-from-palette($primary-theme, 150);
  $selected-checkbox: mat.get-color-from-palette($accent-theme, 500);
  $mat-option-height: 35px;

  .filters-sidebar {
    height: 100%;

    .mat-dialog-container {
      padding: 0;
      overflow-x: hidden;
      border-radius: 0;

      .filters-container {
        max-width: 370px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .filters-titles {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 20px;
        line-height: 36px;
        background: $light-gray-color;
      }
    }

    .mat-card-header {
      display: block;
      margin: 0;
    }

    .mat-mdc-dialog-content {
      padding: 0;
      max-height: calc(5 * #{$mat-option-height});
      overflow-y: auto;
      position: relative;
      margin: 0;

      .mat-mdc-selection-list {
        border-radius: 5px;
        border: 1px solid $selection-list-border;
        margin-bottom: 20px;
        max-height: calc(6 * 40px);

        .mat-mdc-list-option {
          border-bottom: 1px solid $list-option-border;
          background-color: $light-gray-color;
          height: 40px !important;

          .mdc-list-item__content {
            padding: 0 !important;
            .mdc-list-item__primary-text {
              padding: 0 !important;
              color: colors.$black-87 !important;
            }
          }



          &:nth-child(2n) {
            background: $white-color;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .filters__search .mat-form-field .mat-form-field-flex {
        width: 330px !important;
        flex-direction: row-reverse;

        .mat-form-field-infix {
          width: auto;
        }
      }

      .mat-icon {
        width: 24px;
        height: 24px;
        color: colors.$gray-38;
      }

      .filter-title div,
      .filters__titles {
        font-weight: normal !important;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 2;
      }

      .mat-form-field {
        width: 100%;

        input.mat-input-element {
          padding: 5px 10px;
        }
      }

      #searchableList {
        .mdc-checkbox__background {
          background-color: $white-color;
          border-radius: 3px;
        }
        mat-list-option[aria-selected="true"] {
          .mdc-checkbox__background {
            background-color: var(--mdc-checkbox-selected-icon-color);
            color: colors.$primary-100;
            border-color: $selected-checkbox;
          }

        }
      }

      .date-range-field {
        width: 100%;
      }
    }
  }
}
