@use '@angular/material' as mat;
@use 'colors' as colors;

@mixin schedule-settings-input($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary));
  $warn-color: mat.get-color-from-palette(map-get($theme, warn));
  $form-field-border-color: mat.get-color-from-palette($foreground, disabled-text);
  $primary-text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $form-field-background: mat.get-color-from-palette($background, hover);
  $popover-background: mat.get-color-from-palette($background, card);
  $day-card-border: mat.get-color-from-palette($foreground, divider);
  $disabled-text-color: mat.get-color-from-palette($foreground, disabled);

  .schedule-settings-input {
    border: 1px solid $day-card-border;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 1;
    cursor: pointer;

    .tune-icon {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      margin-left: 12px;
    }

    &__warn {
      border-bottom-color: $warn-color;
      .inner-wrapper {
        padding-bottom: 0;
      }
    }

    &:hover {
      border-color: $form-field-border-color;
    }

    &:active {
      border-color: $primary-color;
    }

    .field-label {
      position: relative;
      top: 18px;
      border-top: 0.84375em solid transparent;
      padding: 0.25em 0.75em 0.75em 0.75em;
      line-height: 1.125;

      &__warn {
        color: $warn-color;
      }
    }

    .inner-wrapper {
      border-radius: 4px 4px 0 0;
      padding: 0.75em 16px 1px 16px;
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &-text-field {
        position: relative;
        top: 2px;
        border-top: 0.84375em solid transparent;
        padding: 0.25em 0 0.75em 0;
        line-height: 1.125;
      }

      .filled-field-label {
        color: $secondary-text-color;
        scale: 0.8;
        position: absolute;
        top: 6px;
        left: 0;
        font-size: 16px;

      }
    }
  }

  .scheduling-options-popup {
    height: fit-content;
    width: fit-content;
    border-radius: 4px;
    background-color: $popover-background;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &__small {
      width: 480px;
    }

    .popup-header {
      margin-bottom: 5px;
      color: $primary-text-color;
    }
    .period-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
    .radio-group-wrapper {
      display: flex;
      flex-direction: column;

      .radio-group {
        color: $secondary-text-color;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
        padding-bottom: 10px;

        .month-radio-button-text-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
        }
      }
    }

    .month-period {
      height: fit-content;
      max-width: 950px;
    }

    .days-list-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .day-card {
        cursor: pointer;
        height: 32px;
        width: 43px;
        padding: 4px 8px;
        border-radius: 6px;
        border: 1px solid $day-card-border;

        &__active {
          border-color: $primary-color;
          background-color: colors.$primary-02;
        }

        &__disabled {
          color: $disabled-text-color;
          cursor: default;
        }
      }

      .times-per-day-card {
        width: 84px;
      }
    }

    .action-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
}
