@use '@angular/material' as mat;
@use '../themes/typography/typography' as flo-typography;
@use "colors" as colors;

@mixin sim-profiles-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $text-color: mat.get-color-from-palette(map-get($theme, accent), 700);

  .sim-profiles {
    .mat-mdc-card-title {
      @include flo-typography.set-font-style(flo-typography.$FONT_SECONDARY_24_24_400_04);
    }

    .mat-elevation-z1 {
      box-shadow: none;
    }

    .mat-mdc-tab-group {
      display: flex;
      flex-direction: row;
      border: none;
    }

    .mat-mdc-tab-header {
      width: 285px;
      margin-right: 15px;
    }

    .mat-mdc-tab-list {
      max-width: 100%;
    }

    .mat-mdc-tab-labels {
      display: flex;
      flex-direction: column;
      max-height: 420px;
      overflow: auto;
      border: 1px solid colors.$black-16;
      border-radius: 6px;

      .mdc-tab {
        justify-content: flex-start;
        height: 70px;
        min-height: 70px;
        max-height: 70px;
        overflow: hidden;
        border-bottom: 1px solid colors.$black-16;
        padding: 0 10px;
        max-width: 100%;
        width: 100%;
        min-width: auto;
        text-align: left;
        position: relative;
        color: colors.$gray-87;
        opacity: 1;

        .label-color {
          color: colors.$gray-60;
        }

        .mdc-tab-indicator__content--underline {
          border: none;
        }

        .mat-icon {
          position: absolute;
          right: -11px;
          top: 50%;
          margin-top: -11px;
        }

        .active-tab {
          color: colors.$status-green-100;
          font-size: 12px;
          padding: 0 5px;
          background: colors.$status-green-06;
          margin-left: 5px;
          line-height: 20px;
          max-height: 20px;
        }

        .mdc-tab__content {
          width: 100%;
        }

        .mdc-tab__text-label {
          display: block;
          width: 100%;
        }

        &.mdc-tab--active {
          background: $text-color;
          color: initial;

          .mdc-tab__text-label {
            color: colors.$gray-87;
          }

          mat-icon {
            color: mat.get-color-from-palette($accent, 500);
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }


    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0;

      .mat-mdc-tab-body {
        flex-grow: 1;

        bd-table {
          border: 1px solid colors.$black-16;
          border-radius: 6px;
          box-shadow: none;

          tr {
            &.mat-mdc-header-row, &.mat-mdc-row, &.mat-mdc-footer-row {
              height: 42px;
            }

            &.mat-mdc-header-row {
              th.mat-mdc-header-cell {
                border-bottom: 1px solid colors.$black-16 !important;
              }
            }

            &.mat-mdc-row {
              &:last-child {
                td.mat-cell {
                  border: none;
                }
              }
            }
          }
        }
      }
    }

    .mat-mdc-ink-bar {
      display: none;
    }
  }
}
