@import "@mdi/font/css/materialdesignicons.min.css";
@import "bootstrap/dist/css/bootstrap-grid.min.css";
@import "flag-icon-css/css/flag-icon.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto-Black"), url("/assets/fonts/Roboto/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto-BlackItalic"), url("/assets/fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto-Bold"), url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto-BoldItalic"), url("/assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto-Italic"), url("/assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto-Light"), url("/assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto-LightItalic"), url("/assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto-Medium"), url("/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto-MediumItalic"), url("/assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto-Regular"), local(Roboto), url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Roboto-Thin"), url("/assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Roboto-ThinItalic"), url("/assets/fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Poppins Black"), url("/assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Poppins BlackItalic"), url("/assets/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Poppins Bold"), url("/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Poppins BoldItalic"), url("/assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Poppins ExtraBold"), url("/assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local("Poppins ExtraBoldItalic"), url("/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Poppins ExtraLight"), url("/assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local("Poppins ExtraLightItalic"), url("/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Italic"), url("/assets/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Poppins Light"), url("/assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Poppins LightItalic"), url("/assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins Medium"), url("/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins MediumItalic"), url("/assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular"), url("/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBold"), url("/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBoldItalic"), url("/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Poppins Thin"), url("/assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Poppins ThinItalic"), url("/assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Black";
  src: url("/assets/fonts/Lato/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Lato BlackItalic";
  src: url("/assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Bold";
  src: url("/assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato BoldItalic";
  src: url("/assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato HairLine";
  src: url("/assets/fonts/Lato/Lato-HairLine.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-HairLine.ttf") format("truetype");
}
@font-face {
  font-family: "Lato HairLineItalic";
  src: url("/assets/fonts/Lato/Lato-HairLineItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-HairLineItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Italic";
  src: url("/assets/fonts/Lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Light";
  src: url("/assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato LightItalic";
  src: url("/assets/fonts/Lato/Lato-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Regular";
  src: url("/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .search__input {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .default-table-header__row_filters-parameter .filtered-by-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

mat-selection-list .mat-list-item-content .mat-list-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-form-field-inherit-fill .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

mat-form-field .mat-mdc-form-field-icon-suffix {
  padding-bottom: 5px;
}

.full-width-expansion-panel-body .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: inherit !important;
}

.mat-ink-bar {
  background: var(--primary-color, #F69C00) !important;
}

.mat-step-icon-state-edit .mat-icon {
  color: inherit;
}

.mat-mdc-list-option .mdc-checkbox__checkmark {
  color: #FFFFFFDE !important;
}

.mat-mdc-dialog-surface {
  overflow-x: clip;
}

.mdc-list-item--selected .mat-pseudo-checkbox-minimal {
  display: none;
}

.mdc-switch__icons {
  display: none;
}

a:-webkit-any-link {
  color: inherit;
}

.no-subscript .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-slide-toggle .mdc-form-field > label {
  padding-left: 8px;
}

.mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full {
  margin-right: 8px !important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: inherit !important;
}

.mat-mdc-form-field.mat-focused:not(.mat-form-field-invalid) .mat-mdc-form-field-label,
.mat-mdc-form-field.mat-focused:not(.mat-form-field-invalid) .mat-mdc-form-field-required-marker {
  color: rgba(26, 26, 38, 0.87);
}
.mat-mdc-form-field.mat-focused:not(.mat-form-field-invalid) input[type=number]::-webkit-inner-spin-button {
  color: red;
}

.mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  color: #FFFFFF;
}

.mat-mdc-icon-button:disabled {
  color: var(--mdc-icon-button-disabled-icon-color) !important;
}

body {
  margin: 0;
  background-color: #FCFCFC;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  border-collapse: collapse;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-initial {
  pointer-events: initial;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-100 {
  height: 100% !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-1000 {
  max-width: 1000px;
}

.min-width-200 {
  min-width: 200px;
}

.absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 24px;
}
@media (max-width: 1600px) {
  .cards-grid-container {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}
@media (max-width: 1600px) and (max-width: 900px) {
  .cards-grid-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.comma-divided-list ~ .comma-divided-list::before {
  content: ", ";
}

.color-background-100 {
  color: #FCFCFC;
}

.color-background-local {
  color: #FAFAFA;
}

.color-background-row {
  color: #F1F1F1;
}

.color-white-100 {
  color: #FFFFFF;
}

.color-white-08 {
  color: rgba(255, 255, 255, 0.08);
}

.color-gray-100 {
  color: #233351;
}

.color-gray-87 {
  color: rgba(35, 51, 81, 0.87);
}

.color-gray-60 {
  color: rgba(35, 51, 81, 0.6);
}

.color-gray-38 {
  color: rgba(35, 51, 81, 0.38);
}

.color-gray-16 {
  color: rgba(35, 51, 81, 0.16);
}

.color-gray-06 {
  color: rgba(35, 51, 81, 0.06);
}

.color-black-100 {
  color: #1A1A26;
}

.color-black-87 {
  color: rgba(26, 26, 38, 0.87);
}

.color-black-60 {
  color: rgba(26, 26, 38, 0.6);
}

.color-black-38 {
  color: rgba(26, 26, 38, 0.38);
}

.color-black-16 {
  color: rgba(26, 26, 38, 0.16);
}

.color-black-12 {
  color: rgba(26, 26, 38, 0.12);
}

.color-black-08 {
  color: rgba(26, 26, 38, 0.08);
}

.color-black-06 {
  color: rgba(26, 26, 38, 0.06);
}

.color-black-04 {
  color: rgba(26, 26, 38, 0.04);
}

.color-black-02 {
  color: rgba(26, 26, 38, 0.02);
}

.icon-hover mat-icon, .icon-hover .mdi {
  visibility: hidden;
  font-size: 1.2em;
}
.icon-hover:hover mat-icon, .icon-hover:hover .mdi {
  visibility: initial;
}

.gshDisplay {
  border-color: #caf8ea;
  opacity: 58%;
  margin-bottom: 9px;
}

/* important to first import the default theme since the dark one uses its typography */
.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.row--form-row {
  margin: 0 -5px;
}

.row--form-row > *[class*=col-] {
  padding: 0 5px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button.small-mat-button {
  line-height: 21px !important;
  padding: 0 8px !important;
}

.dynamic-table-default-icon:after {
  content: "--";
}

.flo-chip {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
  display: inline-block;
  padding: 0 10px;
}

.flo-chip-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  margin: -1px 10px -1px -11px;
  width: 32px;
  height: 32px;
}

.flo-form--toggle {
  height: 3.9375em !important;
}

.flo-form--checkbox {
  height: 3.9375em !important;
  line-height: 3.9375em !important;
}

.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.tabs-outline {
  position: relative !important;
  padding-left: 14px;
}

.tabs-outline .mdc-tab__text-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.tabs-outline .mat-mdc-tab-link {
  position: relative;
  z-index: 2;
  max-width: 50%;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab {
  border-radius: 8px 8px 0 0;
  border: solid 1px rgba(26, 26, 38, 0.12);
  border-bottom-color: transparent;
  display: block;
  margin: 0 8px;
  transition: background ease 0.25s;
  padding: 0 36px;
  height: 52px;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--inactive {
  border-bottom-color: transparent;
  color: rgba(26, 26, 38, 0.6);
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--inactive:hover {
  cursor: pointer;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--active {
  background: white;
  border-color: var(--primary-color, #F69C00);
  border-bottom-color: #FFFFFF;
  color: var(--primary-color, #F69C00);
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--active:hover, .tabs-outline .mat-mdc-tab-link.mdc-tab--active:active, .tabs-outline .mat-mdc-tab-link.mdc-tab--active:visited {
  background: white;
  border-bottom-color: #FFFFFF;
  color: var(--primary-color, #F69C00);
  cursor: default;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab:first-child .tabs-outline .mat-mdc-tab-link.mdc-tab__link {
  margin-left: 20px;
}

.tabs-outline .mdc-tab-indicator {
  display: none;
}

.tabs-outline:before {
  display: block;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(26, 26, 38, 0.16);
  z-index: 1;
  height: 1px;
}

.table-tabs-outline {
  position: relative;
}

.table-tabs-outline .tabs-outline {
  padding-left: 0;
}

.table-tabs-outline .tabs-outline:before {
  display: none;
}

.table-tabs-outline .default-table-header__row_head {
  max-height: 140px;
  min-height: 140px;
  align-items: flex-end !important;
}

.table-tabs-outline .default-table-header__row_head .title-wrapper {
  margin-bottom: 65px !important;
}

.table-tabs-outline .default-table-header__row {
  background: #FFFFFF;
}

.table-tabs-outline .table-tabs-container {
  position: absolute;
  left: 20px;
  top: 108px;
}

.flo-snack-bar {
  min-width: 10px;
  color: #FFFFFF !important;
}

.flo-snack-bar .mat-button {
  color: #FFFFFF !important;
}

.flo-snack-bar__success {
  background-color: #28a745 !important;
}

.flo-snack-bar__error {
  background-color: #d05764 !important;
}

.flo-snack-bar__info {
  background-color: #17a2b8 !important;
}

.flo-snack-bar__warning {
  background-color: #FFA87D !important;
}

bd-header header.full-width-header {
  margin: -30px -30px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.highcharts-gauge-main-number {
  font-family: "Poppins SemiBold", sans-serif !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 36px !important;
  letter-spacing: -0.404406px !important;
  color: #2C405A;
}

.highcharts-gauge-main-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #696974;
  border-radius: 4px;
}

.highcharts-gauge-small-title {
  font-family: "Poppins SemiBold", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.highcharts-gauge-loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.preloader-img {
  max-width: 100%;
  max-height: 100%;
}

.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .search__input, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .dark-theme .system-labels .mat-chip.mat-standard-chip, .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.flo-progress-bar {
  border-radius: 4px;
}

.flo-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #E2E2EA !important;
}

.flo-progress-bar--green .mdc-linear-progress__bar-inner {
  border-color: #2ED47A !important;
}

.flo-progress-bar--yellow .mdc-linear-progress__bar-inner {
  border-color: #FACA00 !important;
}

.flo-progress-bar--red .mdc-linear-progress__bar-inner {
  border-color: #FC5A5A !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(26, 26, 38, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--primary-color, #F69C00);
  --mat-option-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--primary-color, #F69C00);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(26, 26, 38, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(35, 51, 81, 0.6);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primary-color, #F69C00);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--primary-color, #F69C00);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fcfcfc;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primary-color, #F69C00);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--primary-color, #F69C00);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fcfcfc;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primary-color, #F69C00);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--primary-color, #F69C00);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fcfcfc;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fcfcfc;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fcfcfc;
  color: rgba(26, 26, 38, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(26, 26, 38, 0.2), 0px 0px 0px 0px rgba(26, 26, 38, 0.14), 0px 0px 0px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(26, 26, 38, 0.2), 0px 1px 1px 0px rgba(26, 26, 38, 0.14), 0px 1px 3px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(26, 26, 38, 0.2), 0px 2px 2px 0px rgba(26, 26, 38, 0.14), 0px 1px 5px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(26, 26, 38, 0.2), 0px 3px 4px 0px rgba(26, 26, 38, 0.14), 0px 1px 8px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(26, 26, 38, 0.2), 0px 4px 5px 0px rgba(26, 26, 38, 0.14), 0px 1px 10px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(26, 26, 38, 0.2), 0px 5px 8px 0px rgba(26, 26, 38, 0.14), 0px 1px 14px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(26, 26, 38, 0.2), 0px 6px 10px 0px rgba(26, 26, 38, 0.14), 0px 1px 18px 0px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(26, 26, 38, 0.2), 0px 7px 10px 1px rgba(26, 26, 38, 0.14), 0px 2px 16px 1px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(26, 26, 38, 0.2), 0px 8px 10px 1px rgba(26, 26, 38, 0.14), 0px 3px 14px 2px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(26, 26, 38, 0.2), 0px 9px 12px 1px rgba(26, 26, 38, 0.14), 0px 3px 16px 2px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(26, 26, 38, 0.2), 0px 10px 14px 1px rgba(26, 26, 38, 0.14), 0px 4px 18px 3px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(26, 26, 38, 0.2), 0px 11px 15px 1px rgba(26, 26, 38, 0.14), 0px 4px 20px 3px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(26, 26, 38, 0.2), 0px 12px 17px 2px rgba(26, 26, 38, 0.14), 0px 5px 22px 4px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(26, 26, 38, 0.2), 0px 13px 19px 2px rgba(26, 26, 38, 0.14), 0px 5px 24px 4px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(26, 26, 38, 0.2), 0px 14px 21px 2px rgba(26, 26, 38, 0.14), 0px 5px 26px 4px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(26, 26, 38, 0.2), 0px 15px 22px 2px rgba(26, 26, 38, 0.14), 0px 6px 28px 5px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(26, 26, 38, 0.2), 0px 16px 24px 2px rgba(26, 26, 38, 0.14), 0px 6px 30px 5px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(26, 26, 38, 0.2), 0px 17px 26px 2px rgba(26, 26, 38, 0.14), 0px 6px 32px 5px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(26, 26, 38, 0.2), 0px 18px 28px 2px rgba(26, 26, 38, 0.14), 0px 7px 34px 6px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(26, 26, 38, 0.2), 0px 19px 29px 2px rgba(26, 26, 38, 0.14), 0px 7px 36px 6px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(26, 26, 38, 0.2), 0px 20px 31px 3px rgba(26, 26, 38, 0.14), 0px 8px 38px 7px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(26, 26, 38, 0.2), 0px 21px 33px 3px rgba(26, 26, 38, 0.14), 0px 8px 40px 7px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(26, 26, 38, 0.2), 0px 22px 35px 3px rgba(26, 26, 38, 0.14), 0px 8px 42px 7px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(26, 26, 38, 0.2), 0px 23px 36px 3px rgba(26, 26, 38, 0.14), 0px 9px 44px 8px rgba(26, 26, 38, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(26, 26, 38, 0.2), 0px 24px 38px 3px rgba(26, 26, 38, 0.14), 0px 9px 46px 8px rgba(26, 26, 38, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(26, 26, 38, 0.2), 0px 1px 1px 0px rgba(26, 26, 38, 0.14), 0px 1px 3px 0px rgba(26, 26, 38, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(26, 26, 38, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(26, 26, 38, 0.2), 0px 0px 0px 0px rgba(26, 26, 38, 0.14), 0px 0px 0px 0px rgba(26, 26, 38, 0.12);
  --mat-card-subtitle-text-color: rgba(35, 51, 81, 0.6);
}

.mat-mdc-card {
  --mat-card-title-text-font: Open Sans, sans-serif;
  --mat-card-title-text-line-height: 24px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.4px;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Open Sans, sans-serif;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 18px;
  --mat-card-subtitle-text-tracking: 0.4px;
  --mat-card-subtitle-text-weight: 400;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-linear-progress-track-color: var(--primary-color, #F69C00);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: var(--primary-color, #F69C00);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--primary-color, #F69C00));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--primary-color, %23F69C00)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--primary-color, #F69C00);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--primary-color, #F69C00));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-linear-progress-track-color: var(--primary-color, #F69C00);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: var(--primary-color, #F69C00);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--primary-color, #F69C00));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--primary-color, %23F69C00)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: var(--primary-color, #F69C00);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--primary-color, #F69C00));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 11px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.4px;
}

html {
  --mdc-filled-text-field-caret-color: var(--primary-color, #F69C00);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-color, #F69C00);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(var(--primary-color, #F69C00), 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(var(--primary-color, #F69C00), 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--primary-color, #F69C00);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-caret-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color, #F69C00);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-color, #F69C00);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 52px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -32.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 22px;
  padding-bottom: 6px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 12px;
  --mat-form-field-subscript-text-size: 11px;
  --mat-form-field-subscript-text-tracking: 0.4px;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--primary-color, #F69C00);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--primary-color, #F69C00);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Open Sans, sans-serif;
  --mdc-dialog-subhead-line-height: 24px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0.4px;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: #1a1a26;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--primary-color, #F69C00);
  --mdc-chip-elevated-disabled-container-color: var(--primary-color, #F69C00);
  --mdc-chip-focus-state-layer-color: #1a1a26;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--primary-color, #F69C00);
  --mdc-chip-elevated-disabled-container-color: var(--primary-color, #F69C00);
  --mdc-chip-focus-state-layer-color: #1a1a26;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: #1a1a26;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 18px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: rgba(26, 26, 38, 0.38);
  --mdc-switch-selected-handle-color: rgba(26, 26, 38, 0.38);
  --mdc-switch-selected-hover-state-layer-color: rgba(26, 26, 38, 0.38);
  --mdc-switch-selected-pressed-state-layer-color: rgba(26, 26, 38, 0.38);
  --mdc-switch-selected-focus-track-color: #233351;
  --mdc-switch-selected-hover-track-color: #233351;
  --mdc-switch-selected-pressed-track-color: #233351;
  --mdc-switch-selected-track-color: #233351;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(26, 26, 38, 0.2), 0px 1px 1px 0px rgba(26, 26, 38, 0.14), 0px 1px 3px 0px rgba(26, 26, 38, 0.12);
  --mdc-switch-handle-shadow-color: #1a1a26;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(26, 26, 38, 0.2), 0px 0px 0px 0px rgba(26, 26, 38, 0.14), 0px 0px 0px 0px rgba(26, 26, 38, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(26, 26, 38, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color-opacity-16, rgba(246, 156, 0, 0.1607843137));
  --mdc-switch-selected-handle-color: var(--primary-color-opacity-16, rgba(246, 156, 0, 0.1607843137));
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color-opacity-16, rgba(246, 156, 0, 0.1607843137));
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color-opacity-16, rgba(246, 156, 0, 0.1607843137));
  --mdc-switch-selected-focus-handle-color: var(--chart-color, #64ADF0);
  --mdc-switch-selected-hover-handle-color: var(--chart-color, #64ADF0);
  --mdc-switch-selected-pressed-handle-color: var(--chart-color, #64ADF0);
  --mdc-switch-selected-focus-track-color: rgba(66, 139, 255, 0.06);
  --mdc-switch-selected-hover-track-color: rgba(66, 139, 255, 0.06);
  --mdc-switch-selected-pressed-track-color: rgba(66, 139, 255, 0.06);
  --mdc-switch-selected-track-color: rgba(66, 139, 255, 0.06);
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 44px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Roboto, sans-serif;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 18px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--primary-color, #F69C00);
  --mat-radio-disabled-label-color: rgba(26, 26, 38, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--primary-color, #F69C00);
  --mat-radio-disabled-label-color: rgba(26, 26, 38, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(26, 26, 38, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 36px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 18px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-focus-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-hover-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-active-track-color: var(--primary-color, #F69C00);
  --mdc-slider-inactive-track-color: var(--primary-color, #F69C00);
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-color, #F69C00);
  --mat-mdc-slider-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--primary-color, #F69C00), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--primary-color, #F69C00), 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-focus-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-hover-handle-color: var(--primary-color, #F69C00);
  --mdc-slider-active-track-color: var(--primary-color, #F69C00);
  --mdc-slider-inactive-track-color: var(--primary-color, #F69C00);
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-color, #F69C00);
  --mat-mdc-slider-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--primary-color, #F69C00), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--primary-color, #F69C00), 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Open Sans, sans-serif;
  --mdc-slider-label-label-text-size: 18px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: 0.4px;
  --mdc-slider-label-label-text-weight: 400;
}

html {
  --mat-menu-item-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-menu-item-icon-color: rgba(26, 26, 38, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 500;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(26, 26, 38, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(35, 51, 81, 0.6);
  --mdc-list-list-item-leading-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(35, 51, 81, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-list-list-item-disabled-label-text-color: #1a1a26;
  --mdc-list-list-item-disabled-leading-icon-color: #1a1a26;
  --mdc-list-list-item-disabled-trailing-icon-color: #1a1a26;
  --mdc-list-list-item-hover-label-text-color: rgba(26, 26, 38, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(26, 26, 38, 0.87);
  --mdc-list-list-item-hover-state-layer-color: #1a1a26;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: #1a1a26;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-pressed-icon-color: var(--primary-color, #F69C00);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-radio-selected-pressed-icon-color: var(--primary-color, #F69C00);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--primary-color, #F69C00);
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--primary-color, #F69C00);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-two-line-container-height: 60px;
  --mdc-list-list-item-three-line-container-height: 84px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 52px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 68px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 18px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 12px;
  --mdc-list-list-item-trailing-supporting-text-size: 11px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.4px;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.4px;
}

html {
  --mat-paginator-container-text-color: rgba(26, 26, 38, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(26, 26, 38, 0.54);
  --mat-paginator-disabled-icon-color: rgba(26, 26, 38, 0.12);
}

html {
  --mat-paginator-container-size: 52px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 12px;
  --mat-paginator-container-text-size: 11px;
  --mat-paginator-container-text-tracking: 0.4px;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 11px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--primary-color, #F69C00);
  --mat-tab-header-disabled-ripple-color: rgba(35, 51, 81, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-ripple-color: var(--primary-color, #F69C00);
  --mat-tab-header-inactive-ripple-color: var(--primary-color, #F69C00);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-hover-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-focus-indicator-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-hover-indicator-color: var(--primary-color, #F69C00);
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--primary-color, #F69C00);
  --mat-tab-header-disabled-ripple-color: rgba(35, 51, 81, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-ripple-color: var(--primary-color, #F69C00);
  --mat-tab-header-inactive-ripple-color: var(--primary-color, #F69C00);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-hover-label-text-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-focus-indicator-color: var(--primary-color, #F69C00);
  --mat-tab-header-active-hover-indicator-color: var(--primary-color, #F69C00);
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(35, 51, 81, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--primary-color, #F69C00);
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--primary-color, #F69C00);
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 44px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.5px;
  --mat-tab-header-label-text-line-height: 16px;
  --mat-tab-header-label-text-weight: 600;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color, #F69C00);
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1a26;
  --mdc-checkbox-unselected-pressed-state-layer-color: #1a1a26;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(26, 26, 38, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 36px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 18px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--primary-color, #F69C00);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--primary-color, #F69C00);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--primary-color, #F69C00);
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--primary-color, #F69C00);
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--primary-color, #F69C00);
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--primary-color, #F69C00);
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--primary-color, #F69C00);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--primary-color, #F69C00);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-button-ripple-color: rgba(var(--primary-color, #F69C00), 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-button-ripple-color: rgba(var(--primary-color, #F69C00), 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
}
.mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 16px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.5px);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--primary-color, #F69C00);
  --mat-mdc-button-persistent-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-button-ripple-color: rgba(var(--primary-color, #F69C00), 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--primary-color, #F69C00);
  --mat-mdc-button-persistent-ripple-color: var(--primary-color, #F69C00);
  --mat-mdc-button-ripple-color: rgba(var(--primary-color, #F69C00), 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 44px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 10px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: var(--primary-color, #F69C00);
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: var(--primary-color, #F69C00);
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 16px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.5px);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Open Sans, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.5px;
  --mdc-extended-fab-label-text-weight: 600;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: var(--primary-color, #F69C00);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 18px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(26, 26, 38, 0.87);
  --mat-table-row-item-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-table-row-item-outline-color: rgba(35, 51, 81, 0.16);
}

html {
  --mat-table-header-container-height: 52px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
}

html {
  --mat-table-header-headline-font: Open Sans, sans-serif;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 18px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: 0.4px;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 18px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 18px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--primary-color, #F69C00);
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--primary-color, #F69C00);
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: var(--primary-color, #F69C00);
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #a6a6ab;
  --mat-badge-disabled-state-text-color: rgba(26, 26, 38, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: var(--primary-color, #F69C00);
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(26, 26, 38, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 18px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(35, 51, 81, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(26, 26, 38, 0.08);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(35, 51, 81, 0.6);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(26, 26, 38, 0.38);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(26, 26, 38, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #1a1a26;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(26, 26, 38, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(26, 26, 38, 0.38);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 26, 38, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #dcdee3;
}

html {
  --mat-standard-button-toggle-height: 44px;
}

html {
  --mat-legacy-button-toggle-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-text-font: Roboto, sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary-color, #F69C00), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(26, 26, 38, 0.6);
  --mat-datepicker-calendar-body-label-text-color: rgba(35, 51, 81, 0.6);
  --mat-datepicker-calendar-period-button-icon-color: rgba(26, 26, 38, 0.6);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(26, 26, 38, 0.6);
  --mat-datepicker-calendar-header-divider-color: rgba(35, 51, 81, 0.16);
  --mat-datepicker-calendar-header-text-color: rgba(35, 51, 81, 0.6);
  --mat-datepicker-calendar-date-today-outline-color: rgba(35, 51, 81, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(35, 51, 81, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(26, 26, 38, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(35, 51, 81, 0.32);
  --mat-datepicker-range-input-separator-color: rgba(26, 26, 38, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(26, 26, 38, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-color, #F69C00);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary-color, #F69C00), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-color, #F69C00);
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-color: rgba(35, 51, 81, 0.16);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(26, 26, 38, 0.87);
  --mat-expansion-actions-divider-color: rgba(35, 51, 81, 0.16);
  --mat-expansion-header-hover-state-layer-color: rgba(26, 26, 38, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(26, 26, 38, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(26, 26, 38, 0.38);
  --mat-expansion-header-text-color: rgba(26, 26, 38, 0.87);
  --mat-expansion-header-description-color: rgba(35, 51, 81, 0.6);
  --mat-expansion-header-indicator-color: rgba(35, 51, 81, 0.6);
}

html {
  --mat-expansion-header-collapsed-state-height: 44px;
  --mat-expansion-header-expanded-state-height: 60px;
}

html {
  --mat-expansion-header-text-font: Open Sans, sans-serif;
  --mat-expansion-header-text-size: 18px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 18px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 11px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 11px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--primary-color, #F69C00);
}
.mat-icon.mat-accent {
  --mat-icon-color: var(--primary-color, #F69C00);
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(35, 51, 81, 0.16);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(26, 26, 38, 0.87);
  --mat-sidenav-content-background-color: #fcfcfc;
  --mat-sidenav-content-text-color: rgba(26, 26, 38, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(35, 51, 81, 0.16);
  --mat-stepper-header-hover-state-layer-color: rgba(26, 26, 38, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(26, 26, 38, 0.04);
  --mat-stepper-header-label-text-color: rgba(35, 51, 81, 0.6);
  --mat-stepper-header-optional-label-text-color: rgba(35, 51, 81, 0.6);
  --mat-stepper-header-selected-state-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(35, 51, 81, 0.6);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--primary-color, #F69C00);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 68px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

.mat-sort-header-arrow {
  color: #7b8597;
}

html {
  --mat-toolbar-container-background-color: rgba(26, 26, 38, 0.0196078431);
  --mat-toolbar-container-text-color: rgba(26, 26, 38, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--primary-color, #F69C00);
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--primary-color, #F69C00);
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 60px;
  --mat-toolbar-mobile-height: 52px;
}

html {
  --mat-toolbar-title-text-font: Open Sans, sans-serif;
  --mat-toolbar-title-text-line-height: 24px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.4px;
  --mat-toolbar-title-text-weight: 400;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(26, 26, 38, 0.87);
}

.mat-tree-node {
  min-height: 44px;
}

.mat-tree {
  font-family: Roboto, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.4px;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.4px;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.4px;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 18px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 18px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.4px;
}

.mat-body,
.mat-body-2,
.dark-theme :root .default-theme .mat-mdc-menu-content,
.dark-theme :root .default-theme .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
:root .default-theme .mat-mdc-menu-content,
:root .default-theme .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.dark-theme :root .default-theme .mat-mdc-menu-content p,
.dark-theme :root .default-theme .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text p,
:root .default-theme .mat-mdc-menu-content p,
:root .default-theme .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 112px;
  font-family: Open Sans, sans-serif;
  letter-spacing: -1.5px;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 72px;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.5px;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  font-family: Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
  font-family: Open Sans, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

:root .default-theme {
  --mdc-checkbox-selected-checkmark-color: #FFFFFFDE;
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-checkbox-unselected-focus-icon-color: rgba(26, 26, 38, 0.6);
  --mdc-checkbox-unselected-hover-icon-color: rgba(26, 26, 38, 0.6);
  --mdc-checkbox-unselected-icon-color: rgba(26, 26, 38, 0.6);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(26, 26, 38, 0.6);
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;
  --flo-checkbox-size: 16px;
  --mdc-checkbox-state-layer-size: 32px;
  --mdc-chip-with-icon-icon-color: rgba(26, 26, 38, 0.6);
  --mat-table-header-container-height: 56px;
  --mat-paginator-container-size: 68px;
  --mat-paginator-container-text-color: rgba(35, 51, 81, 0.6);
  --mat-icon-color: rgba(26, 26, 38, 0.6);
  --mdc-icon-button-icon-color: rgba(26, 26, 38, 0.6);
  --mat-menu-item-icon-color: rgba(26, 26, 38, 0.6);
  --mat-expansion-header-text-color: rgba(26, 26, 38, 0.87);
  --mat-expansion-container-text-color: rgba(26, 26, 38, 0.87);
  --mdc-list-list-item-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-option-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-optgroup-label-text-color: rgba(26, 26, 38, 0.87);
  --mdc-theme-text-primary-on-background: rgba(26, 26, 38, 0.87);
  --mat-sidenav-content-text-color: rgba(26, 26, 38, 0.87);
  --mat-menu-item-label-text-color: rgba(26, 26, 38, 0.87);
  --mat-stepper-header-selected-state-label-text-color: rgba(26, 26, 38, 0.87);
  --mdc-filled-text-field-label-text-color: rgba(26, 26, 38, 0.6);
  --mat-stepper-header-label-text-color: rgba(26, 26, 38, 0.6);
  --mat-stepper-header-icon-background-color: rgba(26, 26, 38, 0.38);
  --mat-datepicker-calendar-date-text-color: rgba(26, 26, 38, 0.87);
  --mat-datepicker-calendar-body-label-text-color: rgba(26, 26, 38, 0.6);
  --mat-datepicker-calendar-header-text-color: rgba(26, 26, 38, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(26, 26, 38, 0.87);
  --mat-select-enabled-trigger-text-color: rgba(26, 26, 38, 0.87);
  --mat-form-field-container-text-weight: 400;
  --mdc-filled-text-field-disabled-label-text-color: rgba(26, 26, 38, 0.38);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(26, 26, 38, 0.38);
  --mdc-filled-text-field-disabled-input-text-color: rgba(26, 26, 38, 0.38);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(26, 26, 38, 0.38);
  --mat-select-trigger-text-font: typography.$font-family-primary;
  --mat-select-focused-arrow-color: rgba(26, 26, 38, 0.6);
  --mat-select-invalid-arrow-color: rgba(26, 26, 38, 0.6);
  --mat-select-disabled-arrow-color: rgba(26, 26, 38, 0.16);
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-tracking: normal;
  --mat-paginator-select-trigger-text-size: 14px;
  --mdc-filled-text-field-label-text-font: typography.$font-family-primary;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-line-height: 24px;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-error-focus-outline-color: #F44336 !important;
  --mdc-outlined-text-field-error-label-text-color: rgba(26, 26, 38, 0.6) !important;
  --mdc-outlined-text-field-error-focus-label-text-color: #F44336 !important;
  --mdc-outlined-text-field-error-outline-color: rgba(26, 26, 38, 0.16) !important;
  --mdc-outlined-text-field-error-hover-outline-color: rgba(26, 26, 38, 0.38) !important;
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-color, #F69C00) !important;
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color, #F69C00) !important;
  --mat-option-selected-state-label-text-color: var(--primary-color, #F69C00) !important;
  --mdc-outlined-text-field-outline-color: rgba(26, 26, 38, 0.16) !important;
  --mdc-outlined-text-field-hover-outline-color: rgba(26, 26, 38, 0.38) !important;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-subscript-text-line-height: 18px;
  --mat-option-hover-state-layer-color: inherit;
  --mat-option-focus-state-layer-color: none;
  --mat-option-selected-state-layer-color: none;
}
:root .default-theme .mdc-checkbox__background {
  height: var(--flo-checkbox-size);
  width: var(--flo-checkbox-size);
}
:root .default-theme .mat-pseudo-checkbox {
  width: var(--flo-checkbox-size);
  height: var(--flo-checkbox-size);
}
:root .default-theme .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-radio-disabled-unselected-icon-color: rgba(26, 26, 38, 0.16);
}
:root .default-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: rgba(26, 26, 38, 0.87);
}
:root .default-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: rgba(26, 26, 38, 0.87);
}
:root .default-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(26, 26, 38, 0.06);
  --mdc-filled-button-disabled-label-text-color: rgba(26, 26, 38, 0.38);
  --mdc-filled-button-container-color: rgba(26, 26, 38, 0.06);
  --mdc-filled-button-label-text-color: rgba(26, 26, 38, 0.38);
}
:root .default-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: rgba(35, 51, 81, 0.16);
}
:root .default-theme .mat-mdc-icon-button .mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: 8px;
}
:root .default-theme .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 40px;
  width: 40px;
}
:root .default-theme .mat-mdc-icon-button:disabled mat-icon {
  color: var(--mdc-icon-button-disabled-icon-color);
}
:root .default-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-icon-button-disabled-icon-color: rgba(26, 26, 38, 0.16);
}
:root .default-theme .mdc-card {
  padding: 16px;
}
:root .default-theme .mdc-card:not([class*=mat-elevation-z]) {
  border: solid 1px rgba(26, 26, 38, 0.12);
  border-radius: 8px;
  box-shadow: none;
}
:root .default-theme .mat-mdc-tab-link, :root .default-theme .mdc-tab__text-label {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 600;
}
:root .default-theme .flo-inner-tabs .mat-mdc-tab-link, :root .default-theme .flo-inner-tabs .mdc-tab__text-label {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 400;
}
:root .default-theme .mat-mdc-tab-group, :root .default-theme .mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-ripple-color: inherit;
  --mat-tab-header-active-ripple-color: inherit;
  --mat-tab-header-inactive-label-text-color: rgba(26, 26, 38, 0.6);
}
:root .default-theme .mat-mdc-form-field-infix {
  min-height: 56px;
}
:root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
:root .default-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
:root .default-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
:root .default-theme .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
:root .default-theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
:root .default-theme .mat-mdc-text-field-wrapper {
  height: 48px;
}
:root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 50%;
}
:root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-select-arrow-wrapper {
  transform: translateY(-3px);
}
:root .default-theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 26px;
  padding-bottom: 6px;
}
:root .default-theme .mat-mdc-option-ripple {
  display: none !important;
}
:root .default-theme .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
:root .default-theme .mdc-notched-outline__leading, :root .default-theme .mdc-notched-outline__notch, :root .default-theme .mdc-notched-outline__trailing {
  border-width: 1px !important;
}
:root .default-theme ._form-field-font-config, :root .dark-theme :root .default-theme .mat-mdc-select,
:root .dark-theme :root .default-theme .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control, :root .dark-theme :root .default-theme .mdc-list-item__primary-text, :root .default-theme .mat-mdc-select,
:root .default-theme .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control, :root .default-theme .mdc-list-item__primary-text {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
:root .default-theme .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: var(--mat-option-hover-state-layer-color) !important;
}
:root .default-theme mat-hint {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
  color: rgba(26, 26, 38, 0.6);
}
:root .default-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #FFFFFF;
  --mdc-dialog-subhead-color: rgba(26, 26, 38, 0.87);
  --mdc-dialog-supporting-text-color: rgba(26, 26, 38, 0.87);
}
:root .default-theme .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 18px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}
:root .default-theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: inherit;
  --mdc-snackbar-supporting-text-color: inherit;
  --mat-snack-bar-button-color: inherit;
}
:root .default-theme .mat-mdc-slide-toggle {
  --mdc-switch-handle-surface-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-hover-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-pressed-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-pressed-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-focus-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-hover-track-color: var(--primary-color-opacity-16);
  --mdc-switch-unselected-focus-handle-color: #FFFFFF;
  --mdc-switch-unselected-handle-color: #FFFFFF;
  --mdc-switch-unselected-hover-handle-color: #FFFFFF;
  --mdc-switch-disabled-unselected-handle-color: #FFFFFF;
  --mdc-switch-disabled-selected-handle-color: var(--primary-color-opacity-50);
  --mdc-switch-disabled-selected-track-color: var(--primary-color-opacity-50);
  --mdc-switch-unselected-pressed-handle-color: var(--primary-color-opacity-50);
  --mdc-switch-unselected-pressed-state-layer-color: var(--primary-color, #F69C00);
}
:root .default-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: var(--primary-color, #F69C00);
}
:root .default-theme .mdc-switch:disabled {
  --mdc-switch-handle-surface-color: #FFFFFF;
}
:root .default-theme .mdc-switch:disabled .mdc-switch__shadow {
  box-shadow: var(--mdc-switch-handle-elevation-shadow);
}
:root .default-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-linear-progress-track-color: var(--primary-color-opacity-50);
}
:root .default-theme .mat-mdc-list-base {
  --mdc-list-list-item-hover-state-layer-color: unset;
  --mdc-list-list-item-hover-state-layer-opacity: unset;
  --mdc-list-list-item-focus-state-layer-color: unset;
  --mdc-list-list-item-focus-state-layer-opacity: unset;
}

.color-primary {
  color: var(--primary-color, #F69C00) !important;
}

.disabled-form-field-text-color {
  color: var(--mdc-filled-text-field-disabled-label-text-color);
}

.color-secondary {
  color: var(--primary-color, #F69C00) !important;
}

.color-warn {
  color: #f44336 !important;
}

.color-accent {
  color: var(--primary-color, #F69C00) !important;
}

.color-white {
  color: #FFFFFF;
}

.fill-primary {
  fill: var(--primary-color, #F69C00) !important;
}

.background-primary {
  background-color: #fcfcfc !important;
}

.background-card {
  background-color: white !important;
}

.app-bar-color {
  background-color: rgba(26, 26, 38, 0.0196078431) !important;
}

.background-secondary {
  background-color: var(--primary-color, #F69C00) !important;
}

.background-warn {
  background-color: #f44336 !important;
}

.background-accent {
  background-color: var(--primary-color, #F69C00) !important;
}

.flo-icon-color {
  color: rgba(26, 26, 38, 0.6);
}

.flo-search-icon-color {
  color: rgba(35, 51, 81, 0.38) !important;
}

.flo-light-icon-color {
  color: rgba(26, 26, 38, 0.38);
}

.mat-secondary-text-color {
  color: rgba(35, 51, 81, 0.6) !important;
}

.mat-dark-secondary-text-color {
  color: #FFFFFF;
}

.mat-dark-secondary-divider-color {
  border-top-color: rgba(35, 51, 81, 0.38);
}

.flo-border-color {
  border-color: rgba(26, 26, 38, 0.16) !important;
}

.mat-divider-background {
  background-color: rgba(35, 51, 81, 0.16);
}

.mat-hint-text-color {
  color: rgba(35, 51, 81, 0.38);
}

.flo-breadcrumbs-color {
  color: rgba(26, 26, 38, 0.6);
}

.default-header-colored-button-icon svg path {
  fill: currentColor;
  fill-opacity: 0.87;
}

.flo-vertical-divider {
  background-color: rgba(35, 51, 81, 0.16);
  height: 36px;
  width: 1px;
}

.flo-horizontal-divider {
  background-color: rgba(35, 51, 81, 0.16);
  height: 1px;
  width: 100%;
}

.middle-sized-icon {
  height: 17.5px !important;
  width: 17.5px !important;
}

.storytelling-icon {
  height: 205px !important;
  width: 370px !important;
  color: var(--primary-color, #F69C00);
}

.main {
  display: block;
}
.main--with-margins {
  padding: 20px 40px 40px 40px;
}

html {
  height: 100vh;
}

body {
  height: 100%;
}

.wrapper-sidenav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 65px;
  padding-left: 10px;
  padding-right: 10px;
}

.flo-icon {
  display: inline-block;
  text-align: center;
}
.flo-icon--background--green {
  background-color: #3FAA19;
}
.flo-icon--background--red {
  background-color: #f44336;
}
.flo-icon--background--gray {
  background-color: rgba(26, 26, 38, 0.6);
}
.flo-icon--background--orange {
  background-color: #FF7A30;
}
.flo-icon--button {
  padding: 2.5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.flo-icon--big {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  padding-top: 5px;
}
.flo-icon--big .flo-icon__text {
  line-height: 35px;
  font-size: 18px;
}
.flo-icon--green svg path {
  background-color: #3FAA19;
  fill: #3FAA19;
  fill-opacity: 1;
}
.flo-icon--green svg path .flo-icon__text {
  fill: rgb(61, 213, 152);
  color: rgb(61, 213, 152);
}
.flo-icon--gray svg path {
  background-color: rgba(26, 26, 38, 0.6);
  fill: rgba(26, 26, 38, 0.6);
  fill-opacity: 1;
}
.flo-icon--gray svg path .flo-icon__text {
  fill: rgba(26, 26, 38, 0.6);
  color: rgba(26, 26, 38, 0.6);
}
.flo-icon--white {
  color: #FFFFFF;
  fill: currentColor;
  fill-opacity: 1;
}
.flo-icon--white .flo-icon__text {
  fill: #FFFFFF;
  color: #FFFFFF;
}
.flo-icon--red svg path {
  fill: #f44336;
  fill-opacity: 1;
}
.flo-icon--red svg path .flo-icon__text {
  fill: #f44336;
  color: #f44336;
}
.flo-icon--light-red {
  background-color: rgba(254, 77, 151, 0.1);
}
.flo-icon--light-red .flo-icon__text {
  fill: rgb(254, 77, 151);
}
.flo-icon--yellow svg path {
  fill: rgb(250, 197, 66);
}
.flo-icon--yellow svg path .flo-icon__text {
  fill: rgb(250, 197, 66);
  color: rgb(250, 197, 66);
}
.flo-icon--orange {
  background-color: rgba(255, 151, 74, 0.1);
}
.flo-icon--orange .flo-icon__text {
  fill: rgb(255, 151, 74);
}
.flo-icon--indigo {
  background-color: rgba(171, 125, 246, 0.1);
}
.flo-icon--indigo .flo-icon__text {
  fill: rgb(171, 125, 246);
}
.flo-icon--blue {
  background-color: rgba(77, 124, 254, 0.1);
}
.flo-icon--blue .flo-icon__text {
  fill: rgb(77, 124, 254);
}
.flo-icon--light-blue {
  background-color: rgba(80, 181, 255, 0.1);
}
.flo-icon--light-blue .flo-icon__text {
  fill: rgb(80, 181, 255);
}
.flo-icon--without-inner-text {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  font-size: 16px;
}
.flo-icon--without-inner-text.flo-icon--green {
  color: #3FAA19;
}
.flo-icon--without-inner-text.flo-icon--gray {
  color: rgba(26, 26, 38, 0.6);
}
.flo-icon--without-inner-text.flo-icon--red {
  color: #f44336;
}
.flo-icon--without-inner-text.flo-icon--yellow {
  color: rgb(250, 197, 66);
}

.search__field {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 250px;
  border: solid 1px rgba(35, 51, 81, 0.16);
  transition: border-color 0.1s ease-in;
  border-radius: 4px;
  background: white;
}
.search__field mat-icon {
  color: rgba(35, 51, 81, 0.38);
}
.search__field:focus {
  border-color: var(--primary-color, #F69C00);
}
.search__field:hover {
  border-color: var(--primary-color, #F69C00);
}
.search__input {
  width: 250px;
  background-color: unset;
  border: none;
  outline: none;
  padding: 5px;
  color: inherit;
}
.search__input::placeholder {
  color: rgba(35, 51, 81, 0.38);
}
.search__container .search__close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search__container .search__close-icon svg {
  height: 16px;
  width: 16px;
  font-size: 16px;
  text-align: center;
}
.search__container .default-table-header__button_default.search__search-icon {
  margin: 8px 8px 6px 9px;
  height: 20px;
  width: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.bd-table,
bd-lazy-table {
  display: block;
  min-width: 100%;
  padding: 0;
  border-radius: 8px;
  border: solid 1px rgba(26, 26, 38, 0.12);
  overflow: hidden;
}
.bd-table .table-header,
bd-lazy-table .table-header {
  height: 40px;
  margin-bottom: 10px;
}
.bd-table .table-header .title-wrapper,
bd-lazy-table .table-header .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.bd-table .table-header .title-wrapper .title-text,
bd-lazy-table .table-header .title-wrapper .title-text {
  color: rgba(26, 26, 38, 0.87);
}
.bd-table .table-header .selected-from-text,
.bd-table .table-header .filtered-by-text,
bd-lazy-table .table-header .selected-from-text,
bd-lazy-table .table-header .filtered-by-text {
  color: rgba(35, 51, 81, 0.6);
  margin-left: 20px;
}
.bd-table .table-header .system-button,
bd-lazy-table .table-header .system-button {
  margin: 0 5px;
  min-width: 40px;
}
.bd-table .table-header .system-button:last-of-type,
bd-lazy-table .table-header .system-button:last-of-type {
  margin-right: 0;
}
.bd-table .table-header .system-button.mat-stroked-button,
bd-lazy-table .table-header .system-button.mat-stroked-button {
  background-color: white;
}
.bd-table .table-header .bulk-action-button,
bd-lazy-table .table-header .bulk-action-button {
  font-size: 20px;
  color: rgba(35, 51, 81, 0.6);
}
.bd-table .table-header .filtered-by-text,
bd-lazy-table .table-header .filtered-by-text {
  margin-right: 10px;
}
.bd-table .table-header .text-search-container,
bd-lazy-table .table-header .text-search-container {
  margin-right: 16px;
}
.bd-table .table-header ::ng-deep .text-search-container .mat-form-field-wrapper,
bd-lazy-table .table-header ::ng-deep .text-search-container .mat-form-field-wrapper {
  position: relative;
  bottom: -6px;
}
.bd-table .table-wrapper,
bd-lazy-table .table-wrapper {
  overflow: auto;
}
.bd-table .table-wrapper .mat-mdc-table,
bd-lazy-table .table-wrapper .mat-mdc-table {
  overflow-x: scroll;
  min-width: 100%;
  border-collapse: separate;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-table-sticky,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-table-sticky {
  background-color: inherit;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2),
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2) {
  padding-left: 25px !important;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom: none;
  color: rgba(26, 26, 38, 0.87);
  padding: 0 24px 0 0;
  white-space: nowrap;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell:first-of-type,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell:first-of-type {
  padding-left: 24px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell .mat-sort-header-button,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell .mat-sort-header-button {
  text-align: left;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell .sub-header,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell .sub-header {
  padding: 0;
  color: rgba(35, 51, 81, 0.6);
  font-weight: normal;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell ::ng-deep .mat-sort-header-content,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell ::ng-deep .mat-sort-header-content {
  flex-direction: column;
  align-items: flex-start;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.expand-arrow-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.expand-arrow-row-header-cell {
  z-index: 3 !important;
  box-shadow: 3px 0 3px 0 rgba(35, 51, 81, 0.16);
  border-right: solid 1px rgba(35, 51, 81, 0.16);
  min-width: 58px;
  width: 58px;
  padding: 0 5px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell {
  padding: 0 12px;
  width: 40px;
  border-right: solid 1px rgba(35, 51, 81, 0.16);
  border-bottom: solid 1px rgba(35, 51, 81, 0.16);
  background-color: white;
  text-align: center;
  z-index: 3 !important;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button {
  width: 40px;
  text-align: right;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button {
  margin-left: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 44px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button:hover,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button:hover {
  background-color: rgba(26, 26, 38, 0.08);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button .open-checkbox-menu-icon,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button .open-checkbox-menu-icon {
  color: rgba(26, 26, 38, 0.6);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell .data-row-header-cell-inner-flex-wrap,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell .data-row-header-cell-inner-flex-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.progress-bar-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.progress-bar-row-header-cell {
  min-width: 100px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.avatar-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.avatar-row-header-cell {
  width: 40px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.actions-row-header-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.actions-row-header-cell {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
  width: 0;
  background-color: white;
  padding: 0 !important;
  z-index: 3 !important;
  border-left: solid 1px rgba(35, 51, 81, 0.16);
  text-align: right;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .sticky-column,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .sticky-column {
  z-index: 3 !important;
}
.bd-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row {
  transition: box-shadow 0.1s ease-in;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2),
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2) {
  padding-left: 25px !important;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:nth-child(odd),
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:nth-child(odd) {
  background-color: #FAFAFA;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.clickable,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.clickable {
  cursor: pointer;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.selected,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.selected {
  background-color: #eeeeee;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.expanded-element-row,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.expanded-element-row {
  height: 0;
  border: none;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover {
  box-shadow: 0 2px 4px 0 rgba(35, 51, 81, 0.16), 0 -2px 6px 0 #23335105;
  transform: scale(1);
  border-top: none;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open {
  opacity: 1;
  color: rgba(26, 26, 38, 0.87);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.bulk-checkbox-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.bulk-checkbox-row-cell {
  box-shadow: 0 2px 4px 0 rgba(35, 51, 81, 0.16), 0 -2px 6px 0 #23335105;
  z-index: 2;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.actions-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.actions-row-cell {
  box-shadow: 0 2px 4px 0 rgba(35, 51, 81, 0.16), 0 -2px 6px 0 #23335105;
  z-index: 2;
  padding: 0 10px !important;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell {
  color: rgba(35, 51, 81, 0.87);
  padding: 0 24px 0 0;
  background: inherit;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell:first-of-type,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell:first-of-type {
  padding-left: 24px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell, .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell {
  padding: 0 12px;
  text-align: center;
  border-right: solid 1px rgba(35, 51, 81, 0.16);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell:hover, .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell:hover,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell:hover,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell:hover {
  box-shadow: 0 2px 4px 0 rgba(35, 51, 81, 0.16), 0 -2px 6px 0 #23335105;
  z-index: 2;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell {
  border-right: solid 1px rgba(35, 51, 81, 0.16);
  width: 58px;
  min-width: 58px;
  padding: 0 5px;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open {
  color: rgba(35, 51, 81, 0.6);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-close,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-close {
  transform: rotate(180deg) translateY(5px);
  color: rgba(26, 26, 38, 0.87);
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell {
  border-bottom: none;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail {
  overflow: hidden;
  display: flex;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail .expanded-element-row-cell-element-content,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail .expanded-element-row-cell-element-content {
  padding: 10px;
  width: 100%;
}
.bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.actions-row-cell,
bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.actions-row-cell {
  padding: 0 10px !important;
  width: 0;
  border-left: solid 1px rgba(35, 51, 81, 0.16);
  text-align: right;
}
.bd-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-right,
bd-lazy-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-right {
  box-shadow: 3px 0 3px 0 rgba(35, 51, 81, 0.16);
}
.bd-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-left,
bd-lazy-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-left {
  box-shadow: -3px 0 5px 0 rgba(35, 51, 81, 0.16);
}
.bd-table .table-wrapper .expandable-table tr.mat-mdc-row,
bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row {
  background-color: white;
}
.bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(odd),
bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(odd) {
  background-color: white;
}
.bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+1),
bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+1) {
  background-color: #FAFAFA;
}
.bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+2),
bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+2) {
  background-color: #FAFAFA;
}
.bd-table .table-wrapper .expandable-table tr.mat-mdc-row:hover,
bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:hover {
  cursor: pointer;
}
.bd-table .sticky-column,
bd-lazy-table .sticky-column {
  box-shadow: 3px 0 3px 0 rgba(35, 51, 81, 0.16);
}
.bd-table .mat-mdc-paginator,
bd-lazy-table .mat-mdc-paginator {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.bd-table .mat-mdc-paginator .mat-mdc-paginator-container,
bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container {
  border-top: 1px solid rgba(35, 51, 81, 0.16);
  align-items: center;
}
.bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label,
.bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
.bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label,
bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label,
bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
}
.bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field-infix,
bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: 36px !important;
}
.bd-table .mat-sort-header-content,
bd-lazy-table .mat-sort-header-content {
  text-align: start;
}
.bd-table .mat-card,
bd-lazy-table .mat-card {
  border-radius: 0;
}

.integrated-table-layout {
  border-left: none;
  border-right: none;
  border-radius: unset;
}

div[aria-labelledby*=mat-paginator-page-size-label- i] .mdc-list-item__primary-text {
  font-size: 14px !important;
}

.action-label {
  margin-left: 10px;
}

.filter-label {
  margin-left: 5px;
}

.manage-plans .table-header {
  margin-bottom: 2rem !important;
}
.manage-plans .mat-mdc-cell:first-child {
  width: 10px;
  color: rgba(26, 26, 38, 0.6) !important;
  font-size: 24px !important;
}
.manage-plans .mat-mdc-cell:last-child {
  color: rgba(26, 26, 38, 0.6) !important;
  text-align: end;
}
.manage-plans .blue-last-line .mat-mdc-row:last-child {
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431)) !important;
  cursor: pointer;
}
.manage-plans .clean-table tr, .manage-plans .clean-table td {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

tr.disabled {
  background-color: #F1F1F1 !important;
}
tr.disabled .mat-cell {
  color: rgba(35, 51, 81, 0.38);
}

.filters-sidebar {
  height: 100%;
}
.filters-sidebar .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  border-radius: 0;
}
.filters-sidebar .mat-dialog-container .filters-container {
  max-width: 370px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.filters-sidebar .mat-dialog-container .filters-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  line-height: 36px;
  background: #FAFAFA;
}
.filters-sidebar .mat-card-header {
  display: block;
  margin: 0;
}
.filters-sidebar .mat-mdc-dialog-content {
  padding: 0;
  max-height: calc(5 * 35px);
  overflow-y: auto;
  position: relative;
  margin: 0;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list {
  border-radius: 5px;
  border: 1px solid rgba(35, 51, 81, 0.16);
  margin-bottom: 20px;
  max-height: 240px;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option {
  border-bottom: 1px solid rgba(26, 26, 38, 0.06);
  background-color: #FAFAFA;
  height: 40px !important;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option .mdc-list-item__content {
  padding: 0 !important;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option .mdc-list-item__content .mdc-list-item__primary-text {
  padding: 0 !important;
  color: rgba(26, 26, 38, 0.87) !important;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option:nth-child(2n) {
  background: white;
}
.filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option:last-child {
  border-bottom: none;
}
.filters-sidebar .mat-mdc-dialog-content .filters__search .mat-form-field .mat-form-field-flex {
  width: 330px !important;
  flex-direction: row-reverse;
}
.filters-sidebar .mat-mdc-dialog-content .filters__search .mat-form-field .mat-form-field-flex .mat-form-field-infix {
  width: auto;
}
.filters-sidebar .mat-mdc-dialog-content .mat-icon {
  width: 24px;
  height: 24px;
  color: rgba(35, 51, 81, 0.38);
}
.filters-sidebar .mat-mdc-dialog-content .filter-title div,
.filters-sidebar .mat-mdc-dialog-content .filters__titles {
  font-weight: normal !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 2;
}
.filters-sidebar .mat-mdc-dialog-content .mat-form-field {
  width: 100%;
}
.filters-sidebar .mat-mdc-dialog-content .mat-form-field input.mat-input-element {
  padding: 5px 10px;
}
.filters-sidebar .mat-mdc-dialog-content #searchableList .mdc-checkbox__background {
  background-color: white;
  border-radius: 3px;
}
.filters-sidebar .mat-mdc-dialog-content #searchableList mat-list-option[aria-selected=true] .mdc-checkbox__background {
  background-color: var(--mdc-checkbox-selected-icon-color);
  color: var(--primary-color, #F69C00);
  border-color: var(--primary-color, #F69C00);
}
.filters-sidebar .mat-mdc-dialog-content .date-range-field {
  width: 100%;
}

.order-sims .wrapper {
  position: relative;
  border-radius: 4px;
  padding: 16px;
}
.order-sims .wrapper--bg-blue {
  background: rgba(26, 26, 38, 0.04);
}
.order-sims .wrapper--bg-blue .mat-flat-button.mat-primary {
  background: #935dd7;
}
.order-sims .wrapper--bg-light-gray {
  background: rgba(26, 26, 38, 0.06);
  color: rgba(35, 51, 81, 0.6);
}
.order-sims .wrapper--bg-light-gray h2 {
  color: #3FAA19;
}
.order-sims .wrapper--bg-light-gray mat-icon {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 53px;
  color: #3FAA19;
}
.order-sims .wrapper--bg-dark-gray {
  background: rgba(35, 51, 81, 0.6);
  color: white;
}
.order-sims .wrapper--bg-dark-gray mat-divider {
  border-top-color: white;
}
.order-sims .wrapper--bg-outline {
  border: 1px solid rgba(35, 51, 81, 0.16);
}
.order-sims .mat-card {
  box-shadow: none;
  border: 1px solid rgba(35, 51, 81, 0.16);
}
.order-sims .mat-card .mat-card-header {
  padding-left: 24px;
}
.order-sims .mat-card .mat-card-header .mat-card-title {
  margin-bottom: 0;
}
.order-sims .mat-card .mat-card-header .mat-card-title .title {
  font-weight: normal;
  font-size: 20px;
}
.order-sims .mat-card .mat-card-content .mat-icon {
  font-size: 20px;
  color: rgba(35, 51, 81, 0.38);
}
.order-sims .info-icon {
  font-size: 24px;
  color: rgba(26, 26, 38, 0.6);
}
.order-sims .limit-info {
  font-size: 12px;
  color: rgba(26, 26, 38, 0.6);
}

.sim-types {
  line-height: 24px;
}
.sim-types__title {
  color: rgba(35, 51, 81, 0.6);
}
.sim-types__value {
  color: rgba(35, 51, 81, 0.87);
}

.mat-tooltip.tooltip-blue {
  background: #233351;
  max-width: 150px;
}

.billing-invoice .billing-invoice__header .mat-mdc-tab-link-container {
  padding: 0 15px 50px;
}
.billing-invoice .billing-invoice__header .mat-mdc-tab-link-container .mat-mdc-tab-link {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}

.eligible-billing-cycle-definitions .info-card-wrapper {
  margin-bottom: 0 !important;
}
.eligible-billing-cycle-definitions .button-update {
  position: relative;
  margin: -220px 15px 0 0;
  z-index: 2;
}
.eligible-billing-cycle-definitions .bd-lazy-table {
  padding: 0;
  box-shadow: none;
  border-top: none;
  border-radius: 0;
}
.eligible-billing-cycle-definitions .bd-lazy-table .default-table-header__row_head {
  padding: 0;
}

.billing-invoice-popover {
  padding: 22px !important;
  position: relative;
  font: 14px/21px Roboto, sans-serif;
  color: rgba(26, 26, 38, 0.6);
}
.billing-invoice-popover:before {
  display: block;
  content: "";
  position: absolute;
  right: 25px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
}
.billing-invoice-popover .warning-text {
  color: #F44336;
}

.sim-linked-offers-details-dialog .mat-card {
  position: relative;
  padding: 0;
  line-height: 24px;
}
.sim-linked-offers-details-dialog .mat-card-header {
  position: sticky;
  right: 0;
  top: 0;
  background: white;
  display: block;
  z-index: 100;
  background: rgba(26, 26, 38, 0.02);
}
.sim-linked-offers-details-dialog .mat-card-header .mat-h5 {
  font-size: 14px;
  color: rgba(35, 51, 81, 0.6);
}
.sim-linked-offers-details-dialog .offer-details-title {
  color: rgba(35, 51, 81, 0.6);
}
.sim-linked-offers-details-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}

.mat-card-border {
  box-shadow: none !important;
  border: 1px solid rgba(35, 51, 81, 0.16);
}

sim-linked-offers-select .mat-column-__dragAndDropColumn__ {
  border-right: none !important;
  padding: 0 10px !important;
}

.linked-offers-popover {
  color: rgba(35, 51, 81, 0.87);
  line-height: 18px;
  min-width: 225px;
  padding: 16px !important;
  position: relative;
}
.linked-offers-popover:before {
  display: block;
  content: "";
  position: absolute;
  left: 25px;
  bottom: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: white transparent transparent;
  border-width: 8px 8px 0;
}

.default-table-header-v1 {
  min-height: 40px;
  margin-bottom: 10px;
}
.default-table-header-v1 .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.default-table-header-v1 .selected-from-text,
.default-table-header-v1 .filtered-by-text {
  margin-left: 20px;
  font-weight: 500;
}
.default-table-header-v1 .system-button__icon {
  font-size: 24px;
  color: rgba(26, 26, 38, 0.6);
}
.default-table-header-v1 .system-button .header-button-icon {
  color: inherit;
}
.default-table-header-v1 .system-button.mat-stroked-button {
  background-color: white;
}
.default-table-header-v1 .filtered-by-text {
  margin-right: 10px;
}
.default-table-header-v1 mat-chip-list {
  display: inline-block;
}
.default-table-header-v1 mat-chip-list .mat-chip-remove {
  line-height: initial;
}
.default-table-header-v1 .text-search-container {
  margin-right: 16px;
}
.default-table-header-v1 .action-label {
  margin-left: 10px;
}
.default-table-header-v1 .filter-label {
  margin-left: 5px;
}

.default-table-header__actions, .default-table-header__amount {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 1;
  gap: 10px;
}
.default-table-header__row {
  height: 60px;
  background-color: #FFFFFF;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.default-table-header__row_head {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
  max-height: 4.5em;
  padding: 1.5em 0;
}
.default-table-header__row_head .header-action-btn-group {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}
.default-table-header__row_actions, .default-table-header__row_filters {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.default-table-header__row_filters {
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431));
  height: 48px;
}
.default-table-header__row_filters-parameters {
  width: fit-content;
}
.default-table-header__row_filters-parameter {
  margin: 5px 0;
}
.default-table-header__row_filters-parameter .filtered-by-text {
  color: rgba(35, 51, 81, 0.6);
}
.default-table-header__row_filters-parameter .default-table-header__separator {
  font-size: 20px;
  color: rgba(35, 51, 81, 0.16);
}
.default-table-header__row_filters .filter-counter-group {
  display: flex;
  flex-wrap: nowrap;
}
.default-table-header__row_additional_title {
  display: flex;
  align-items: center;
  background-color: white;
  height: 60px;
  font-size: 24px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.default-table-header__separator {
  padding: 0 12px;
  font-size: 20px;
  font-weight: 100;
  color: rgba(26, 26, 38, 0.6);
}
.default-table-header__button_default {
  font-size: 18px;
}
.default-table-header__button_search {
  font-size: 18px;
  width: 18px;
}
.default-table-header .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.default-table-header .title-wrapper .title-text {
  color: rgba(26, 26, 38, 0.87);
}
.default-table-header .selected-from-text {
  color: rgba(35, 51, 81, 0.6);
}
.default-table-header .filtered-by {
  font-weight: 500;
}
.default-table-header .filtered-by-text {
  color: rgba(35, 51, 81, 0.6);
  font-weight: 500;
  margin-right: 10px;
}
.default-table-header .system-button__icon {
  font-size: 24px;
  color: rgba(26, 26, 38, 0.6);
}
.default-table-header .system-button .header-button-icon {
  color: inherit;
}
.default-table-header .system-button.mat-stroked-button {
  background-color: white;
}
.default-table-header .bulk-action-button {
  font-size: 20px;
  color: rgba(26, 26, 38, 0.6);
}
.default-table-header .bulk-action-button__icon {
  font-size: 24px;
}
.default-table-header .header-padding-20 {
  padding: 0 20px;
}
.default-table-header .header-padding-24 {
  padding: 0 24px;
}

.default-table-header-v2 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
}

.filters-list-wrapper {
  overflow: hidden;
  width: 100% !important;
}

.mat-chips-row .mdc-evolution-chip-set__chips {
  flex-wrap: nowrap !important;
}

.more-button {
  align-self: center;
}

.more-button:hover {
  cursor: pointer;
  color: rgba(26, 26, 38, 0.87);
  transition: all 0.5s;
}

.clear-all-button {
  flex-shrink: 0;
}

.filter-chips-panel-popover {
  width: fit-content;
  block-size: fit-content;
}

.filter-chips-panel-popover, .filter-chips-panel {
  padding: 0 24px;
}
.filter-chips-panel-popover mat-chip-list .mat-chip-remove, .filter-chips-panel mat-chip-list .mat-chip-remove {
  line-height: initial;
}
.filter-chips-panel-popover .filtered-by-text, .filter-chips-panel .filtered-by-text {
  white-space: nowrap;
  color: rgba(35, 51, 81, 0.6);
  margin: 0 10px;
}

.filter-chips-panel-popover {
  height: fit-content;
}

.collapsed-group-wrapper:last-child {
  margin-bottom: 10px;
}

.action-label {
  margin-left: 10px;
}

.filter-label {
  margin-left: 5px;
}

mat-toolbar.main-toolbar {
  padding: 0;
  background-color: white !important;
  box-shadow: rgba(26, 26, 38, 0.08) 0 3px 4px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
mat-toolbar.main-toolbar mat-toolbar-row {
  padding: 0 16px;
  min-height: 65px;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal {
  padding: 0;
  width: 100%;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item {
  display: inline-block;
  height: auto;
  width: auto;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:first-child.mat-list-item-content {
  padding: 0 5px 0 0;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:last-child.mat-list-item-content {
  padding: 0 0 0 5px;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:last-child a {
  color: rgba(26, 26, 38, 0.87);
  text-decoration: none;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:not(:first-child):not(:last-child).mat-list-item-content {
  padding: 0 5px 0 5px;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal a {
  padding: 0;
  color: rgba(26, 26, 38, 0.87);
  font-size: 14px;
  text-decoration: underline;
}
mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal a:hover {
  text-decoration: underline;
}
mat-toolbar.main-toolbar mat-toolbar-row .arrow {
  color: rgba(26, 26, 38, 0.87);
  font-size: 14px;
}
mat-toolbar.main-toolbar mat-toolbar-row header {
  box-shadow: none !important;
}

.mat-mdc-card-header {
  width: 100%;
}
.mat-mdc-card-header .mat-mdc-card-header-text {
  width: 100%;
}
.mat-mdc-card-header span.mat-card-header-avatar {
  color: var(--primary-color, #F69C00);
  font-size: 1.5rem;
  line-height: 30px;
  margin-left: 16px;
}

sim-bd-icon-flat-button .mat-flat-button {
  line-height: 21px !important;
  padding: 0 8px !important;
}

sim-bd-dialog .mat-dialog-actions {
  color: var(--primary-color, #F69C00);
  border-top: 1px solid rgba(35, 51, 81, 0.16);
  background-color: white;
  margin: 0 -24px -24px -24px;
  padding: 8px 24px 8px 96px !important;
}

sim-bd-dialog .mat-dialog-title {
  background-color: var(--primary-color, #F69C00);
  color: var(--primary-color, #F69C00);
  margin: -24px -24px 5px -24px !important;
  padding: 12px 24px 12px 24px;
}
sim-bd-dialog .mat-dialog-title .mat-card-header-text {
  margin: 0;
}
sim-bd-dialog .mat-dialog-title .mat-card-header-text .mat-card-title {
  margin-bottom: 0 !important;
}

.mat-sidenav-container {
  height: 100%;
}
.mat-sidenav-container mat-sidenav {
  box-shadow: rgba(26, 26, 38, 0.08) 5px 65px 30px;
}
.mat-sidenav-container mat-sidenav .sidenav {
  padding-bottom: 60px;
}
.mat-sidenav-container mat-sidenav .sidenav mat-nav-list {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.mat-sidenav-container mat-sidenav .sidenav mat-nav-list .toggle-menu-btn-wrapper {
  margin-left: 5px;
}
.mat-sidenav-container mat-sidenav .sidenav mat-nav-list .mdc-list-item {
  height: 40px;
}
.mat-sidenav-container mat-sidenav .sidenav mat-nav-list .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}
.mat-sidenav-container mat-sidenav .sidenav__logo {
  padding: 10px 24px;
  text-align: left;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(35, 51, 81, 0.16);
  height: 65px;
}
.mat-sidenav-container mat-sidenav .sidenav__logo img {
  height: 45px;
  width: auto;
}
.mat-sidenav-container mat-sidenav .sidenav__category-label {
  height: 40px;
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 40px;
  color: rgba(35, 51, 81, 0.6) !important;
  letter-spacing: 1px;
}
.mat-sidenav-container mat-sidenav .sidenav__item, .mat-sidenav-container mat-sidenav .sidenav__sub-item {
  position: relative;
  box-sizing: border-box;
  box-shadow: none !important;
}
.mat-sidenav-container mat-sidenav .sidenav__item .nav-link-wrapper, .mat-sidenav-container mat-sidenav .sidenav__sub-item .nav-link-wrapper {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.mat-sidenav-container mat-sidenav .sidenav__item .mat-expansion-panel-body, .mat-sidenav-container mat-sidenav .sidenav__sub-item .mat-expansion-panel-body {
  padding: 0;
}
.mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children {
  display: block;
  height: 40px;
  margin-bottom: 5px;
  text-decoration: none;
  border-left-width: 0;
  border-left-style: solid;
  border-left-color: var(--primary-color, #F69C00);
  width: 200px;
  margin-left: 27px;
}
.mat-sidenav-container mat-sidenav .sidenav__item-icon {
  height: 24px;
  width: 24px;
  color: rgba(26, 26, 38, 0.6);
}
.mat-sidenav-container mat-sidenav .sidenav__item-label {
  margin-left: 13px;
  color: rgba(26, 26, 38, 0.87);
}
.mat-sidenav-container mat-sidenav .sidenav .active {
  background-color: rgba(var(--primary-color, #F69C00), 0.08);
}
.mat-sidenav-container mat-sidenav .sidenav .active .path-active {
  stroke: var(--primary-color, #F69C00);
}
.mat-sidenav-container mat-sidenav .sidenav .active .path-active-fill {
  fill: var(--primary-color, #F69C00);
}
.mat-sidenav-container mat-sidenav .sidenav .active :hover {
  background-color: rgba(var(--primary-color, #F69C00), 0.08) !important;
}
.mat-sidenav-container mat-sidenav .sidenav .dot-expansion-panel {
  background-color: var(--primary-color, #F69C00);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 5px;
}
.mat-sidenav-container mat-sidenav .sidenav .selected {
  background-color: transparent !important;
}
.mat-sidenav-container mat-sidenav .sidenav .selected .span-with-children {
  color: var(--primary-color, #F69C00);
}
.mat-sidenav-container mat-sidenav .sidenav .selected .i-with-children {
  color: var(--primary-color, #F69C00) !important;
}
.mat-sidenav-container mat-sidenav .sidenav .selected .path-active {
  stroke: var(--primary-color, #F69C00) !important;
}
.mat-sidenav-container mat-sidenav .sidenav .selected .path-active-fill {
  fill: var(--primary-color, #F69C00) !important;
}
.mat-sidenav-container mat-sidenav .sidenav .selected .closed-sidebar-description-panel {
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431)) !important;
  margin-right: 0;
}
.mat-sidenav-container mat-sidenav .sidenav .submenu-icon svg {
  position: absolute;
  height: 53px;
  width: 53px;
}
.mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-description-panel {
  height: 40px;
  padding: 0 16px;
}
.mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-expansion-header {
  display: initial;
  flex-direction: initial;
  align-items: initial;
  padding: initial !important;
  margin-left: 0 !important;
}
.mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-expansion-header .mat-expansion-indicator {
  display: none;
}
.mat-sidenav-container mat-sidenav .sidenav .mat-expansion-panel-header {
  padding: 0 16px;
}
.mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-without-children mat-icon svg {
  width: 24px;
}
.mat-sidenav-container mat-sidenav .sidenav .nav-link-wrapper:hover:not(.active),
.mat-sidenav-container mat-sidenav .sidenav .mat-mdc-list-item:hover:not(.active),
.mat-sidenav-container mat-sidenav .sidenav .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px !important;
}
.mat-sidenav-container mat-sidenav .sidenav .mat-mdc-list-item.active:hover {
  background-color: var(--primary-color, #F69C00) !important;
  border-radius: 6px !important;
}
.mat-sidenav-container mat-sidenav .sidenav.impersonated {
  margin-top: 41px;
}

.search-string-bold-color {
  color: var(--primary-color, #F69C00);
}

.main-toolbar .drawer-button {
  margin: 0 20px;
}
.main-toolbar .flo-toolbar-logo {
  height: 65%;
  align-self: center;
}
.main-toolbar .search__field {
  width: 370px;
  justify-content: start;
}
.main-toolbar .search__field mat-icon {
  color: rgba(26, 26, 38, 0.6);
}
.main-toolbar .search__field .toolbar-search-close-icon {
  color: rgba(35, 51, 81, 0.38);
}
.main-toolbar .search__input {
  width: 330px;
}
.main-toolbar .user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}
.main-toolbar .user-menu .user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.main-toolbar .user-menu .user-info__avatar {
  vertical-align: middle;
  margin: 0 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.main-toolbar .user-menu .user-info__initials {
  margin: 0 5px;
}
.main-toolbar .user-menu .user-info__name .user-name {
  margin: 0;
  line-height: 20px;
  color: rgba(35, 51, 81, 0.6);
}
.main-toolbar .user-menu .user-info__name .user-company {
  margin: 0;
  line-height: 15px;
  color: rgba(35, 51, 81, 0.6);
}
.main-toolbar .user-menu .user-info__arrow {
  margin-left: 5px;
  color: rgba(35, 51, 81, 0.6);
}
.main-toolbar .toolbar-menu-item {
  width: 193px;
  color: rgba(35, 51, 81, 0.6);
}
@media only screen and (max-width: 600px) {
  .main-toolbar mat-toolbar-row {
    padding: 0 !important;
  }
  .main-toolbar .sims-search__input {
    width: 100% !important;
  }
  .main-toolbar .user-menu {
    padding: 0 20px;
    margin: 0 20px !important;
  }
}
.main-toolbar .search__container {
  margin-right: 10px;
}
.main-toolbar .search__container .table-header__button_default {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-search-optgroup {
  border-top: 1px solid rgba(35, 51, 81, 0.16);
  display: block;
}
.toolbar-search-optgroup:first-child {
  border-top: none;
}
.toolbar-search-optgroup .mat-optgroup-label {
  font-weight: 400;
  color: rgba(26, 26, 38, 0.87);
}
.toolbar-search-optgroup .mat-option {
  font-size: 16px;
}

.user-menu-list .mat-mdc-menu-item .mat-icon {
  width: 19px;
  margin-right: 10px;
}

.user-info__initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color, #F69C00);
  color: #FFFFFF;
  font-size: 16px;
}

.uuid-popover:before {
  display: block;
  content: "";
  position: absolute;
  right: 12px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
}

.plans-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.plans-list .header-wrapper {
  box-shadow: 0 2px 1px -1px rgba(35, 51, 81, 0.16), 0 1px 1px 0px rgba(35, 51, 81, 0.16), 0 1px 3px 0px rgba(35, 51, 81, 0.16);
  border-top: 1px solid rgba(35, 51, 81, 0.16);
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
  margin-bottom: -10px;
  background: white;
  padding: 20px 20px 12px;
}
.plans-list__content {
  margin-top: 16px;
  padding: 3px;
  flex-grow: 1;
  overflow-y: hidden;
}

.sim-list bd-nav-breadcrumbs {
  display: block;
  margin-bottom: 0.5em;
}
.sim-list .iccid {
  display: flex;
  flex-direction: column;
}
.sim-list .iccid span {
  line-height: 1.2;
}
.sim-list .iccid span.alias {
  font-size: 11px;
  color: rgba(35, 51, 81, 0.6);
}
.sim-list sim-flo-truncate-tooltip span,
.sim-list .mat-column-country bd-icon-column-cell {
  white-space: nowrap;
}
.sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-cell.bulk-checkbox-row-header-cell,
.sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-cell.bulk-checkbox-row-cell {
  border-right: none !important;
}
.sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2),
.sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2) {
  padding-left: 0 !important;
}
.sim-list bd-lazy-table .table-wrapper .mat-mdc-table .starting-column {
  padding-left: 24px !important;
}
bd-header.sim-details-header {
  margin-bottom: 20px;
  display: block;
}
bd-header.sim-details-header nav.mat-tab-nav-bar {
  border-bottom-width: 0;
}

.sim-details-header__info {
  padding: 0 10px;
}
.sim-details-header__icon {
  color: rgba(26, 26, 38, 0.6);
}
.sim-details-header .info-field-label {
  color: rgba(35, 51, 81, 0.6);
}
.sim-details-header .info-field-value {
  color: rgba(35, 51, 81, 0.87);
  font-weight: 500;
}
.sim-details-header .flo-status {
  font-size: 14px;
  padding: 5px 10px;
}
.sim-details-header .alias-name-field {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 32px / 36px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}
.sim-details-header .expandable-msisdn-imsi-list {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.25s;
}
.sim-details-header .expandable-msisdn-imsi-list:hover {
  background-color: rgba(26, 26, 38, 0.0196078431);
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.sim-details-header__separator {
  padding: 0 8px;
  font-size: 20px;
  font-weight: 100;
  color: rgba(35, 51, 81, 0.16);
}
.sim-details-header .height64px {
  height: 64px;
}
.sim-details-header .action-label {
  margin-left: 10px;
}
.sim-details-header .sim-alias {
  margin-right: 10px;
  font-size: 32px;
  font-family: Open Sans, sans-serif;
}

.sim-details-msisdn-imsi-list.mat-mdc-menu-panel {
  max-width: none;
}

.sim-details-msisdn-imsi-list-item {
  height: 40px;
  padding: 8px 0;
  width: 390px;
}
.sim-details-msisdn-imsi-list-item .sim-details-header__separator {
  padding: 0 8px;
}
.sim-details-msisdn-imsi-list-item-text-imsi {
  padding-left: 20px;
}
.sim-details-msisdn-imsi-list-item-text-msisdn {
  padding-right: 20px;
}
.sim-details-msisdn-imsi-list-item .info-field-label {
  color: rgba(35, 51, 81, 0.6);
}
.sim-details-msisdn-imsi-list-item .info-field-value {
  font-weight: 500;
  color: rgba(35, 51, 81, 0.87);
}

.sim-imeisv {
  margin-top: 0.5em;
  margin-left: -12em;
  padding: 0.5em 1em;
}

.sim-profiles .mat-mdc-card-title {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 24px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.4px !important;
}
.sim-profiles .mat-elevation-z1 {
  box-shadow: none;
}
.sim-profiles .mat-mdc-tab-group {
  display: flex;
  flex-direction: row;
  border: none;
}
.sim-profiles .mat-mdc-tab-header {
  width: 285px;
  margin-right: 15px;
}
.sim-profiles .mat-mdc-tab-list {
  max-width: 100%;
}
.sim-profiles .mat-mdc-tab-labels {
  display: flex;
  flex-direction: column;
  max-height: 420px;
  overflow: auto;
  border: 1px solid rgba(26, 26, 38, 0.16);
  border-radius: 6px;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab {
  justify-content: flex-start;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
  border-bottom: 1px solid rgba(26, 26, 38, 0.16);
  padding: 0 10px;
  max-width: 100%;
  width: 100%;
  min-width: auto;
  text-align: left;
  position: relative;
  color: rgba(35, 51, 81, 0.87);
  opacity: 1;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .label-color {
  color: rgba(35, 51, 81, 0.6);
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab-indicator__content--underline {
  border: none;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .mat-icon {
  position: absolute;
  right: -11px;
  top: 50%;
  margin-top: -11px;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .active-tab {
  color: #3FAA19;
  font-size: 12px;
  padding: 0 5px;
  background: rgba(63, 170, 25, 0.06);
  margin-left: 5px;
  line-height: 20px;
  max-height: 20px;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab__content {
  width: 100%;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab__text-label {
  display: block;
  width: 100%;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active {
  background: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431));
  color: initial;
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: rgba(35, 51, 81, 0.87);
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active mat-icon {
  color: var(--primary-color, #F69C00);
}
.sim-profiles .mat-mdc-tab-labels .mdc-tab:last-child {
  border-bottom: none;
}
.sim-profiles .mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body {
  flex-grow: 1;
}
.sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table {
  border: 1px solid rgba(26, 26, 38, 0.16);
  border-radius: 6px;
  box-shadow: none;
}
.sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-header-row, .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-row, .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-footer-row {
  height: 42px;
}
.sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-header-row th.mat-mdc-header-cell {
  border-bottom: 1px solid rgba(26, 26, 38, 0.16) !important;
}
.sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-row:last-child td.mat-cell {
  border: none;
}
.sim-profiles .mat-mdc-ink-bar {
  display: none;
}

.drag-and-drop-menu-container #uploader__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.drag-and-drop-menu-container .uploader {
  background-color: white;
  border: 1px dashed rgba(26, 26, 38, 0.16);
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 160px;
  color: rgba(26, 26, 38, 0.38);
}
.drag-and-drop-menu-container .uploader .gap {
  gap: 4px;
}
.drag-and-drop-menu-container .uploader__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1em;
}
.drag-and-drop-menu-container .uploader__label .uploader__icon {
  margin-bottom: 10px;
  width: 45px;
  height: 30px;
}
.drag-and-drop-menu-container .uploader__label .uploader__icon svg path {
  fill: rgba(26, 26, 38, 0.16);
}
.drag-and-drop-menu-container .uploader__label .uploader__title {
  margin-bottom: 0;
  white-space: nowrap;
  color: rgba(26, 26, 38, 0.6);
}
.drag-and-drop-menu-container .uploader__label .uploader__subtitle {
  margin-bottom: 0;
  color: rgba(26, 26, 38, 0.38);
  font-weight: normal;
}
.drag-and-drop-menu-container .uploader__label .description-divider {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(26, 26, 38, 0.08);
  color: rgba(26, 26, 38, 0.6);
  display: inline-block;
  text-align: center;
}
.drag-and-drop-menu-container .uploader--active {
  border-color: rgba(35, 51, 81, 0.6);
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431));
}
.drag-and-drop-menu-container .uploader--active .uploader__icon {
  opacity: 0.8;
}
.drag-and-drop-menu-container .uploader--reject {
  background-color: #ffebee;
}
.drag-and-drop-menu-container .button {
  padding: 10px 0 10px 10px;
  text-align: end;
}
.drag-and-drop-menu-container .icon {
  text-align: center;
  width: 35px;
  height: 35px;
  font-size: 30px;
}
.drag-and-drop-menu-container .success {
  color: #3FAA19;
}
.drag-and-drop-menu-container .reject {
  color: #f44336;
}

.flo-dialog__content.p-0 {
  padding: 0 !important;
}
.flo-dialog__content.p-0 .uploading-state {
  margin: 32px 0 14px !important;
}

.dialog-content__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
}
.dialog-content__title mat-icon {
  height: 32px;
  width: 32px;
}
.dialog-content__title button {
  margin-top: 15px;
}
.dialog-content__title button span {
  margin-top: unset;
}
.dialog-content__title span {
  margin-outside: 20px;
  margin-top: 8px;
  color: rgba(26, 26, 38, 0.87);
  text-align: center;
}
.dialog-content__title span.success-text {
  color: #3FAA19;
  font-size: 13px;
}
.dialog-content__title span.error-text {
  color: #F44336;
  font-size: 13px;
}

.flo-dialog h3.mat-mdc-dialog-title {
  margin-bottom: 8px;
}
.flo-dialog .dialog-warning-icon {
  color: #f44336;
}
.flo-dialog__dialog-warning-line {
  border-left: 12px solid #f44336;
}
.flo-dialog__dialog-warning-line .mdc-dialog__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.flo-dialog__header {
  margin-top: -34px;
  margin-bottom: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flo-dialog__header-warn {
  color: #f44336;
}
.flo-dialog__divider {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.flo-dialog__content {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 20px !important;
  position: relative;
  line-height: 24px !important;
}
.flo-dialog__actions {
  padding: 16px 8px !important;
}
.flo-dialog__section-label:first-child {
  margin: 0 0 24px 0 !important;
}
.flo-dialog__section-label:not(:first-child) {
  margin: 24px 0 !important;
}

.divider-tune {
  height: 24px;
  margin: 0 8px 0 4px;
}

.service-providers .mat-tab-link {
  padding: 0 10px;
  min-width: 410px;
  opacity: 1;
}
.service-providers .no-data {
  max-height: 240px !important;
  overflow: hidden;
}

.system-labels .mat-chip.mat-standard-chip {
  color: rgba(35, 51, 81, 0.38);
  background-color: white;
  border-radius: 6px;
  border: 1px solid rgba(35, 51, 81, 0.38);
  max-width: 195px;
}

.sidebar-filters-dialog {
  height: calc(100vh - 64px);
}
.sidebar-filters-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}
.sidebar-filters-dialog .mat-dialog-container .mat-dialog-content {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(calc(100vh - 64px) - 64px - 64px);
  height: calc(calc(100vh - 64px) - 64px - 64px);
}
.sidebar-filters-dialog .filters-container {
  max-width: 340px;
  width: 100%;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-filters-dialog .filters-container .mat-card-actions {
  margin: 0;
  height: 64px;
  padding: 10px;
}
.sidebar-filters-dialog .filters-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  padding: 14px 20px;
}
.sidebar-filters-dialog .mat-card-header {
  display: block;
  margin: 0;
}
.sidebar-filters-dialog .mat-flat-button {
  padding: 0 30px;
}
.sidebar-filters-dialog .date-range-field {
  width: 100%;
}

/*
provide this kind of colours in the theme
 */
.batch-operation-list-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  padding: 3px;
}
.batch-operation-list-details lib-custom-header {
  margin-bottom: 0.5em;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container {
  display: flex;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button:not(:last-of-type) {
  margin-right: 8px;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button.btn-delete {
  color: #233351;
  border-color: var(--primary-color, #F69C00);
  display: flex;
  align-items: center;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button.btn-refresh {
  color: #935dd7;
  border-color: rgba(147, 93, 215, 0.06);
  display: flex;
  align-items: center;
}
.batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button span {
  margin-left: 6px;
}
.batch-operation-list-details .header-additional-row {
  display: flex;
  align-items: center;
  height: 88px;
  width: 100%;
  padding: 0 1em;
  gap: 0.5em;
  margin-top: 0.5em;
}
.batch-operation-list-details .header-additional-row .header-additional-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  margin-right: 16px;
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
  width: 380px;
}
.batch-operation-list-details .header-additional-row .header-additional-col .label {
  color: rgba(35, 51, 81, 0.6);
}
.batch-operation-list-details bd-lazy-table {
  flex-grow: 1;
}
.batch-operation-list-details bd-lazy-table .status-column-container {
  display: flex;
  justify-content: flex-end;
}
.batch-operation-list-details bd-lazy-table .status-column-container button {
  background: #935dd7;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.labels-wrapper {
  padding: 6px 8px;
  max-width: 200px;
  background: white;
  border: solid 1px rgba(35, 51, 81, 0.16) !important;
  height: 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
}
.labels-wrapper .dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  background-color: var(--primary-color, #F69C00);
  border-radius: 50%;
  display: inline-block;
}
.labels-wrapper .labels-text, .labels-wrapper sim-flo-truncate-tooltip {
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 51, 81, 0.6);
}

:host-context(.white-labels) .labels-wrapper,
.white-labels .labels-wrapper {
  background: white;
  border: 1px solid rgba(35, 51, 81, 0.16);
  border-radius: 22px;
}
:host-context(.white-labels) .labels-wrapper .labels-text,
.white-labels .labels-wrapper .labels-text {
  color: rgba(35, 51, 81, 0.6);
}

.chip-wrapper {
  padding: 3px 8px;
  background: #FFFFFF;
  border: solid 1px rgba(35, 51, 81, 0.16) !important;
  height: 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
.chip-wrapper .chip-content {
  border-left: 1px solid rgba(35, 51, 81, 0.16);
  padding-left: 6px;
  margin-left: 6px;
}
.chip-wrapper .chip-content:first-child {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}
.chip-wrapper .chip-title, .chip-wrapper .chip-text, .chip-wrapper sim-flo-truncate-tooltip {
  font-size: 12px;
  line-height: 18px;
  color: rgba(35, 51, 81, 0.6);
}
.chip-wrapper .chip-title {
  padding-right: 5px;
}
.chip-wrapper .flo-icon-color {
  color: rgba(35, 51, 81, 0.38);
}

.flo-chip-v2 .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel .mdc-evolution-chip-set__chips, .filter-chips-panel-popover .mdc-evolution-chip-set__chips, .filter-chips-panel .mdc-evolution-chip-set__chips {
  align-items: center;
}
.flo-chip-v2 .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel .mdc-evolution-chip__text-label, .filter-chips-panel-popover .mdc-evolution-chip__text-label, .filter-chips-panel .mdc-evolution-chip__text-label {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  background-color: white;
  border: 1px solid rgba(35, 51, 81, 0.16);
  --mdc-chip-label-text-color: rgba(35, 51, 81, 0.6);
  --mdc-chip-container-height: 26px;
  min-height: 26px;
}
.flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip::after, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip::after, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip::after, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip::after, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip::after {
  background: transparent;
}
.flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  color: rgba(35, 51, 81, 0.38);
  opacity: 1;
}
.flo-chip-v2 .add-new-chip-button, .dark-theme .filter-chips-panel-popover .add-new-chip-button, .dark-theme .filter-chips-panel .add-new-chip-button, .filter-chips-panel-popover .add-new-chip-button, .filter-chips-panel .add-new-chip-button {
  border: none;
  background-color: var(--primary-color, #F69C00);
  height: 22px;
  width: 22px;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
}
.flo-chip-v2 .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel-popover .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel .add-new-chip-button mat-icon, .filter-chips-panel-popover .add-new-chip-button mat-icon, .filter-chips-panel .add-new-chip-button mat-icon {
  height: 22px;
  width: 22px;
  color: white;
}

.invoices-list {
  padding: 0 !important;
  overflow: hidden;
}
.invoices-list bd-lazy-table {
  box-shadow: none;
  border: none;
}
.invoices-list bd-lazy-table .default-table-header {
  border-top: 1px solid rgba(26, 26, 38, 0.16);
}
.invoices-list bd-lazy-table .mat-header-cell {
  border-bottom: 1px solid rgba(35, 51, 81, 0.38) !important;
}
.invoices-list bd-lazy-table .hidden-table,
.invoices-list bd-lazy-table .default-table-header__row_head {
  display: none !important;
}
.invoices-list .download-column-container a {
  cursor: pointer;
  color: rgba(35, 51, 81, 0.6);
}
.invoices-list .download-column-container > mat-icon {
  color: rgba(35, 51, 81, 0.24);
}

.lib-bss-popover {
  padding: 22px !important;
  position: relative;
  font: 14px/21px Roboto, sans-serif;
  color: rgba(26, 26, 38, 0.6);
}
.lib-bss-popover:before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}
.lib-bss-popover.arrow-left-top:before {
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
  left: 18px;
  top: -8px;
}
.lib-bss-popover.arrow-left-top.gap-small:before {
  left: 6px;
}
.lib-bss-popover .title-text {
  color: rgba(26, 26, 38, 0.87);
}
.lib-bss-popover .warning-text {
  color: #F44336;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flo-slim-select-panel,
.flo-date-range-selector {
  display: flex;
  flex-direction: column;
}
.flo-slim-select-panel div.mat-mdc-select-panel,
.flo-date-range-selector div.mat-mdc-select-panel {
  padding-top: 0 !important;
}
.flo-slim-select-panel .mat-mdc-select-panel,
.flo-date-range-selector .mat-mdc-select-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  border-radius: 4px;
  outline: 0;
}
.flo-slim-select-panel .mat-mdc-form-field-hint,
.flo-date-range-selector .mat-mdc-form-field-hint {
  margin-bottom: 4px;
}
.flo-slim-select-panel .mat-mdc-text-field-wrapper,
.flo-date-range-selector .mat-mdc-text-field-wrapper {
  height: 36px !important;
  padding-bottom: 0;
}
.flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-floating-label,
.flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 50% !important;
}
.flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  font-size: 14px !important;
}
.flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix,
.flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  padding-bottom: 16px !important;
}
.flo-slim-select-panel .mat-mdc-text-field-wrapper .mdc-floating-label--float-above,
.flo-date-range-selector .mat-mdc-text-field-wrapper .mdc-floating-label--float-above {
  margin-top: 12px !important;
}
.flo-slim-select-panel .mdc-notched-outline__leading, .flo-slim-select-panel .mdc-notched-outline__notch, .flo-slim-select-panel .mdc-notched-outline__trailing,
.flo-date-range-selector .mdc-notched-outline__leading,
.flo-date-range-selector .mdc-notched-outline__notch,
.flo-date-range-selector .mdc-notched-outline__trailing {
  border-color: rgba(26, 26, 38, 0.12) !important;
}
.flo-slim-select-panel .mdc-text-field--outlined,
.flo-date-range-selector .mdc-text-field--outlined {
  background: white;
}
.flo-slim-select-panel .mat-mdc-form-field-infix,
.flo-date-range-selector .mat-mdc-form-field-infix {
  min-height: unset;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: unset;
  padding-top: 0 !important;
}
.flo-slim-select-panel .mat-mdc-form-field-infix mat-select,
.flo-date-range-selector .mat-mdc-form-field-infix mat-select {
  transform: translateY(-2px);
}
.flo-slim-select-panel .mat-mdc-form-field-infix mat-select .mat-mdc-select-arrow-wrapper,
.flo-date-range-selector .mat-mdc-form-field-infix mat-select .mat-mdc-select-arrow-wrapper {
  transform: translateY(-1px) !important;
}

.flo-textarea-panel .mat-mdc-text-field-wrapper {
  height: auto !important;
}

.flo-thin-picker-overlay {
  padding-top: 0 !important;
}
.flo-thin-picker-overlay .mat-mdc-option {
  min-height: 42px;
}
.mat-mdc-select-panel {
  padding-top: 0 !important;
}

.datepicker-hidden {
  display: flex;
  flex-direction: column;
  height: 0;
  justify-content: flex-start;
  overflow: hidden;
  width: 0;
}

.custom-pseudo-checkbox-mat-option {
  display: flex !important;
  align-items: center !important;
  padding-left: 16px;
}
.custom-pseudo-checkbox-mat-option .mdc-checkbox__native-control {
  display: none;
}
.custom-pseudo-checkbox-mat-option .mdc-checkbox {
  padding: 0;
  margin-right: 8px;
}
.custom-pseudo-checkbox-mat-option .mdc-checkbox__background {
  position: static;
}
.custom-pseudo-checkbox-mat-option .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: scale(0.7);
}
.custom-pseudo-checkbox-mat-option .mdc-form-field > label {
  padding-left: 0;
}

.flo-input-options {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
  border: solid 1px rgba(35, 51, 81, 0.16);
  transition: border-color 0.1s ease-in;
  border-radius: 4px;
  background: white;
}
.flo-input-options .input-field {
  min-width: 150px;
  padding: 0;
  border: none;
  background: none;
  color: rgba(26, 26, 38, 0.87);
  border-right: solid 1px rgba(35, 51, 81, 0.16) !important;
}
.flo-input-options .input-field:focus-visible {
  outline: none;
}
.flo-input-options .input-icon {
  flex-shrink: 0;
  margin: 0 10px;
  height: 20px;
  width: 20px;
  color: inherit;
}
.flo-input-options .option-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  height: 36px;
  border: none;
  background: none;
  padding: 5px 5px;
  cursor: pointer;
}
.flo-input-options .option-btn .arrow-down {
  transition: color 0.2s ease-out;
}
.flo-input-options .option-btn:hover .arrow-down {
  color: rgba(26, 26, 38, 0.87);
}
.flo-input-options .option-btn:focus .arrow-down {
  color: var(--primary-color, #F69C00);
}
.flo-input-options .option-btn__text {
  color: rgba(26, 26, 38, 0.87);
  letter-spacing: 0.5px;
}

.flo-input-menu-item {
  color: rgba(26, 26, 38, 0.87);
}

.active-menu-item {
  color: var(--primary-color, #F69C00) !important;
  background: transparent !important;
}

.disable-field {
  pointer-events: none;
  color: rgba(35, 51, 81, 0.16);
}

.input-auto-sizer {
  width: fit-content;
  max-width: 420px;
}

.schedule-settings-input {
  border: 1px solid rgba(35, 51, 81, 0.16);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
  cursor: pointer;
}
.schedule-settings-input .tune-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  margin-left: 12px;
}
.schedule-settings-input__warn {
  border-bottom-color: #f44336;
}
.schedule-settings-input__warn .inner-wrapper {
  padding-bottom: 0;
}
.schedule-settings-input:hover {
  border-color: rgba(26, 26, 38, 0.38);
}
.schedule-settings-input:active {
  border-color: var(--primary-color, #F69C00);
}
.schedule-settings-input .field-label {
  position: relative;
  top: 18px;
  border-top: 0.84375em solid transparent;
  padding: 0.25em 0.75em 0.75em 0.75em;
  line-height: 1.125;
}
.schedule-settings-input .field-label__warn {
  color: #f44336;
}
.schedule-settings-input .inner-wrapper {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 16px 1px 16px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.schedule-settings-input .inner-wrapper-text-field {
  position: relative;
  top: 2px;
  border-top: 0.84375em solid transparent;
  padding: 0.25em 0 0.75em 0;
  line-height: 1.125;
}
.schedule-settings-input .inner-wrapper .filled-field-label {
  color: rgba(35, 51, 81, 0.6);
  scale: 0.8;
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 16px;
}

.scheduling-options-popup {
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  cursor: grab;
}
.scheduling-options-popup:active {
  cursor: grabbing;
}
.scheduling-options-popup__small {
  width: 480px;
}
.scheduling-options-popup .popup-header {
  margin-bottom: 5px;
  color: rgba(26, 26, 38, 0.87);
}
.scheduling-options-popup .period-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.scheduling-options-popup .radio-group-wrapper {
  display: flex;
  flex-direction: column;
}
.scheduling-options-popup .radio-group-wrapper .radio-group {
  color: rgba(35, 51, 81, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding-bottom: 10px;
}
.scheduling-options-popup .radio-group-wrapper .radio-group .month-radio-button-text-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.scheduling-options-popup .month-period {
  height: fit-content;
  max-width: 950px;
}
.scheduling-options-popup .days-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.scheduling-options-popup .days-list-wrapper .day-card {
  cursor: pointer;
  height: 32px;
  width: 43px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid rgba(35, 51, 81, 0.16);
}
.scheduling-options-popup .days-list-wrapper .day-card__active {
  border-color: var(--primary-color, #F69C00);
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431));
}
.scheduling-options-popup .days-list-wrapper .day-card__disabled {
  color: rgba(35, 51, 81, 0.38);
  cursor: default;
}
.scheduling-options-popup .days-list-wrapper .times-per-day-card {
  width: 84px;
}
.scheduling-options-popup .action-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

._flo-tooltip, .dark-theme .flo-sim-info-usage-tip .mdc-tooltip__surface, .dark-theme .flo-custom-popup, .flo-sim-info-usage-tip .mdc-tooltip__surface, .flo-custom-popup {
  background-color: white !important;
  padding: 12px !important;
  color: rgba(26, 26, 38, 0.6) !important;
  max-width: 370px !important;
  border-radius: 4px;
  word-wrap: normal;
  word-break: break-word;
  white-space: pre-line;
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
  box-shadow: 4px 4px 12px 6px rgba(26, 26, 38, 0.08);
}

.flo-tooltip-color {
  color: rgba(26, 26, 38, 0.6);
}

.flo-tooltip-icon-color {
  color: rgba(26, 26, 38, 0.38);
}

.flo-tooltip-font {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-truncate-tooltip .mdc-tooltip__surface {
  background-color: white !important;
  color: rgba(26, 26, 38, 0.6) !important;
  border-radius: 4px;
  max-width: 225px;
  word-wrap: normal;
  word-break: break-word;
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
  box-shadow: 4px 4px 12px 6px rgba(26, 26, 38, 0.08);
  white-space: pre-line !important;
}

.flo-info-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.flo-select-search {
  align-items: center;
  background: white;
  border-bottom: 1px solid #cccccc;
  display: flex;
  position: sticky;
  gap: 4px;
  padding: 10px 16px;
  top: 0;
  width: 100%;
  z-index: 1;
}
.flo-select-search .input {
  width: inherit;
  color: rgba(26, 26, 38, 0.87);
  border: unset;
  height: 26px;
}
.flo-select-search .input::placeholder {
  color: rgba(35, 51, 81, 0.38);
}
.flo-select-search .search-icon {
  color: rgba(35, 51, 81, 0.38);
  flex-shrink: 0;
  height: 20px;
  width: 20px;
}
.flo-select-search .cancel-icon {
  flex-shrink: 0;
  line-height: 20px;
  width: 20px;
}
.flo-select-search .small-icon-button {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.flo-select-search .hidden {
  visibility: hidden;
}

.thin-select {
  padding: 6px 16px;
}
.thin-select .input {
  font-size: 14px !important;
  height: 26px;
}

.no-entries-found {
  padding: 16px;
}

.create-new {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 1em;
  font-size: 16px;
  cursor: pointer;
}
.create-new .plus {
  height: 25px;
  width: 30px;
  font-size: 24px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: var(--primary-color, #F69C00);
  line-height: 1;
  text-align: center;
  vertical-align: center;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.row--form-row {
  margin: 0 -5px;
}

.row--form-row > *[class*=col-] {
  padding: 0 5px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button.small-mat-button {
  line-height: 21px !important;
  padding: 0 8px !important;
}

.dynamic-table-default-icon:after {
  content: "--";
}

.flo-chip {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
  display: inline-block;
  padding: 0 10px;
}

.flo-chip-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  margin: -1px 10px -1px -11px;
  width: 32px;
  height: 32px;
}

.flo-form--toggle {
  height: 3.9375em !important;
}

.flo-form--checkbox {
  height: 3.9375em !important;
  line-height: 3.9375em !important;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.tabs-outline {
  position: relative !important;
  padding-left: 14px;
}

.tabs-outline .mdc-tab__text-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.tabs-outline .mat-mdc-tab-link {
  position: relative;
  z-index: 2;
  max-width: 50%;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab {
  border-radius: 8px 8px 0 0;
  border: solid 1px rgba(26, 26, 38, 0.12);
  border-bottom-color: transparent;
  display: block;
  margin: 0 8px;
  transition: background ease 0.25s;
  padding: 0 36px;
  height: 52px;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--inactive {
  border-bottom-color: transparent;
  color: rgba(26, 26, 38, 0.6);
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--inactive:hover {
  cursor: pointer;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--active {
  background: white;
  border-color: var(--primary-color, #F69C00);
  border-bottom-color: #FFFFFF;
  color: var(--primary-color, #F69C00);
}

.tabs-outline .mat-mdc-tab-link.mdc-tab--active:hover, .tabs-outline .mat-mdc-tab-link.mdc-tab--active:active, .tabs-outline .mat-mdc-tab-link.mdc-tab--active:visited {
  background: white;
  border-bottom-color: #FFFFFF;
  color: var(--primary-color, #F69C00);
  cursor: default;
}

.tabs-outline .mat-mdc-tab-link.mdc-tab:first-child .tabs-outline .mat-mdc-tab-link.mdc-tab__link {
  margin-left: 20px;
}

.tabs-outline .mdc-tab-indicator {
  display: none;
}

.tabs-outline:before {
  display: block;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(26, 26, 38, 0.16);
  z-index: 1;
  height: 1px;
}

.table-tabs-outline {
  position: relative;
}

.table-tabs-outline .tabs-outline {
  padding-left: 0;
}

.table-tabs-outline .tabs-outline:before {
  display: none;
}

.table-tabs-outline .default-table-header__row_head {
  max-height: 140px;
  min-height: 140px;
  align-items: flex-end !important;
}

.table-tabs-outline .default-table-header__row_head .title-wrapper {
  margin-bottom: 65px !important;
}

.table-tabs-outline .default-table-header__row {
  background: #FFFFFF;
}

.table-tabs-outline .table-tabs-container {
  position: absolute;
  left: 20px;
  top: 108px;
}

.flo-snack-bar {
  min-width: 10px;
  color: #FFFFFF !important;
}

.flo-snack-bar .mat-button {
  color: #FFFFFF !important;
}

.flo-snack-bar__success {
  background-color: #28a745 !important;
}

.flo-snack-bar__error {
  background-color: #d05764 !important;
}

.flo-snack-bar__info {
  background-color: #17a2b8 !important;
}

.flo-snack-bar__warning {
  background-color: #FFA87D !important;
}

bd-header header.full-width-header {
  margin: -30px -30px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.highcharts-gauge-main-number {
  font-family: "Poppins SemiBold", sans-serif !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 36px !important;
  letter-spacing: -0.404406px !important;
  color: #2C405A;
}

.highcharts-gauge-main-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #696974;
  border-radius: 4px;
}

.highcharts-gauge-small-title {
  font-family: "Poppins SemiBold", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.highcharts-gauge-loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.preloader-img {
  max-width: 100%;
  max-height: 100%;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.sidenav-typography, .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .mat-sidenav-container mat-sidenav .sidenav__item-label, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 16px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.2px !important;
}

.button-little-typography {
  /*Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.button-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 14px / 16px Open Sans, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.table-title-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 11.5px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.table-data-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-counter-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.flo-thin-input-typography, .search__input, .flo-slim-select-panel .mat-mdc-select,
.flo-date-range-selector .mat-mdc-select, .flo-thin-picker-overlay span.mdc-list-item__primary-text, .dark-theme .search__input, .dark-theme .flo-slim-select-panel .mat-mdc-select,
.dark-theme .flo-date-range-selector .mat-mdc-select, .dark-theme .flo-thin-picker-overlay span.mdc-list-item__primary-text {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.table-filter-selected-chip, .default-table-header__row_filters-parameter .filtered-by-text, .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .default-table-header__row_filters-parameter .filtered-by-text, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-typography, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.label-caption-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 11px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.info-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 13px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.list-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.note-text-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 21px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.markup-chip-content-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}

.custom-markup-chip-typography {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.helper-typography, .mdc-tooltip {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.mat-mdc-form-field-error {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 500 10px / 12px Roboto, sans-serif !important;
  letter-spacing: 0.5px !important;
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 600 12px / 14px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: Roboto, sans-serif;
}

.font-family-secondary {
  font-family: Open Sans, sans-serif;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium, .system-labels .mat-chip.mat-standard-chip, .dark-theme .system-labels .mat-chip.mat-standard-chip {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.mat-typography h1 {
  font-size: 32px;
}

.mat-typography h1.smaller {
  font-size: 24px;
}

.flo-progress-bar {
  border-radius: 4px;
}

.flo-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #E2E2EA !important;
}

.flo-progress-bar--green .mdc-linear-progress__bar-inner {
  border-color: #2ED47A !important;
}

.flo-progress-bar--yellow .mdc-linear-progress__bar-inner {
  border-color: #FACA00 !important;
}

.flo-progress-bar--red .mdc-linear-progress__bar-inner {
  border-color: #FC5A5A !important;
}

.dark-theme {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  /*
  provide this kind of colours in the theme
   */
}
.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme {
  --mat-option-selected-state-label-text-color: #3b8da3;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #3b8da3;
}
.dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}
.dark-theme {
  --mat-optgroup-label-text-color: white;
}

.dark-theme .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-elevation-z0, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme {
  --mat-option-selected-state-label-text-color: #3b8da3;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #3b8da3;
}
.dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}
.dark-theme {
  --mat-optgroup-label-text-color: white;
}

.dark-theme .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b8da3;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b8da3;
}
.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-elevation-z0, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-mdc-card {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3b8da3;
  --mdc-linear-progress-track-color: rgba(59, 141, 163, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(59, 141, 163, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(59, 141, 163, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(59, 141, 163, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(59, 141, 163, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(59, 141, 163, 0.25));
}
.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #3b8da3;
  --mdc-linear-progress-track-color: rgba(59, 141, 163, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(59, 141, 163, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(59, 141, 163, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(59, 141, 163, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(59, 141, 163, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(59, 141, 163, 0.25));
}
.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.dark-theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.dark-theme {
  --mdc-filled-text-field-caret-color: #3b8da3;
  --mdc-filled-text-field-focus-active-indicator-color: #3b8da3;
  --mdc-filled-text-field-focus-label-text-color: rgba(59, 141, 163, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3b8da3;
  --mdc-outlined-text-field-focus-outline-color: #3b8da3;
  --mdc-outlined-text-field-focus-label-text-color: rgba(59, 141, 163, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.dark-theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme .mat-mdc-form-field-subscript-wrapper,
.dark-theme .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.dark-theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.dark-theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(59, 141, 163, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(59, 141, 163, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #3b8da3;
  --mdc-filled-text-field-focus-active-indicator-color: #3b8da3;
  --mdc-filled-text-field-focus-label-text-color: rgba(59, 141, 163, 0.87);
  --mdc-outlined-text-field-caret-color: #3b8da3;
  --mdc-outlined-text-field-focus-outline-color: #3b8da3;
  --mdc-outlined-text-field-focus-label-text-color: rgba(59, 141, 163, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.dark-theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.dark-theme {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(59, 141, 163, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(59, 141, 163, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

.dark-theme {
  --mat-autocomplete-background-color: #424242;
}

.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-elevated-container-color: #3b8da3;
  --mdc-chip-elevated-disabled-container-color: #3b8da3;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-elevated-container-color: #3b8da3;
  --mdc-chip-elevated-disabled-container-color: #3b8da3;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(255, 255, 255, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #9ddeea;
  --mdc-switch-selected-handle-color: #9ddeea;
  --mdc-switch-selected-hover-state-layer-color: #9ddeea;
  --mdc-switch-selected-pressed-state-layer-color: #9ddeea;
  --mdc-switch-selected-focus-handle-color: #b2f3ff;
  --mdc-switch-selected-hover-handle-color: #b2f3ff;
  --mdc-switch-selected-pressed-handle-color: #b2f3ff;
  --mdc-switch-selected-focus-track-color: #3c7d93;
  --mdc-switch-selected-hover-track-color: #3c7d93;
  --mdc-switch-selected-pressed-track-color: #3c7d93;
  --mdc-switch-selected-track-color: #3c7d93;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #9ddeea;
  --mdc-switch-selected-handle-color: #9ddeea;
  --mdc-switch-selected-hover-state-layer-color: #9ddeea;
  --mdc-switch-selected-pressed-state-layer-color: #9ddeea;
  --mdc-switch-selected-focus-handle-color: #b2f3ff;
  --mdc-switch-selected-hover-handle-color: #b2f3ff;
  --mdc-switch-selected-pressed-handle-color: #b2f3ff;
  --mdc-switch-selected-focus-track-color: #3c7d93;
  --mdc-switch-selected-hover-track-color: #3c7d93;
  --mdc-switch-selected-pressed-track-color: #3c7d93;
  --mdc-switch-selected-track-color: #3c7d93;
}
.dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.dark-theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b8da3;
  --mdc-radio-selected-hover-icon-color: #3b8da3;
  --mdc-radio-selected-icon-color: #3b8da3;
  --mdc-radio-selected-pressed-icon-color: #3b8da3;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #3b8da3;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b8da3;
  --mdc-radio-selected-hover-icon-color: #3b8da3;
  --mdc-radio-selected-icon-color: #3b8da3;
  --mdc-radio-selected-pressed-icon-color: #3b8da3;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #3b8da3;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.dark-theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3b8da3;
  --mdc-slider-focus-handle-color: #3b8da3;
  --mdc-slider-hover-handle-color: #3b8da3;
  --mdc-slider-active-track-color: #3b8da3;
  --mdc-slider-inactive-track-color: #3b8da3;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3b8da3;
  --mat-mdc-slider-ripple-color: #3b8da3;
  --mat-mdc-slider-hover-ripple-color: rgba(59, 141, 163, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(59, 141, 163, 0.2);
}
.dark-theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #3b8da3;
  --mdc-slider-focus-handle-color: #3b8da3;
  --mdc-slider-hover-handle-color: #3b8da3;
  --mdc-slider-active-track-color: #3b8da3;
  --mdc-slider-inactive-track-color: #3b8da3;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3b8da3;
  --mat-mdc-slider-ripple-color: #3b8da3;
  --mat-mdc-slider-hover-ripple-color: rgba(59, 141, 163, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(59, 141, 163, 0.2);
}
.dark-theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.dark-theme {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
}

.dark-theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.dark-theme .mdc-list-item__start,
.dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b8da3;
  --mdc-radio-selected-hover-icon-color: #3b8da3;
  --mdc-radio-selected-icon-color: #3b8da3;
  --mdc-radio-selected-pressed-icon-color: #3b8da3;
}
.dark-theme .mat-accent .mdc-list-item__start,
.dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b8da3;
  --mdc-radio-selected-hover-icon-color: #3b8da3;
  --mdc-radio-selected-icon-color: #3b8da3;
  --mdc-radio-selected-pressed-icon-color: #3b8da3;
}
.dark-theme .mat-warn .mdc-list-item__start,
.dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b8da3;
  --mdc-checkbox-selected-hover-icon-color: #3b8da3;
  --mdc-checkbox-selected-icon-color: #3b8da3;
  --mdc-checkbox-selected-pressed-icon-color: #3b8da3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-hover-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b8da3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b8da3;
  --mdc-checkbox-selected-hover-icon-color: #3b8da3;
  --mdc-checkbox-selected-icon-color: #3b8da3;
  --mdc-checkbox-selected-pressed-icon-color: #3b8da3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-hover-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b8da3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3b8da3;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3b8da3;
}
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3b8da3;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #3b8da3;
  --mat-tab-header-active-ripple-color: #3b8da3;
  --mat-tab-header-inactive-ripple-color: #3b8da3;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3b8da3;
  --mat-tab-header-active-hover-label-text-color: #3b8da3;
  --mat-tab-header-active-focus-indicator-color: #3b8da3;
  --mat-tab-header-active-hover-indicator-color: #3b8da3;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #3b8da3;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #3b8da3;
  --mat-tab-header-active-ripple-color: #3b8da3;
  --mat-tab-header-inactive-ripple-color: #3b8da3;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3b8da3;
  --mat-tab-header-active-hover-label-text-color: #3b8da3;
  --mat-tab-header-active-focus-indicator-color: #3b8da3;
  --mat-tab-header-active-hover-indicator-color: #3b8da3;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3b8da3;
  --mat-tab-header-with-background-foreground-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #3b8da3;
  --mat-tab-header-with-background-foreground-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b8da3;
  --mdc-checkbox-selected-hover-icon-color: #3b8da3;
  --mdc-checkbox-selected-icon-color: #3b8da3;
  --mdc-checkbox-selected-pressed-icon-color: #3b8da3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-hover-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b8da3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b8da3;
  --mdc-checkbox-selected-hover-icon-color: #3b8da3;
  --mdc-checkbox-selected-icon-color: #3b8da3;
  --mdc-checkbox-selected-pressed-icon-color: #3b8da3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-hover-state-layer-color: #3b8da3;
  --mdc-checkbox-selected-pressed-state-layer-color: #3b8da3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3b8da3;
}
.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3b8da3;
}
.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.dark-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3b8da3;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3b8da3;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3b8da3;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3b8da3;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3b8da3;
}
.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3b8da3;
}
.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-button, .dark-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-button.mat-primary, .dark-theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3b8da3;
  --mat-mdc-button-ripple-color: rgba(59, 141, 163, 0.1);
}
.dark-theme .mat-mdc-button.mat-accent, .dark-theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3b8da3;
  --mat-mdc-button-ripple-color: rgba(59, 141, 163, 0.1);
}
.dark-theme .mat-mdc-button.mat-warn, .dark-theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-raised-button, .dark-theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-raised-button.mat-primary, .dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-accent, .dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-warn, .dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3b8da3;
  --mat-mdc-button-persistent-ripple-color: #3b8da3;
  --mat-mdc-button-ripple-color: rgba(59, 141, 163, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3b8da3;
  --mat-mdc-button-persistent-ripple-color: #3b8da3;
  --mat-mdc-button-ripple-color: rgba(59, 141, 163, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-fab,
.dark-theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.dark-theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.dark-theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.dark-theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.dark-theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme .mat-mdc-fab.mat-primary,
.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab.mat-accent,
.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab.mat-warn,
.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab[disabled][disabled],
.dark-theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-fab.mat-unthemed,
.dark-theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-fab.mat-primary,
.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3b8da3;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-fab.mat-accent,
.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #3b8da3;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-fab.mat-warn,
.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark-theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3b8da3;
}
.dark-theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #3b8da3;
}
.dark-theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.dark-theme {
  --mat-badge-background-color: #3b8da3;
  --mat-badge-text-color: rgba(255, 255, 255, 0.87);
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}

.dark-theme .mat-badge-accent {
  --mat-badge-background-color: #3b8da3;
  --mat-badge-text-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}
.dark-theme {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}

.dark-theme {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
}

.dark-theme {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(255, 255, 255, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #3b8da3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(59, 141, 163, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(255, 255, 255, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(59, 141, 163, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(59, 141, 163, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3b8da3;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(59, 141, 163, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}

.dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(255, 255, 255, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #3b8da3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(59, 141, 163, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(255, 255, 255, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(59, 141, 163, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(59, 141, 163, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(59, 141, 163, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #3b8da3;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.dark-theme {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.dark-theme {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}

.dark-theme {
  --mat-icon-color: inherit;
}

.dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #3b8da3;
}
.dark-theme .mat-icon.mat-accent {
  --mat-icon-color: #3b8da3;
}
.dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.dark-theme {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}

.dark-theme {
  --mat-stepper-header-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #3b8da3;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #3b8da3;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #3b8da3;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #3b8da3;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #3b8da3;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #3b8da3;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark-theme {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}

.dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3b8da3;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #3b8da3;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-tree {
  background: #424242;
}
.dark-theme .mat-tree-node,
.dark-theme .mat-nested-tree-node {
  color: white;
}
.dark-theme :root .default-theme {
  --mdc-checkbox-selected-checkmark-color: #FFFFFFDE;
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-checkbox-unselected-focus-icon-color: white;
  --mdc-checkbox-unselected-hover-icon-color: white;
  --mdc-checkbox-unselected-icon-color: white;
  --mdc-checkbox-unselected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;
  --flo-checkbox-size: 16px;
  --mdc-checkbox-state-layer-size: 32px;
  --mdc-chip-with-icon-icon-color: white;
  --mat-table-header-container-height: 56px;
  --mat-paginator-container-size: 68px;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: white;
  --mdc-icon-button-icon-color: white;
  --mat-menu-item-icon-color: white;
  --mat-expansion-header-text-color: white;
  --mat-expansion-container-text-color: white;
  --mdc-list-list-item-label-text-color: white;
  --mat-option-label-text-color: white;
  --mat-optgroup-label-text-color: white;
  --mdc-theme-text-primary-on-background: white;
  --mat-sidenav-content-text-color: white;
  --mat-menu-item-label-text-color: white;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mdc-filled-text-field-label-text-color: ;
  --mat-stepper-header-label-text-color: ;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-body-label-text-color: ;
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-text-field-input-text-color: white;
  --mat-select-enabled-trigger-text-color: white;
  --mat-form-field-container-text-weight: 400;
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.5);
  --mat-select-trigger-text-font: typography.$font-family-primary;
  --mat-select-focused-arrow-color: rgba(26, 26, 38, 0.6);
  --mat-select-invalid-arrow-color: rgba(26, 26, 38, 0.6);
  --mat-select-disabled-arrow-color: rgba(26, 26, 38, 0.16);
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-tracking: normal;
  --mat-paginator-select-trigger-text-size: 14px;
  --mdc-filled-text-field-label-text-font: typography.$font-family-primary;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-line-height: 24px;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-error-focus-outline-color: #F44336 !important;
  --mdc-outlined-text-field-error-label-text-color: rgba(26, 26, 38, 0.6) !important;
  --mdc-outlined-text-field-error-focus-label-text-color: #F44336 !important;
  --mdc-outlined-text-field-error-outline-color: rgba(26, 26, 38, 0.16) !important;
  --mdc-outlined-text-field-error-hover-outline-color: rgba(26, 26, 38, 0.38) !important;
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-color, #F69C00) !important;
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color, #F69C00) !important;
  --mat-option-selected-state-label-text-color: var(--primary-color, #F69C00) !important;
  --mdc-outlined-text-field-outline-color: rgba(26, 26, 38, 0.16) !important;
  --mdc-outlined-text-field-hover-outline-color: rgba(26, 26, 38, 0.38) !important;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-subscript-text-line-height: 18px;
  --mat-option-hover-state-layer-color: inherit;
  --mat-option-focus-state-layer-color: none;
  --mat-option-selected-state-layer-color: none;
}
.dark-theme :root .default-theme .mdc-checkbox__background {
  height: var(--flo-checkbox-size);
  width: var(--flo-checkbox-size);
}
.dark-theme :root .default-theme .mat-pseudo-checkbox {
  width: var(--flo-checkbox-size);
  height: var(--flo-checkbox-size);
}
.dark-theme :root .default-theme .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-radio-disabled-unselected-icon-color: rgba(26, 26, 38, 0.16);
}
.dark-theme :root .default-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: white;
}
.dark-theme :root .default-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: white;
}
.dark-theme :root .default-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(26, 26, 38, 0.06);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(26, 26, 38, 0.06);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme :root .default-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: rgba(35, 51, 81, 0.16);
}
.dark-theme :root .default-theme .mat-mdc-icon-button .mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: 8px;
}
.dark-theme :root .default-theme .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 40px;
  width: 40px;
}
.dark-theme :root .default-theme .mat-mdc-icon-button:disabled mat-icon {
  color: var(--mdc-icon-button-disabled-icon-color);
}
.dark-theme :root .default-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(26, 26, 38, 0.16);
  --mdc-icon-button-disabled-icon-color: rgba(26, 26, 38, 0.16);
}
.dark-theme :root .default-theme .mdc-card {
  padding: 16px;
}
.dark-theme :root .default-theme .mdc-card:not([class*=mat-elevation-z]) {
  border: solid 1px rgba(26, 26, 38, 0.12);
  border-radius: 8px;
  box-shadow: none;
}
.dark-theme :root .default-theme .mat-mdc-tab-link, .dark-theme :root .default-theme .mdc-tab__text-label {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 600;
}
.dark-theme :root .default-theme .flo-inner-tabs .mat-mdc-tab-link, .dark-theme :root .default-theme .flo-inner-tabs .mdc-tab__text-label {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 400;
}
.dark-theme :root .default-theme .mat-mdc-tab-group, .dark-theme :root .default-theme .mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-ripple-color: inherit;
  --mat-tab-header-active-ripple-color: inherit;
  --mat-tab-header-inactive-label-text-color: ;
}
.dark-theme :root .default-theme .mat-mdc-form-field-infix {
  min-height: 56px;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
.dark-theme :root .default-theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper {
  height: 48px;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 50%;
}
.dark-theme :root .default-theme .mat-mdc-text-field-wrapper .mat-mdc-select-arrow-wrapper {
  transform: translateY(-3px);
}
.dark-theme :root .default-theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 26px;
  padding-bottom: 6px;
}
.dark-theme :root .default-theme .mat-mdc-option-ripple {
  display: none !important;
}
.dark-theme :root .default-theme .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
.dark-theme :root .default-theme .mdc-notched-outline__leading, .dark-theme :root .default-theme .mdc-notched-outline__notch, .dark-theme :root .default-theme .mdc-notched-outline__trailing {
  border-width: 1px !important;
}
.dark-theme :root .default-theme ._form-field-font-config, .dark-theme :root .default-theme .mat-mdc-select,
.dark-theme :root .default-theme .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control, .dark-theme :root .default-theme .mdc-list-item__primary-text {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
.dark-theme :root .default-theme .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: var(--mat-option-hover-state-layer-color) !important;
}
.dark-theme :root .default-theme mat-hint {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 12px / 16px Roboto, sans-serif !important;
  letter-spacing: 0.4px !important;
}
.dark-theme :root .default-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #FFFFFF;
  --mdc-dialog-subhead-color: white;
  --mdc-dialog-supporting-text-color: white;
}
.dark-theme :root .default-theme .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 18px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}
.dark-theme :root .default-theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: inherit;
  --mdc-snackbar-supporting-text-color: inherit;
  --mat-snack-bar-button-color: inherit;
}
.dark-theme :root .default-theme .mat-mdc-slide-toggle {
  --mdc-switch-handle-surface-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-hover-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-pressed-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-pressed-handle-color: var(--primary-color, #F69C00);
  --mdc-switch-selected-focus-track-color: var(--primary-color-opacity-16);
  --mdc-switch-selected-hover-track-color: var(--primary-color-opacity-16);
  --mdc-switch-unselected-focus-handle-color: #FFFFFF;
  --mdc-switch-unselected-handle-color: #FFFFFF;
  --mdc-switch-unselected-hover-handle-color: #FFFFFF;
  --mdc-switch-disabled-unselected-handle-color: #FFFFFF;
  --mdc-switch-disabled-selected-handle-color: var(--primary-color-opacity-50);
  --mdc-switch-disabled-selected-track-color: var(--primary-color-opacity-50);
  --mdc-switch-unselected-pressed-handle-color: var(--primary-color-opacity-50);
  --mdc-switch-unselected-pressed-state-layer-color: var(--primary-color, #F69C00);
}
.dark-theme :root .default-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: var(--primary-color, #F69C00);
}
.dark-theme :root .default-theme .mdc-switch:disabled {
  --mdc-switch-handle-surface-color: #FFFFFF;
}
.dark-theme :root .default-theme .mdc-switch:disabled .mdc-switch__shadow {
  box-shadow: var(--mdc-switch-handle-elevation-shadow);
}
.dark-theme :root .default-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-color, #F69C00);
  --mdc-linear-progress-track-color: var(--primary-color-opacity-50);
}
.dark-theme :root .default-theme .mat-mdc-list-base {
  --mdc-list-list-item-hover-state-layer-color: unset;
  --mdc-list-list-item-hover-state-layer-opacity: unset;
  --mdc-list-list-item-focus-state-layer-color: unset;
  --mdc-list-list-item-focus-state-layer-opacity: unset;
}
.dark-theme .color-primary {
  color: #3b8da3 !important;
}
.dark-theme .disabled-form-field-text-color {
  color: var(--mdc-filled-text-field-disabled-label-text-color);
}
.dark-theme .color-secondary {
  color: #3b8da3 !important;
}
.dark-theme .color-warn {
  color: #f44336 !important;
}
.dark-theme .color-accent {
  color: #3b8da3 !important;
}
.dark-theme .color-white {
  color: #FFFFFF;
}
.dark-theme .fill-primary {
  fill: #3b8da3 !important;
}
.dark-theme .background-primary {
  background-color: #303030 !important;
}
.dark-theme .background-card {
  background-color: #424242 !important;
}
.dark-theme .app-bar-color {
  background-color: #212121 !important;
}
.dark-theme .background-secondary {
  background-color: #3b8da3 !important;
}
.dark-theme .background-warn {
  background-color: #f44336 !important;
}
.dark-theme .background-accent {
  background-color: #3b8da3 !important;
}
.dark-theme .flo-icon-color {
  color: white;
}
.dark-theme .flo-search-icon-color {
  color: rgba(35, 51, 81, 0.38) !important;
}
.dark-theme .mat-secondary-text-color {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-theme .mat-dark-secondary-text-color {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-dark-secondary-divider-color {
  border-top-color: rgba(35, 51, 81, 0.38);
}
.dark-theme .flo-border-color {
  border-color: !important;
}
.dark-theme .mat-divider-background {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-hint-text-color {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .default-header-colored-button-icon svg path {
  fill: currentColor;
  fill-opacity: 0.87;
}
.dark-theme .flo-vertical-divider {
  background-color: rgba(255, 255, 255, 0.12);
  height: 36px;
  width: 1px;
}
.dark-theme .flo-horizontal-divider {
  background-color: rgba(255, 255, 255, 0.12);
  height: 1px;
  width: 100%;
}
.dark-theme .middle-sized-icon {
  height: 17.5px !important;
  width: 17.5px !important;
}
.dark-theme .storytelling-icon {
  height: 205px !important;
  width: 370px !important;
  color: #3b8da3;
}
.dark-theme .main {
  display: block;
}
.dark-theme .main--with-margins {
  padding: 20px 40px 40px 40px;
}
.dark-theme html {
  height: 100vh;
}
.dark-theme body {
  height: 100%;
}
.dark-theme .wrapper-sidenav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 65px;
  padding-left: 10px;
  padding-right: 10px;
}
.dark-theme .flo-icon {
  display: inline-block;
  text-align: center;
}
.dark-theme .flo-icon--background--green {
  background-color: #3FAA19;
}
.dark-theme .flo-icon--background--red {
  background-color: #f44336;
}
.dark-theme .flo-icon--background--gray {
  background-color: rgba(26, 26, 38, 0.6);
}
.dark-theme .flo-icon--background--orange {
  background-color: #FF7A30;
}
.dark-theme .flo-icon--button {
  padding: 2.5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.dark-theme .flo-icon--big {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  padding-top: 5px;
}
.dark-theme .flo-icon--big .flo-icon__text {
  line-height: 35px;
  font-size: 18px;
}
.dark-theme .flo-icon--green svg path {
  background-color: #3FAA19;
  fill: #3FAA19;
  fill-opacity: 1;
}
.dark-theme .flo-icon--green svg path .flo-icon__text {
  fill: rgb(61, 213, 152);
  color: rgb(61, 213, 152);
}
.dark-theme .flo-icon--gray svg path {
  background-color: rgba(26, 26, 38, 0.6);
  fill: rgba(26, 26, 38, 0.6);
  fill-opacity: 1;
}
.dark-theme .flo-icon--gray svg path .flo-icon__text {
  fill: rgba(26, 26, 38, 0.6);
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .flo-icon--white {
  color: #FFFFFF;
  fill: currentColor;
  fill-opacity: 1;
}
.dark-theme .flo-icon--white .flo-icon__text {
  fill: #FFFFFF;
  color: #FFFFFF;
}
.dark-theme .flo-icon--red svg path {
  fill: #f44336;
  fill-opacity: 1;
}
.dark-theme .flo-icon--red svg path .flo-icon__text {
  fill: #f44336;
  color: #f44336;
}
.dark-theme .flo-icon--light-red {
  background-color: rgba(254, 77, 151, 0.1);
}
.dark-theme .flo-icon--light-red .flo-icon__text {
  fill: rgb(254, 77, 151);
}
.dark-theme .flo-icon--yellow svg path {
  fill: rgb(250, 197, 66);
}
.dark-theme .flo-icon--yellow svg path .flo-icon__text {
  fill: rgb(250, 197, 66);
  color: rgb(250, 197, 66);
}
.dark-theme .flo-icon--orange {
  background-color: rgba(255, 151, 74, 0.1);
}
.dark-theme .flo-icon--orange .flo-icon__text {
  fill: rgb(255, 151, 74);
}
.dark-theme .flo-icon--indigo {
  background-color: rgba(171, 125, 246, 0.1);
}
.dark-theme .flo-icon--indigo .flo-icon__text {
  fill: rgb(171, 125, 246);
}
.dark-theme .flo-icon--blue {
  background-color: rgba(77, 124, 254, 0.1);
}
.dark-theme .flo-icon--blue .flo-icon__text {
  fill: rgb(77, 124, 254);
}
.dark-theme .flo-icon--light-blue {
  background-color: rgba(80, 181, 255, 0.1);
}
.dark-theme .flo-icon--light-blue .flo-icon__text {
  fill: rgb(80, 181, 255);
}
.dark-theme .flo-icon--without-inner-text {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  font-size: 16px;
}
.dark-theme .flo-icon--without-inner-text.flo-icon--green {
  color: #3FAA19;
}
.dark-theme .flo-icon--without-inner-text.flo-icon--gray {
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .flo-icon--without-inner-text.flo-icon--red {
  color: #f44336;
}
.dark-theme .flo-icon--without-inner-text.flo-icon--yellow {
  color: rgb(250, 197, 66);
}
.dark-theme .search__field {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 250px;
  border: solid 1px rgba(255, 255, 255, 0.12);
  transition: border-color 0.1s ease-in;
  border-radius: 4px;
  background: #424242;
}
.dark-theme .search__field mat-icon {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .search__field:focus {
  border-color: var(--primary-color, #F69C00);
}
.dark-theme .search__field:hover {
  border-color: var(--primary-color, #F69C00);
}
.dark-theme .search__input {
  width: 250px;
  background-color: unset;
  border: none;
  outline: none;
  padding: 5px;
  color: inherit;
}
.dark-theme .search__input::placeholder {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .search__container .search__close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark-theme .search__container .search__close-icon svg {
  height: 16px;
  width: 16px;
  font-size: 16px;
  text-align: center;
}
.dark-theme .search__container .default-table-header__button_default.search__search-icon {
  margin: 8px 8px 6px 9px;
  height: 20px;
  width: 20px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dark-theme .fade-in {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}
.dark-theme .bd-table,
.dark-theme bd-lazy-table {
  display: block;
  min-width: 100%;
  padding: 0;
  border-radius: 8px;
  border: solid 1px rgba(26, 26, 38, 0.12);
  overflow: hidden;
}
.dark-theme .bd-table .table-header,
.dark-theme bd-lazy-table .table-header {
  height: 40px;
  margin-bottom: 10px;
}
.dark-theme .bd-table .table-header .title-wrapper,
.dark-theme bd-lazy-table .table-header .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.dark-theme .bd-table .table-header .title-wrapper .title-text,
.dark-theme bd-lazy-table .table-header .title-wrapper .title-text {
  color: white;
}
.dark-theme .bd-table .table-header .selected-from-text,
.dark-theme .bd-table .table-header .filtered-by-text,
.dark-theme bd-lazy-table .table-header .selected-from-text,
.dark-theme bd-lazy-table .table-header .filtered-by-text {
  color: rgba(255, 255, 255, 0.7);
  margin-left: 20px;
}
.dark-theme .bd-table .table-header .system-button,
.dark-theme bd-lazy-table .table-header .system-button {
  margin: 0 5px;
  min-width: 40px;
}
.dark-theme .bd-table .table-header .system-button:last-of-type,
.dark-theme bd-lazy-table .table-header .system-button:last-of-type {
  margin-right: 0;
}
.dark-theme .bd-table .table-header .system-button.mat-stroked-button,
.dark-theme bd-lazy-table .table-header .system-button.mat-stroked-button {
  background-color: #424242;
}
.dark-theme .bd-table .table-header .bulk-action-button,
.dark-theme bd-lazy-table .table-header .bulk-action-button {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .bd-table .table-header .filtered-by-text,
.dark-theme bd-lazy-table .table-header .filtered-by-text {
  margin-right: 10px;
}
.dark-theme .bd-table .table-header .text-search-container,
.dark-theme bd-lazy-table .table-header .text-search-container {
  margin-right: 16px;
}
.dark-theme .bd-table .table-header ::ng-deep .text-search-container .mat-form-field-wrapper,
.dark-theme bd-lazy-table .table-header ::ng-deep .text-search-container .mat-form-field-wrapper {
  position: relative;
  bottom: -6px;
}
.dark-theme .bd-table .table-wrapper,
.dark-theme bd-lazy-table .table-wrapper {
  overflow: auto;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table {
  overflow-x: scroll;
  min-width: 100%;
  border-collapse: separate;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-table-sticky,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-table-sticky {
  background-color: inherit;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2),
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2) {
  padding-left: 25px !important;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom: none;
  color: white;
  padding: 0 24px 0 0;
  white-space: nowrap;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell:first-of-type,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell:first-of-type {
  padding-left: 24px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell .mat-sort-header-button,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell .mat-sort-header-button {
  text-align: left;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell .sub-header,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell .sub-header {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell ::ng-deep .mat-sort-header-content,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.text-row-sub-header-cell ::ng-deep .mat-sort-header-content {
  flex-direction: column;
  align-items: flex-start;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.expand-arrow-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.expand-arrow-row-header-cell {
  z-index: 3 !important;
  box-shadow: 3px 0 3px 0 rgba(255, 255, 255, 0.12);
  border-right: solid 1px rgba(255, 255, 255, 0.12);
  min-width: 58px;
  width: 58px;
  padding: 0 5px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell {
  padding: 0 12px;
  width: 40px;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  background-color: #424242;
  text-align: center;
  z-index: 3 !important;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button {
  width: 40px;
  text-align: right;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button {
  margin-left: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 44px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button:hover,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button:hover {
  background-color: rgba(26, 26, 38, 0.08);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button .open-checkbox-menu-icon,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.bulk-checkbox-row-header-cell-with-button .bulk-checkbox-row-header-cell-menu-button .open-checkbox-menu-icon {
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell .data-row-header-cell-inner-flex-wrap,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell .data-row-header-cell-inner-flex-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.progress-bar-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.progress-bar-row-header-cell {
  min-width: 100px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.avatar-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.data-row-header-cell.avatar-row-header-cell {
  width: 40px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.actions-row-header-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.actions-row-header-cell {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  width: 0;
  background-color: #424242;
  padding: 0 !important;
  z-index: 3 !important;
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  text-align: right;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .sticky-column,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .sticky-column {
  z-index: 3 !important;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row {
  transition: box-shadow 0.1s ease-in;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2),
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2) {
  padding-left: 25px !important;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:nth-child(odd),
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:nth-child(odd) {
  background-color: #FAFAFA;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.clickable,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.clickable {
  cursor: pointer;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.selected,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.data-row.selected {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row.expanded-element-row,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row.expanded-element-row {
  height: 0;
  border: none;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.12), 0 -2px 6px 0 #23335105;
  transform: scale(1);
  border-top: none;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open {
  opacity: 1;
  color: white;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.bulk-checkbox-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.bulk-checkbox-row-cell {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.12), 0 -2px 6px 0 #23335105;
  z-index: 2;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.actions-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell.actions-row-cell {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.12), 0 -2px 6px 0 #23335105;
  z-index: 2;
  padding: 0 10px !important;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell {
  color: rgba(255, 255, 255, 0.87);
  padding: 0 24px 0 0;
  background: inherit;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell:first-of-type,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell:first-of-type {
  padding-left: 24px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell, .dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell {
  padding: 0 12px;
  text-align: center;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell:hover, .dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell:hover,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell:hover,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.bulk-checkbox-row-cell:hover {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.12), 0 -2px 6px 0 #23335105;
  z-index: 2;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
  width: 58px;
  min-width: 58px;
  padding: 0 5px;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-open {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-close,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expand-arrow-row-cell .expand.expand-close {
  transform: rotate(180deg) translateY(5px);
  color: white;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell {
  border-bottom: none;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail {
  overflow: hidden;
  display: flex;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail .expanded-element-row-cell-element-content,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.expanded-element-row-cell .expanded-element-row-cell-element-detail .expanded-element-row-cell-element-content {
  padding: 10px;
  width: 100%;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.actions-row-cell,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .mat-mdc-cell.actions-row-cell {
  padding: 0 10px !important;
  width: 0;
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  text-align: right;
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-right,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-right {
  box-shadow: 3px 0 3px 0 rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-left,
.dark-theme bd-lazy-table .table-wrapper .mat-mdc-table .top-layer-vertical-shadow-left {
  box-shadow: -3px 0 5px 0 rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .table-wrapper .expandable-table tr.mat-mdc-row,
.dark-theme bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row {
  background-color: #424242;
}
.dark-theme .bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(odd),
.dark-theme bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(odd) {
  background-color: #424242;
}
.dark-theme .bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+1),
.dark-theme bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+1) {
  background-color: #FAFAFA;
}
.dark-theme .bd-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+2),
.dark-theme bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:nth-child(4n+2) {
  background-color: #FAFAFA;
}
.dark-theme .bd-table .table-wrapper .expandable-table tr.mat-mdc-row:hover,
.dark-theme bd-lazy-table .table-wrapper .expandable-table tr.mat-mdc-row:hover {
  cursor: pointer;
}
.dark-theme .bd-table .sticky-column,
.dark-theme bd-lazy-table .sticky-column {
  box-shadow: 3px 0 3px 0 rgba(255, 255, 255, 0.12);
}
.dark-theme .bd-table .mat-mdc-paginator,
.dark-theme bd-lazy-table .mat-mdc-paginator {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dark-theme .bd-table .mat-mdc-paginator .mat-mdc-paginator-container,
.dark-theme bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  align-items: center;
}
.dark-theme .bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label,
.dark-theme .bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
.dark-theme .bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label,
.dark-theme bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label,
.dark-theme bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
.dark-theme bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
}
.dark-theme .bd-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field-infix,
.dark-theme bd-lazy-table .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: 36px !important;
}
.dark-theme .bd-table .mat-sort-header-content,
.dark-theme bd-lazy-table .mat-sort-header-content {
  text-align: start;
}
.dark-theme .bd-table .mat-card,
.dark-theme bd-lazy-table .mat-card {
  border-radius: 0;
}
.dark-theme .integrated-table-layout {
  border-left: none;
  border-right: none;
  border-radius: unset;
}
.dark-theme div[aria-labelledby*=mat-paginator-page-size-label- i] .mdc-list-item__primary-text {
  font-size: 14px !important;
}
.dark-theme .action-label {
  margin-left: 10px;
}
.dark-theme .filter-label {
  margin-left: 5px;
}
.dark-theme .manage-plans .table-header {
  margin-bottom: 2rem !important;
}
.dark-theme .manage-plans .mat-mdc-cell:first-child {
  width: 10px;
  color: rgba(26, 26, 38, 0.6) !important;
  font-size: 24px !important;
}
.dark-theme .manage-plans .mat-mdc-cell:last-child {
  color: rgba(26, 26, 38, 0.6) !important;
  text-align: end;
}
.dark-theme .manage-plans .blue-last-line .mat-mdc-row:last-child {
  background-color: #416f87 !important;
  cursor: pointer;
}
.dark-theme .manage-plans .clean-table tr, .dark-theme .manage-plans .clean-table td {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.dark-theme tr.disabled {
  background-color: #F1F1F1 !important;
}
.dark-theme tr.disabled .mat-cell {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .filters-sidebar {
  height: 100%;
}
.dark-theme .filters-sidebar .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  border-radius: 0;
}
.dark-theme .filters-sidebar .mat-dialog-container .filters-container {
  max-width: 370px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dark-theme .filters-sidebar .mat-dialog-container .filters-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  line-height: 36px;
  background: #FAFAFA;
}
.dark-theme .filters-sidebar .mat-card-header {
  display: block;
  margin: 0;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content {
  padding: 0;
  max-height: calc(5 * 35px);
  overflow-y: auto;
  position: relative;
  margin: 0;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list {
  border-radius: 5px;
  border: 1px solid;
  margin-bottom: 20px;
  max-height: 240px;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option {
  border-bottom: 1px solid;
  background-color: #FAFAFA;
  height: 40px !important;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option .mdc-list-item__content {
  padding: 0 !important;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option .mdc-list-item__content .mdc-list-item__primary-text {
  padding: 0 !important;
  color: rgba(26, 26, 38, 0.87) !important;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option:nth-child(2n) {
  background: #d8f9ff;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-mdc-selection-list .mat-mdc-list-option:last-child {
  border-bottom: none;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .filters__search .mat-form-field .mat-form-field-flex {
  width: 330px !important;
  flex-direction: row-reverse;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .filters__search .mat-form-field .mat-form-field-flex .mat-form-field-infix {
  width: auto;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-icon {
  width: 24px;
  height: 24px;
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .filter-title div,
.dark-theme .filters-sidebar .mat-mdc-dialog-content .filters__titles {
  font-weight: normal !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 2;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-form-field {
  width: 100%;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .mat-form-field input.mat-input-element {
  padding: 5px 10px;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content #searchableList .mdc-checkbox__background {
  background-color: #d8f9ff;
  border-radius: 3px;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content #searchableList mat-list-option[aria-selected=true] .mdc-checkbox__background {
  background-color: var(--mdc-checkbox-selected-icon-color);
  color: var(--primary-color, #F69C00);
  border-color: #3b8da3;
}
.dark-theme .filters-sidebar .mat-mdc-dialog-content .date-range-field {
  width: 100%;
}
.dark-theme .order-sims .wrapper {
  position: relative;
  border-radius: 4px;
  padding: 16px;
}
.dark-theme .order-sims .wrapper--bg-blue {
  background: rgba(26, 26, 38, 0.04);
}
.dark-theme .order-sims .wrapper--bg-light-gray {
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme .order-sims .wrapper--bg-light-gray h2 {
  color: #3FAA19;
}
.dark-theme .order-sims .wrapper--bg-light-gray mat-icon {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 53px;
  color: #3FAA19;
}
.dark-theme .order-sims .wrapper--bg-dark-gray {
  background: rgba(35, 51, 81, 0.6);
  color: #d8f9ff;
}
.dark-theme .order-sims .wrapper--bg-dark-gray mat-divider {
  border-top-color: #d8f9ff;
}
.dark-theme .order-sims .wrapper--bg-outline {
  border: 1px solid rgba(35, 51, 81, 0.16);
}
.dark-theme .order-sims .mat-card {
  box-shadow: none;
  border: 1px solid rgba(35, 51, 81, 0.16);
}
.dark-theme .order-sims .mat-card .mat-card-header {
  padding-left: 24px;
}
.dark-theme .order-sims .mat-card .mat-card-header .mat-card-title {
  margin-bottom: 0;
}
.dark-theme .order-sims .mat-card .mat-card-header .mat-card-title .title {
  font-weight: normal;
  font-size: 20px;
}
.dark-theme .order-sims .mat-card .mat-card-content .mat-icon {
  font-size: 20px;
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .order-sims .info-icon {
  font-size: 24px;
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .order-sims .limit-info {
  font-size: 12px;
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .sim-types {
  line-height: 24px;
}
.dark-theme .sim-types__title {
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme .sim-types__value {
  color: rgba(35, 51, 81, 0.87);
}
.dark-theme .mat-tooltip.tooltip-blue {
  background: #233351;
  max-width: 150px;
}
.dark-theme .billing-invoice .billing-invoice__header .mat-mdc-tab-link-container {
  padding: 0 15px 50px;
}
.dark-theme .billing-invoice .billing-invoice__header .mat-mdc-tab-link-container .mat-mdc-tab-link {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}
.dark-theme .eligible-billing-cycle-definitions .info-card-wrapper {
  margin-bottom: 0 !important;
}
.dark-theme .eligible-billing-cycle-definitions .button-update {
  position: relative;
  margin: -220px 15px 0 0;
  z-index: 2;
}
.dark-theme .eligible-billing-cycle-definitions .bd-lazy-table {
  padding: 0;
  box-shadow: none;
  border-top: none;
  border-radius: 0;
}
.dark-theme .eligible-billing-cycle-definitions .bd-lazy-table .default-table-header__row_head {
  padding: 0;
}
.dark-theme .billing-invoice-popover {
  padding: 22px !important;
  position: relative;
  font: 14px/21px Roboto, sans-serif;
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .billing-invoice-popover:before {
  display: block;
  content: "";
  position: absolute;
  right: 25px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
}
.dark-theme .billing-invoice-popover .warning-text {
  color: #F44336;
}
.dark-theme .sim-linked-offers-details-dialog .mat-card {
  position: relative;
  padding: 0;
  line-height: 24px;
}
.dark-theme .sim-linked-offers-details-dialog .mat-card-header {
  position: sticky;
  right: 0;
  top: 0;
  background: #424242;
  display: block;
  z-index: 100;
}
.dark-theme .sim-linked-offers-details-dialog .mat-card-header .mat-h5 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .sim-linked-offers-details-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}
.dark-theme .mat-card-border {
  box-shadow: none !important;
  border: 1px solid;
}
.dark-theme sim-linked-offers-select .mat-column-__dragAndDropColumn__ {
  border-right: none !important;
  padding: 0 10px !important;
}
.dark-theme .linked-offers-popover {
  color: rgba(255, 255, 255, 0.87);
  line-height: 18px;
  min-width: 225px;
  padding: 16px !important;
  position: relative;
}
.dark-theme .linked-offers-popover:before {
  display: block;
  content: "";
  position: absolute;
  left: 25px;
  bottom: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #424242 transparent transparent;
  border-width: 8px 8px 0;
}
.dark-theme .default-table-header-v1 {
  min-height: 40px;
  margin-bottom: 10px;
}
.dark-theme .default-table-header-v1 .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.dark-theme .default-table-header-v1 .selected-from-text,
.dark-theme .default-table-header-v1 .filtered-by-text {
  margin-left: 20px;
  font-weight: 500;
}
.dark-theme .default-table-header-v1 .system-button__icon {
  font-size: 24px;
  color: white;
}
.dark-theme .default-table-header-v1 .system-button .header-button-icon {
  color: inherit;
}
.dark-theme .default-table-header-v1 .system-button.mat-stroked-button {
  background-color: #424242;
}
.dark-theme .default-table-header-v1 .filtered-by-text {
  margin-right: 10px;
}
.dark-theme .default-table-header-v1 mat-chip-list {
  display: inline-block;
}
.dark-theme .default-table-header-v1 mat-chip-list .mat-chip-remove {
  line-height: initial;
}
.dark-theme .default-table-header-v1 .text-search-container {
  margin-right: 16px;
}
.dark-theme .default-table-header-v1 .action-label {
  margin-left: 10px;
}
.dark-theme .default-table-header-v1 .filter-label {
  margin-left: 5px;
}
.dark-theme .default-table-header__actions, .dark-theme .default-table-header__amount {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 1;
  gap: 10px;
}
.dark-theme .default-table-header__row {
  height: 60px;
  background-color: #FFFFFF;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.dark-theme .default-table-header__row_head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  max-height: 4.5em;
  padding: 1.5em 0;
}
.dark-theme .default-table-header__row_head .header-action-btn-group {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}
.dark-theme .default-table-header__row_actions, .dark-theme .default-table-header__row_filters {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .default-table-header__row_filters {
  background-color: #416f87;
  height: 48px;
}
.dark-theme .default-table-header__row_filters-parameters {
  width: fit-content;
}
.dark-theme .default-table-header__row_filters-parameter {
  margin: 5px 0;
}
.dark-theme .default-table-header__row_filters-parameter .filtered-by-text {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .default-table-header__row_filters-parameter .default-table-header__separator {
  font-size: 20px;
  color: rgba(35, 51, 81, 0.16);
}
.dark-theme .default-table-header__row_filters .filter-counter-group {
  display: flex;
  flex-wrap: nowrap;
}
.dark-theme .default-table-header__row_additional_title {
  display: flex;
  align-items: center;
  background-color: #d8f9ff;
  height: 60px;
  font-size: 24px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .default-table-header__separator {
  padding: 0 12px;
  font-size: 20px;
  font-weight: 100;
  color: white;
}
.dark-theme .default-table-header__button_default {
  font-size: 18px;
}
.dark-theme .default-table-header__button_search {
  font-size: 18px;
  width: 18px;
}
.dark-theme .default-table-header .title-wrapper {
  height: 100%;
  flex-grow: 2;
}
.dark-theme .default-table-header .title-wrapper .title-text {
  color: white;
}
.dark-theme .default-table-header .selected-from-text {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .default-table-header .filtered-by {
  font-weight: 500;
}
.dark-theme .default-table-header .filtered-by-text {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-right: 10px;
}
.dark-theme .default-table-header .system-button__icon {
  font-size: 24px;
  color: white;
}
.dark-theme .default-table-header .system-button .header-button-icon {
  color: inherit;
}
.dark-theme .default-table-header .system-button.mat-stroked-button {
  background-color: #d8f9ff;
}
.dark-theme .default-table-header .bulk-action-button {
  font-size: 20px;
  color: white;
}
.dark-theme .default-table-header .bulk-action-button__icon {
  font-size: 24px;
}
.dark-theme .default-table-header .header-padding-20 {
  padding: 0 20px;
}
.dark-theme .default-table-header .header-padding-24 {
  padding: 0 24px;
}
.dark-theme .default-table-header-v2 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #424242;
}
.dark-theme .filters-list-wrapper {
  overflow: hidden;
  width: 100% !important;
}
.dark-theme .mat-chips-row .mdc-evolution-chip-set__chips {
  flex-wrap: nowrap !important;
}
.dark-theme .more-button {
  align-self: center;
}
.dark-theme .more-button:hover {
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.dark-theme .clear-all-button {
  flex-shrink: 0;
}
.dark-theme .filter-chips-panel-popover {
  width: fit-content;
  block-size: fit-content;
}
.dark-theme .filter-chips-panel-popover, .dark-theme .filter-chips-panel {
  padding: 0 24px;
}
.dark-theme .filter-chips-panel-popover mat-chip-list .mat-chip-remove, .dark-theme .filter-chips-panel mat-chip-list .mat-chip-remove {
  line-height: initial;
}
.dark-theme .filter-chips-panel-popover .filtered-by-text, .dark-theme .filter-chips-panel .filtered-by-text {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 10px;
}
.dark-theme .filter-chips-panel-popover {
  height: fit-content;
}
.dark-theme .collapsed-group-wrapper:last-child {
  margin-bottom: 10px;
}
.dark-theme .action-label {
  margin-left: 10px;
}
.dark-theme .filter-label {
  margin-left: 5px;
}
.dark-theme mat-toolbar.main-toolbar {
  padding: 0;
  background-color: #424242 !important;
  box-shadow: rgba(26, 26, 38, 0.08) 0 3px 4px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row {
  padding: 0 16px;
  min-height: 65px;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal {
  padding: 0;
  width: 100%;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item {
  display: inline-block;
  height: auto;
  width: auto;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:first-child.mat-list-item-content {
  padding: 0 5px 0 0;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:last-child.mat-list-item-content {
  padding: 0 0 0 5px;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:last-child a {
  color: white;
  text-decoration: none;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal .mat-list-item:not(:first-child):not(:last-child).mat-list-item-content {
  padding: 0 5px 0 5px;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal a {
  padding: 0;
  color: white;
  font-size: 14px;
  text-decoration: underline;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row mat-nav-list.list-horizontal a:hover {
  text-decoration: underline;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row .arrow {
  color: white;
  font-size: 14px;
}
.dark-theme mat-toolbar.main-toolbar mat-toolbar-row header {
  box-shadow: none !important;
}
.dark-theme .mat-mdc-card-header {
  width: 100%;
}
.dark-theme .mat-mdc-card-header .mat-mdc-card-header-text {
  width: 100%;
}
.dark-theme .mat-mdc-card-header span.mat-card-header-avatar {
  color: #3b8da3;
  font-size: 1.5rem;
  line-height: 30px;
  margin-left: 16px;
}
.dark-theme sim-bd-icon-flat-button .mat-flat-button {
  line-height: 21px !important;
  padding: 0 8px !important;
}
.dark-theme sim-bd-dialog .mat-dialog-actions {
  color: #3b8da3;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #424242;
  margin: 0 -24px -24px -24px;
  padding: 8px 24px 8px 96px !important;
}
.dark-theme sim-bd-dialog .mat-dialog-title {
  background-color: #3b8da3;
  color: #3b8da3;
  margin: -24px -24px 5px -24px !important;
  padding: 12px 24px 12px 24px;
}
.dark-theme sim-bd-dialog .mat-dialog-title .mat-card-header-text {
  margin: 0;
}
.dark-theme sim-bd-dialog .mat-dialog-title .mat-card-header-text .mat-card-title {
  margin-bottom: 0 !important;
}
.dark-theme .mat-sidenav-container {
  height: 100%;
}
.dark-theme .mat-sidenav-container mat-sidenav {
  box-shadow: rgba(26, 26, 38, 0.08) 5px 65px 30px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav {
  padding-bottom: 60px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav mat-nav-list {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav mat-nav-list .toggle-menu-btn-wrapper {
  margin-left: 5px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav mat-nav-list .mdc-list-item {
  height: 40px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav mat-nav-list .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__logo {
  padding: 10px 24px;
  text-align: left;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(255, 255, 255, 0.12);
  height: 65px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__logo img {
  height: 45px;
  width: auto;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__category-label {
  height: 40px;
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.7) !important;
  letter-spacing: 1px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item {
  position: relative;
  box-sizing: border-box;
  box-shadow: none !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item .nav-link-wrapper, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item .nav-link-wrapper {
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item .mat-expansion-panel-body, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item .mat-expansion-panel-body {
  padding: 0;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item--without-children, .dark-theme .mat-sidenav-container mat-sidenav .sidenav__sub-item--without-children {
  display: block;
  height: 40px;
  margin-bottom: 5px;
  text-decoration: none;
  border-left-width: 0;
  border-left-style: solid;
  border-left-color: #3b8da3;
  width: 200px;
  margin-left: 27px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-icon {
  height: 24px;
  width: 24px;
  color: white;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav__item-label {
  margin-left: 13px;
  color: white;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .active {
  background-color: rgba(59, 141, 163, 0.08);
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .active .path-active {
  stroke: #3b8da3;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .active .path-active-fill {
  fill: #3b8da3;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .active :hover {
  background-color: rgba(59, 141, 163, 0.08) !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .dot-expansion-panel {
  background-color: #3b8da3;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 5px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected {
  background-color: transparent !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected .span-with-children {
  color: #3b8da3;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected .i-with-children {
  color: #3b8da3 !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected .path-active {
  stroke: #3b8da3 !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected .path-active-fill {
  fill: #3b8da3 !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .selected .closed-sidebar-description-panel {
  background-color: #416f87 !important;
  margin-right: 0;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .submenu-icon svg {
  position: absolute;
  height: 53px;
  width: 53px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-description-panel {
  height: 40px;
  padding: 0 16px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-expansion-header {
  display: initial;
  flex-direction: initial;
  align-items: initial;
  padding: initial !important;
  margin-left: 0 !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-expansion-header .mat-expansion-indicator {
  display: none;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .mat-expansion-panel-header {
  padding: 0 16px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .closed-sidebar-without-children mat-icon svg {
  width: 24px;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .nav-link-wrapper:hover:not(.active),
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .mat-mdc-list-item:hover:not(.active),
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav .mat-mdc-list-item.active:hover {
  background-color: var(--primary-color, #F69C00) !important;
  border-radius: 6px !important;
}
.dark-theme .mat-sidenav-container mat-sidenav .sidenav.impersonated {
  margin-top: 41px;
}
.dark-theme .search-string-bold-color {
  color: #3b8da3;
}
.dark-theme .main-toolbar .drawer-button {
  margin: 0 20px;
}
.dark-theme .main-toolbar .flo-toolbar-logo {
  height: 65%;
  align-self: center;
}
.dark-theme .main-toolbar .search__field {
  width: 370px;
  justify-content: start;
}
.dark-theme .main-toolbar .search__field mat-icon {
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .main-toolbar .search__field .toolbar-search-close-icon {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .main-toolbar .search__input {
  width: 330px;
}
.dark-theme .main-toolbar .user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}
.dark-theme .main-toolbar .user-menu .user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.dark-theme .main-toolbar .user-menu .user-info__avatar {
  vertical-align: middle;
  margin: 0 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.dark-theme .main-toolbar .user-menu .user-info__initials {
  margin: 0 5px;
}
.dark-theme .main-toolbar .user-menu .user-info__name .user-name {
  margin: 0;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .main-toolbar .user-menu .user-info__name .user-company {
  margin: 0;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .main-toolbar .user-menu .user-info__arrow {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .main-toolbar .toolbar-menu-item {
  width: 193px;
  color: rgba(255, 255, 255, 0.7);
}
@media only screen and (max-width: 600px) {
  .dark-theme .main-toolbar mat-toolbar-row {
    padding: 0 !important;
  }
  .dark-theme .main-toolbar .sims-search__input {
    width: 100% !important;
  }
  .dark-theme .main-toolbar .user-menu {
    padding: 0 20px;
    margin: 0 20px !important;
  }
}
.dark-theme .main-toolbar .search__container {
  margin-right: 10px;
}
.dark-theme .main-toolbar .search__container .table-header__button_default {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark-theme .toolbar-search-optgroup {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: block;
}
.dark-theme .toolbar-search-optgroup:first-child {
  border-top: none;
}
.dark-theme .toolbar-search-optgroup .mat-optgroup-label {
  font-weight: 400;
  color: white;
}
.dark-theme .toolbar-search-optgroup .mat-option {
  font-size: 16px;
}
.dark-theme .user-menu-list .mat-mdc-menu-item .mat-icon {
  width: 19px;
  margin-right: 10px;
}
.dark-theme .user-info__initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color, #F69C00);
  color: #FFFFFF;
  font-size: 16px;
}
.dark-theme .uuid-popover:before {
  display: block;
  content: "";
  position: absolute;
  right: 12px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
}
.dark-theme .plans-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dark-theme .plans-list .header-wrapper {
  box-shadow: 0 2px 1px -1px rgba(255, 255, 255, 0.12), 0 1px 1px 0px rgba(255, 255, 255, 0.12), 0 1px 3px 0px rgba(255, 255, 255, 0.12);
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-bottom: -10px;
  background: #d8f9ff;
  padding: 20px 20px 12px;
}
.dark-theme .plans-list__content {
  margin-top: 16px;
  padding: 3px;
  flex-grow: 1;
  overflow-y: hidden;
}
.dark-theme .sim-list bd-nav-breadcrumbs {
  display: block;
  margin-bottom: 0.5em;
}
.dark-theme .sim-list .iccid {
  display: flex;
  flex-direction: column;
}
.dark-theme .sim-list .iccid span {
  line-height: 1.2;
}
.dark-theme .sim-list .iccid span.alias {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .sim-list sim-flo-truncate-tooltip span,
.dark-theme .sim-list .mat-column-country bd-icon-column-cell {
  white-space: nowrap;
}
.dark-theme .sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-cell.bulk-checkbox-row-header-cell,
.dark-theme .sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-cell.bulk-checkbox-row-cell {
  border-right: none !important;
}
.dark-theme .sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-header-row .additional-cell-padding:nth-child(2),
.dark-theme .sim-list bd-lazy-table .table-wrapper .mat-mdc-table .mat-mdc-row .additional-cell-padding:nth-child(2) {
  padding-left: 0 !important;
}
.dark-theme .sim-list bd-lazy-table .table-wrapper .mat-mdc-table .starting-column {
  padding-left: 24px !important;
}
.dark-theme bd-header.sim-details-header {
  margin-bottom: 20px;
  display: block;
}
.dark-theme bd-header.sim-details-header nav.mat-tab-nav-bar {
  border-bottom-width: 0;
}
.dark-theme .sim-details-header__info {
  padding: 0 10px;
}
.dark-theme .sim-details-header__icon {
  color: white;
}
.dark-theme .sim-details-header .info-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .sim-details-header .info-field-value {
  color: rgba(255, 255, 255, 0.87);
  font-weight: 500;
}
.dark-theme .sim-details-header .flo-status {
  font-size: 14px;
  padding: 5px 10px;
}
.dark-theme .sim-details-header .alias-name-field {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 32px / 36px Open Sans, sans-serif !important;
  letter-spacing: 1px !important;
}
.dark-theme .sim-details-header .expandable-msisdn-imsi-list {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.25s;
}
.dark-theme .sim-details-header .expandable-msisdn-imsi-list:hover {
  background-color: #212121;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .sim-details-header__separator {
  padding: 0 8px;
  font-size: 20px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.12);
}
.dark-theme .sim-details-header .height64px {
  height: 64px;
}
.dark-theme .sim-details-header .action-label {
  margin-left: 10px;
}
.dark-theme .sim-details-header .sim-alias {
  margin-right: 10px;
  font-size: 32px;
  font-family: Open Sans, sans-serif;
}
.dark-theme .sim-details-msisdn-imsi-list.mat-mdc-menu-panel {
  max-width: none;
}
.dark-theme .sim-details-msisdn-imsi-list-item {
  height: 40px;
  padding: 8px 0;
  width: 390px;
}
.dark-theme .sim-details-msisdn-imsi-list-item .sim-details-header__separator {
  padding: 0 8px;
}
.dark-theme .sim-details-msisdn-imsi-list-item-text-imsi {
  padding-left: 20px;
}
.dark-theme .sim-details-msisdn-imsi-list-item-text-msisdn {
  padding-right: 20px;
}
.dark-theme .sim-details-msisdn-imsi-list-item .info-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .sim-details-msisdn-imsi-list-item .info-field-value {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .sim-imeisv {
  margin-top: 0.5em;
  margin-left: -12em;
  padding: 0.5em 1em;
}
.dark-theme .sim-profiles .mat-mdc-card-title {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 24px / 24px Open Sans, sans-serif !important;
  letter-spacing: 0.4px !important;
}
.dark-theme .sim-profiles .mat-elevation-z1 {
  box-shadow: none;
}
.dark-theme .sim-profiles .mat-mdc-tab-group {
  display: flex;
  flex-direction: row;
  border: none;
}
.dark-theme .sim-profiles .mat-mdc-tab-header {
  width: 285px;
  margin-right: 15px;
}
.dark-theme .sim-profiles .mat-mdc-tab-list {
  max-width: 100%;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels {
  display: flex;
  flex-direction: column;
  max-height: 420px;
  overflow: auto;
  border: 1px solid rgba(26, 26, 38, 0.16);
  border-radius: 6px;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab {
  justify-content: flex-start;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
  border-bottom: 1px solid rgba(26, 26, 38, 0.16);
  padding: 0 10px;
  max-width: 100%;
  width: 100%;
  min-width: auto;
  text-align: left;
  position: relative;
  color: rgba(35, 51, 81, 0.87);
  opacity: 1;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .label-color {
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab-indicator__content--underline {
  border: none;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .mat-icon {
  position: absolute;
  right: -11px;
  top: 50%;
  margin-top: -11px;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .active-tab {
  color: #3FAA19;
  font-size: 12px;
  padding: 0 5px;
  background: rgba(63, 170, 25, 0.06);
  margin-left: 5px;
  line-height: 20px;
  max-height: 20px;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab__content {
  width: 100%;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab .mdc-tab__text-label {
  display: block;
  width: 100%;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active {
  background: #416f87;
  color: initial;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: rgba(35, 51, 81, 0.87);
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab.mdc-tab--active mat-icon {
  color: #3b8da3;
}
.dark-theme .sim-profiles .mat-mdc-tab-labels .mdc-tab:last-child {
  border-bottom: none;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body {
  flex-grow: 1;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table {
  border: 1px solid rgba(26, 26, 38, 0.16);
  border-radius: 6px;
  box-shadow: none;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-header-row, .dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-row, .dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-footer-row {
  height: 42px;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-header-row th.mat-mdc-header-cell {
  border-bottom: 1px solid rgba(26, 26, 38, 0.16) !important;
}
.dark-theme .sim-profiles .mat-mdc-tab-body-wrapper .mat-mdc-tab-body bd-table tr.mat-mdc-row:last-child td.mat-cell {
  border: none;
}
.dark-theme .sim-profiles .mat-mdc-ink-bar {
  display: none;
}
.dark-theme .drag-and-drop-menu-container #uploader__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.dark-theme .drag-and-drop-menu-container .uploader {
  background-color: white;
  border: 1px dashed rgba(26, 26, 38, 0.16);
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 160px;
  color: rgba(26, 26, 38, 0.38);
}
.dark-theme .drag-and-drop-menu-container .uploader .gap {
  gap: 4px;
}
.dark-theme .drag-and-drop-menu-container .uploader__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1em;
}
.dark-theme .drag-and-drop-menu-container .uploader__label .uploader__icon {
  margin-bottom: 10px;
  width: 45px;
  height: 30px;
}
.dark-theme .drag-and-drop-menu-container .uploader__label .uploader__icon svg path {
  fill: rgba(26, 26, 38, 0.16);
}
.dark-theme .drag-and-drop-menu-container .uploader__label .uploader__title {
  margin-bottom: 0;
  white-space: nowrap;
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .drag-and-drop-menu-container .uploader__label .uploader__subtitle {
  margin-bottom: 0;
  color: rgba(26, 26, 38, 0.38);
  font-weight: normal;
}
.dark-theme .drag-and-drop-menu-container .uploader__label .description-divider {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(26, 26, 38, 0.08);
  color: rgba(26, 26, 38, 0.6);
  display: inline-block;
  text-align: center;
}
.dark-theme .drag-and-drop-menu-container .uploader--active {
  background-color: #416F87;
}
.dark-theme .drag-and-drop-menu-container .uploader--active .uploader__icon {
  opacity: 0.8;
}
.dark-theme .drag-and-drop-menu-container .uploader--reject {
  background-color: #ffebee;
}
.dark-theme .drag-and-drop-menu-container .button {
  padding: 10px 0 10px 10px;
  text-align: end;
}
.dark-theme .drag-and-drop-menu-container .icon {
  text-align: center;
  width: 35px;
  height: 35px;
  font-size: 30px;
}
.dark-theme .drag-and-drop-menu-container .success {
  color: #3FAA19;
}
.dark-theme .drag-and-drop-menu-container .reject {
  color: #f44336;
}
.dark-theme .flo-dialog__content.p-0 {
  padding: 0 !important;
}
.dark-theme .flo-dialog__content.p-0 .uploading-state {
  margin: 32px 0 14px !important;
}
.dark-theme .dialog-content__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
}
.dark-theme .dialog-content__title mat-icon {
  height: 32px;
  width: 32px;
}
.dark-theme .dialog-content__title button {
  margin-top: 15px;
}
.dark-theme .dialog-content__title button span {
  margin-top: unset;
}
.dark-theme .dialog-content__title span {
  margin-outside: 20px;
  margin-top: 8px;
  color: rgba(26, 26, 38, 0.87);
  text-align: center;
}
.dark-theme .dialog-content__title span.success-text {
  color: #3FAA19;
  font-size: 13px;
}
.dark-theme .dialog-content__title span.error-text {
  color: #F44336;
  font-size: 13px;
}
.dark-theme .flo-dialog h3.mat-mdc-dialog-title {
  margin-bottom: 8px;
}
.dark-theme .flo-dialog .dialog-warning-icon {
  color: #f44336;
}
.dark-theme .flo-dialog__dialog-warning-line {
  border-left: 12px solid #f44336;
}
.dark-theme .flo-dialog__dialog-warning-line .mdc-dialog__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.dark-theme .flo-dialog__header {
  margin-top: -34px;
  margin-bottom: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dark-theme .flo-dialog__header-warn {
  color: #f44336;
}
.dark-theme .flo-dialog__divider {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.dark-theme .flo-dialog__content {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 20px !important;
  position: relative;
  line-height: 24px !important;
}
.dark-theme .flo-dialog__actions {
  padding: 16px 8px !important;
}
.dark-theme .flo-dialog__section-label:first-child {
  margin: 0 0 24px 0 !important;
}
.dark-theme .flo-dialog__section-label:not(:first-child) {
  margin: 24px 0 !important;
}
.dark-theme .divider-tune {
  height: 24px;
  margin: 0 8px 0 4px;
}
.dark-theme .service-providers .mat-tab-link {
  padding: 0 10px;
  min-width: 410px;
  opacity: 1;
}
.dark-theme .service-providers .no-data {
  max-height: 240px !important;
  overflow: hidden;
}
.dark-theme .system-labels .mat-chip.mat-standard-chip {
  color: rgba(255, 255, 255, 0.5);
  background-color: #424242;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  max-width: 195px;
}
.dark-theme .sidebar-filters-dialog {
  height: calc(100vh - 64px);
}
.dark-theme .sidebar-filters-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}
.dark-theme .sidebar-filters-dialog .mat-dialog-container .mat-dialog-content {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(calc(100vh - 64px) - 64px - 64px);
  height: calc(calc(100vh - 64px) - 64px - 64px);
}
.dark-theme .sidebar-filters-dialog .filters-container {
  max-width: 340px;
  width: 100%;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .sidebar-filters-dialog .filters-container .mat-card-actions {
  margin: 0;
  height: 64px;
  padding: 10px;
}
.dark-theme .sidebar-filters-dialog .filters-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  padding: 14px 20px;
}
.dark-theme .sidebar-filters-dialog .mat-card-header {
  display: block;
  margin: 0;
}
.dark-theme .sidebar-filters-dialog .mat-flat-button {
  padding: 0 30px;
}
.dark-theme .sidebar-filters-dialog .date-range-field {
  width: 100%;
}
.dark-theme .batch-operation-list-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  padding: 3px;
}
.dark-theme .batch-operation-list-details lib-custom-header {
  margin-bottom: 0.5em;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container {
  display: flex;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button:not(:last-of-type) {
  margin-right: 8px;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button.btn-delete {
  color: #9ddeea;
  border-color: #3b8da3;
  display: flex;
  align-items: center;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button.btn-refresh {
  border-color: #409db4;
  display: flex;
  align-items: center;
}
.dark-theme .batch-operation-list-details lib-custom-header lib-header-end .actions-container > .mat-raised-button span {
  margin-left: 6px;
}
.dark-theme .batch-operation-list-details .header-additional-row {
  display: flex;
  align-items: center;
  height: 88px;
  width: 100%;
  padding: 0 1em;
  gap: 0.5em;
  margin-top: 0.5em;
}
.dark-theme .batch-operation-list-details .header-additional-row .header-additional-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  margin-right: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  width: 380px;
}
.dark-theme .batch-operation-list-details .header-additional-row .header-additional-col .label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .batch-operation-list-details bd-lazy-table {
  flex-grow: 1;
}
.dark-theme .batch-operation-list-details bd-lazy-table .status-column-container {
  display: flex;
  justify-content: flex-end;
}
.dark-theme .batch-operation-list-details bd-lazy-table .status-column-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.dark-theme .labels-wrapper {
  padding: 6px 8px;
  max-width: 200px;
  background: #424242;
  border: solid 1px rgba(255, 255, 255, 0.12) !important;
  height: 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
}
.dark-theme .labels-wrapper .dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  background-color: #3b8da3;
  border-radius: 50%;
  display: inline-block;
}
.dark-theme .labels-wrapper .labels-text, .dark-theme .labels-wrapper sim-flo-truncate-tooltip {
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme :host-context(.white-labels) .labels-wrapper,
.dark-theme .white-labels .labels-wrapper {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 22px;
}
.dark-theme :host-context(.white-labels) .labels-wrapper .labels-text,
.dark-theme .white-labels .labels-wrapper .labels-text {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .chip-wrapper {
  padding: 3px 8px;
  background: #FFFFFF;
  border: solid 1px rgba(35, 51, 81, 0.16) !important;
  height: 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
.dark-theme .chip-wrapper .chip-content {
  border-left: 1px solid rgba(35, 51, 81, 0.16);
  padding-left: 6px;
  margin-left: 6px;
}
.dark-theme .chip-wrapper .chip-content:first-child {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}
.dark-theme .chip-wrapper .chip-title, .dark-theme .chip-wrapper .chip-text, .dark-theme .chip-wrapper sim-flo-truncate-tooltip {
  font-size: 12px;
  line-height: 18px;
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme .chip-wrapper .chip-title {
  padding-right: 5px;
}
.dark-theme .chip-wrapper .flo-icon-color {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .flo-chip-v2 .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip-set__chips, .dark-theme .filter-chips-panel .mdc-evolution-chip-set__chips {
  align-items: center;
}
.dark-theme .flo-chip-v2 .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel-popover .mdc-evolution-chip__text-label, .dark-theme .filter-chips-panel .mdc-evolution-chip__text-label {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
  --mdc-chip-label-text-color: rgba(255, 255, 255, 0.7);
  --mdc-chip-container-height: 26px;
  min-height: 26px;
}
.dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip::after, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip::after, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip::after {
  background: transparent;
}
.dark-theme .flo-chip-v2 .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove, .dark-theme .filter-chips-panel-popover .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove, .dark-theme .filter-chips-panel .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.dark-theme .flo-chip-v2 .add-new-chip-button, .dark-theme .filter-chips-panel-popover .add-new-chip-button, .dark-theme .filter-chips-panel .add-new-chip-button, .dark-theme .filter-chips-panel-popover .add-new-chip-button, .dark-theme .filter-chips-panel .add-new-chip-button {
  border: none;
  background-color: #3b8da3;
  height: 22px;
  width: 22px;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
}
.dark-theme .flo-chip-v2 .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel-popover .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel-popover .add-new-chip-button mat-icon, .dark-theme .filter-chips-panel .add-new-chip-button mat-icon {
  height: 22px;
  width: 22px;
  color: #d8f9ff;
}
.dark-theme .invoices-list {
  padding: 0 !important;
  overflow: hidden;
}
.dark-theme .invoices-list bd-lazy-table {
  box-shadow: none;
  border: none;
}
.dark-theme .invoices-list bd-lazy-table .default-table-header {
  border-top: 1px solid rgba(26, 26, 38, 0.16);
}
.dark-theme .invoices-list bd-lazy-table .mat-header-cell {
  border-bottom: 1px solid rgba(35, 51, 81, 0.38) !important;
}
.dark-theme .invoices-list bd-lazy-table .hidden-table,
.dark-theme .invoices-list bd-lazy-table .default-table-header__row_head {
  display: none !important;
}
.dark-theme .invoices-list .download-column-container a {
  cursor: pointer;
  color: rgba(35, 51, 81, 0.6);
}
.dark-theme .invoices-list .download-column-container > mat-icon {
  color: rgba(35, 51, 81, 0.24);
}
.dark-theme .lib-bss-popover {
  padding: 22px !important;
  position: relative;
  font: 14px/21px Roboto, sans-serif;
  color: rgba(26, 26, 38, 0.6);
}
.dark-theme .lib-bss-popover:before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}
.dark-theme .lib-bss-popover.arrow-left-top:before {
  border-color: transparent transparent #FFFFFF;
  border-width: 0 8px 8px;
  left: 18px;
  top: -8px;
}
.dark-theme .lib-bss-popover.arrow-left-top.gap-small:before {
  left: 6px;
}
.dark-theme .lib-bss-popover .title-text {
  color: rgba(26, 26, 38, 0.87);
}
.dark-theme .lib-bss-popover .warning-text {
  color: #F44336;
}
.dark-theme input[type=number]::-webkit-inner-spin-button,
.dark-theme input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dark-theme .flo-slim-select-panel,
.dark-theme .flo-date-range-selector {
  display: flex;
  flex-direction: column;
}
.dark-theme .flo-slim-select-panel div.mat-mdc-select-panel,
.dark-theme .flo-date-range-selector div.mat-mdc-select-panel {
  padding-top: 0 !important;
}
.dark-theme .flo-slim-select-panel .mat-mdc-select-panel,
.dark-theme .flo-date-range-selector .mat-mdc-select-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  border-radius: 4px;
  outline: 0;
}
.dark-theme .flo-slim-select-panel .mat-mdc-form-field-hint,
.dark-theme .flo-date-range-selector .mat-mdc-form-field-hint {
  margin-bottom: 4px;
}
.dark-theme .flo-slim-select-panel .mat-mdc-text-field-wrapper,
.dark-theme .flo-date-range-selector .mat-mdc-text-field-wrapper {
  height: 36px !important;
  padding-bottom: 0;
}
.dark-theme .flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-floating-label,
.dark-theme .flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 50% !important;
}
.dark-theme .flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.dark-theme .flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  font-size: 14px !important;
}
.dark-theme .flo-slim-select-panel .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix,
.dark-theme .flo-date-range-selector .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  padding-bottom: 16px !important;
}
.dark-theme .flo-slim-select-panel .mat-mdc-text-field-wrapper .mdc-floating-label--float-above,
.dark-theme .flo-date-range-selector .mat-mdc-text-field-wrapper .mdc-floating-label--float-above {
  margin-top: 12px !important;
}
.dark-theme .flo-slim-select-panel .mdc-notched-outline__leading, .dark-theme .flo-slim-select-panel .mdc-notched-outline__notch, .dark-theme .flo-slim-select-panel .mdc-notched-outline__trailing,
.dark-theme .flo-date-range-selector .mdc-notched-outline__leading,
.dark-theme .flo-date-range-selector .mdc-notched-outline__notch,
.dark-theme .flo-date-range-selector .mdc-notched-outline__trailing {
  border-color: rgba(26, 26, 38, 0.12) !important;
}
.dark-theme .flo-slim-select-panel .mdc-text-field--outlined,
.dark-theme .flo-date-range-selector .mdc-text-field--outlined {
  background: #424242;
}
.dark-theme .flo-slim-select-panel .mat-mdc-form-field-infix,
.dark-theme .flo-date-range-selector .mat-mdc-form-field-infix {
  min-height: unset;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: unset;
  padding-top: 0 !important;
}
.dark-theme .flo-slim-select-panel .mat-mdc-form-field-infix mat-select,
.dark-theme .flo-date-range-selector .mat-mdc-form-field-infix mat-select {
  transform: translateY(-2px);
}
.dark-theme .flo-slim-select-panel .mat-mdc-form-field-infix mat-select .mat-mdc-select-arrow-wrapper,
.dark-theme .flo-date-range-selector .mat-mdc-form-field-infix mat-select .mat-mdc-select-arrow-wrapper {
  transform: translateY(-1px) !important;
}
.dark-theme .flo-textarea-panel .mat-mdc-text-field-wrapper {
  height: auto !important;
}
.dark-theme .flo-thin-picker-overlay {
  padding-top: 0 !important;
}
.dark-theme .flo-thin-picker-overlay .mat-mdc-option {
  min-height: 42px;
}
.dark-theme .mat-mdc-select-panel {
  padding-top: 0 !important;
}
.dark-theme .datepicker-hidden {
  display: flex;
  flex-direction: column;
  height: 0;
  justify-content: flex-start;
  overflow: hidden;
  width: 0;
}
.dark-theme .custom-pseudo-checkbox-mat-option {
  display: flex !important;
  align-items: center !important;
  padding-left: 16px;
}
.dark-theme .custom-pseudo-checkbox-mat-option .mdc-checkbox__native-control {
  display: none;
}
.dark-theme .custom-pseudo-checkbox-mat-option .mdc-checkbox {
  padding: 0;
  margin-right: 8px;
}
.dark-theme .custom-pseudo-checkbox-mat-option .mdc-checkbox__background {
  position: static;
}
.dark-theme .custom-pseudo-checkbox-mat-option .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: scale(0.7);
}
.dark-theme .custom-pseudo-checkbox-mat-option .mdc-form-field > label {
  padding-left: 0;
}
.dark-theme .flo-input-options {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
  border: solid 1px rgba(255, 255, 255, 0.12);
  transition: border-color 0.1s ease-in;
  border-radius: 4px;
  background: #424242;
}
.dark-theme .flo-input-options .input-field {
  min-width: 150px;
  padding: 0;
  border: none;
  background: none;
  color: #416f87;
  border-right: solid 1px rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .flo-input-options .input-field:focus-visible {
  outline: none;
}
.dark-theme .flo-input-options .input-icon {
  flex-shrink: 0;
  margin: 0 10px;
  height: 20px;
  width: 20px;
  color: inherit;
}
.dark-theme .flo-input-options .option-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  height: 36px;
  border: none;
  background: none;
  padding: 5px 5px;
  cursor: pointer;
}
.dark-theme .flo-input-options .option-btn .arrow-down {
  transition: color 0.2s ease-out;
}
.dark-theme .flo-input-options .option-btn:hover .arrow-down {
  color: #416f87;
}
.dark-theme .flo-input-options .option-btn:focus .arrow-down {
  color: #3b8da3;
}
.dark-theme .flo-input-options .option-btn__text {
  color: #416f87;
  letter-spacing: 0.5px;
}
.dark-theme .flo-input-menu-item {
  color: #416f87;
}
.dark-theme .active-menu-item {
  color: #3b8da3 !important;
  background: transparent !important;
}
.dark-theme .disable-field {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.12);
}
.dark-theme .input-auto-sizer {
  width: fit-content;
  max-width: 420px;
}
.dark-theme .schedule-settings-input {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
  cursor: pointer;
}
.dark-theme .schedule-settings-input .tune-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  margin-left: 12px;
}
.dark-theme .schedule-settings-input__warn {
  border-bottom-color: #f44336;
}
.dark-theme .schedule-settings-input__warn .inner-wrapper {
  padding-bottom: 0;
}
.dark-theme .schedule-settings-input:hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .schedule-settings-input:active {
  border-color: #3b8da3;
}
.dark-theme .schedule-settings-input .field-label {
  position: relative;
  top: 18px;
  border-top: 0.84375em solid transparent;
  padding: 0.25em 0.75em 0.75em 0.75em;
  line-height: 1.125;
}
.dark-theme .schedule-settings-input .field-label__warn {
  color: #f44336;
}
.dark-theme .schedule-settings-input .inner-wrapper {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 16px 1px 16px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dark-theme .schedule-settings-input .inner-wrapper-text-field {
  position: relative;
  top: 2px;
  border-top: 0.84375em solid transparent;
  padding: 0.25em 0 0.75em 0;
  line-height: 1.125;
}
.dark-theme .schedule-settings-input .inner-wrapper .filled-field-label {
  color: rgba(255, 255, 255, 0.7);
  scale: 0.8;
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 16px;
}
.dark-theme .scheduling-options-popup {
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  background-color: #424242;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  cursor: grab;
}
.dark-theme .scheduling-options-popup:active {
  cursor: grabbing;
}
.dark-theme .scheduling-options-popup__small {
  width: 480px;
}
.dark-theme .scheduling-options-popup .popup-header {
  margin-bottom: 5px;
  color: white;
}
.dark-theme .scheduling-options-popup .period-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.dark-theme .scheduling-options-popup .radio-group-wrapper {
  display: flex;
  flex-direction: column;
}
.dark-theme .scheduling-options-popup .radio-group-wrapper .radio-group {
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding-bottom: 10px;
}
.dark-theme .scheduling-options-popup .radio-group-wrapper .radio-group .month-radio-button-text-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.dark-theme .scheduling-options-popup .month-period {
  height: fit-content;
  max-width: 950px;
}
.dark-theme .scheduling-options-popup .days-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.dark-theme .scheduling-options-popup .days-list-wrapper .day-card {
  cursor: pointer;
  height: 32px;
  width: 43px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .scheduling-options-popup .days-list-wrapper .day-card__active {
  border-color: #3b8da3;
  background-color: var(--primary-color-opacity-02, rgba(246, 156, 0, 0.0196078431));
}
.dark-theme .scheduling-options-popup .days-list-wrapper .day-card__disabled {
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
}
.dark-theme .scheduling-options-popup .days-list-wrapper .times-per-day-card {
  width: 84px;
}
.dark-theme .scheduling-options-popup .action-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.dark-theme ._flo-tooltip, .dark-theme .flo-sim-info-usage-tip .mdc-tooltip__surface, .dark-theme .flo-custom-popup, .dark-theme .flo-custom-popup, .dark-theme .flo-sim-info-usage-tip .mdc-tooltip__surface, .flo-sim-info-usage-tip .dark-theme .mdc-tooltip__surface {
  background-color: #424242 !important;
  padding: 12px !important;
  color: !important;
  max-width: 370px !important;
  border-radius: 4px;
  word-wrap: normal;
  word-break: break-word;
  white-space: pre-line;
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
  box-shadow: 4px 4px 12px 6px;
}
.dark-theme .flo-tooltip-font {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
}
.dark-theme .flo-truncate-tooltip .mdc-tooltip__surface {
  background-color: #424242 !important;
  color: !important;
  border-radius: 4px;
  max-width: 225px;
  word-wrap: normal;
  word-break: break-word;
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  /*
   Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values.
  */
  font: 400 14px / 24px Roboto, sans-serif !important;
  letter-spacing: 0.25px !important;
  box-shadow: 4px 4px 12px 6px;
  white-space: pre-line !important;
}
.dark-theme .flo-info-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.dark-theme .whitespace-pre-line {
  white-space: pre-line;
}
.dark-theme .flo-select-search {
  align-items: center;
  background: #424242;
  border-bottom: 1px solid #cccccc;
  display: flex;
  position: sticky;
  gap: 4px;
  padding: 10px 16px;
  top: 0;
  width: 100%;
  z-index: 1;
}
.dark-theme .flo-select-search .input {
  width: inherit;
  color: rgba(26, 26, 38, 0.87);
  border: unset;
  height: 26px;
}
.dark-theme .flo-select-search .input::placeholder {
  color: rgba(35, 51, 81, 0.38);
}
.dark-theme .flo-select-search .search-icon {
  color: rgba(35, 51, 81, 0.38);
  flex-shrink: 0;
  height: 20px;
  width: 20px;
}
.dark-theme .flo-select-search .cancel-icon {
  flex-shrink: 0;
  line-height: 20px;
  width: 20px;
}
.dark-theme .flo-select-search .small-icon-button {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.dark-theme .flo-select-search .hidden {
  visibility: hidden;
}
.dark-theme .thin-select {
  padding: 6px 16px;
}
.dark-theme .thin-select .input {
  font-size: 14px !important;
  height: 26px;
}
.dark-theme .no-entries-found {
  padding: 16px;
}
.dark-theme .create-new {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 1em;
  font-size: 16px;
  cursor: pointer;
}
.dark-theme .create-new .plus {
  height: 25px;
  width: 30px;
  font-size: 24px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: var(--primary-color, #F69C00);
  line-height: 1;
  text-align: center;
  vertical-align: center;
}

.messaging__bell-icon .mat-badge-content {
  right: -2px !important;
  top: -2px !important;
  line-height: 15px !important;
  width: 14px;
  height: 14px;
}

.messaging__snack-bar-container {
  background-color: rgba(26, 26, 38, 0.87);
  color: #FFFFFF;
}
.messaging__snack-bar-container--info {
  background-color: var(--primary-color, #F69C00) !important;
}
.messaging__snack-bar-container--success {
  background-color: #3FAA19 !important;
}
.messaging__snack-bar-container--error {
  background-color: #F44336 !important;
}

sim-sub-account-details bd-header h2 {
  margin-top: 12px !important;
  font: 400 32px/36px Open Sans, sans-serif !important;
}

sim-notification-channels-type .default-table-header__row_additional_title {
  padding: 0 !important;
  border: 0 !important;
}
sim-notification-channels-type .default-table-header__row {
  background-color: unset;
}
sim-notification-channels-type .default-table-header__row_head {
  padding: 0 !important;
  border: 0 !important;
}

.flo-tooltip-custom-width .mdc-tooltip__surface {
  max-width: 430px !important;
  padding-left: 21px !important;
}

.cdk-overlay-container .popover-pie-chart {
  padding: 20px !important;
  scroll-padding: unset !important;
  overflow-y: auto;
  max-height: 230px !important;
}
.cdk-overlay-container .attached_offer__list {
  color: rgba(26, 26, 38, 0.6);
}

sim-sub-account-contact-list .bd-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell,
sim-terms-of-use .bd-table .table-wrapper .mat-mdc-table .mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: unset;
}

sim-range-filter .list .mat-form-field-label {
  white-space: normal;
  line-height: 1.5;
}

sim-manage-plans bd-lazy-table {
  border: none !important;
}
sim-manage-plans bd-lazy-table h1 {
  font-size: 24px;
}
sim-manage-plans .create-plan-button .flo-button {
  min-width: 0;
  padding: 0;
  width: 40px;
}
sim-manage-plans .create-plan-button .flo-button mat-icon {
  margin: 0;
}

res-sim-profile-list bd-table {
  padding: 0 !important;
}

.order-list-filters .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  min-width: 24em;
}

sim-order-details .header sim-flo-truncate-tooltip .text-value-truncated,
sim-batch-operation-list-details .header h1 sim-flo-truncate-tooltip .text-value-truncated {
  font-size: 32px;
  line-height: 18px;
}
sim-order-details .header sim-flo-truncate-tooltip .text-value-truncated__icon,
sim-batch-operation-list-details .header h1 sim-flo-truncate-tooltip .text-value-truncated__icon {
  color: rgba(35, 51, 81, 0.87);
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

sim-batch-operation-list-details .header h1 sim-flo-truncate-tooltip .text-value-truncated__icon {
  margin-top: 5px;
}

sim-sim-list .default-table-header__row_head,
sim-order-list .default-table-header__row_head,
sim-batch-operation-list .default-table-header__row_head,
lib-ra-rule-list .default-table-header__row_head,
sim-sub-account-list .default-table-header__row_head,
sim-fees-list .default-table-header__row_head {
  display: none !important;
}
sim-sim-list p,
sim-order-list p,
sim-batch-operation-list p,
lib-ra-rule-list p,
sim-sub-account-list p,
sim-fees-list p {
  margin: unset !important;
}

sim-terms-of-use .default-table-header__row_actions {
  display: none !important;
}

sim-batch-operation-list bd-lazy-table tr {
  cursor: pointer;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}

sim-batch-operation-list-details .default-table-header__row {
  background: white;
}
sim-batch-operation-list-details .default-table-header__row_head {
  max-height: initial;
  padding: 0;
}
sim-batch-operation-list-details .default-table-header__row_head .title-wrapper {
  width: 100%;
}
sim-batch-operation-list-details lib-header-filter-chips {
  display: none;
}
sim-batch-operation-list-details lib-custom-header .sub-title {
  order: 1;
  font-size: 12px !important;
  line-height: 12px !important;
  color: rgba(35, 51, 81, 0.6) !important;
  border: 1px solid rgba(35, 51, 81, 0.16);
  border-radius: 22px;
  padding: 0.313rem 0.5rem;
  margin-top: 7px;
}

sim-terms-of-use .mat-row,
sim-terms-of-use .mat-cell {
  border-bottom: none !important;
}

sim-sim-list sim-flo-truncate-tooltip span,
sim-sim-list .mat-column-country bd-icon-column-cell {
  white-space: nowrap;
}

sim-audit-trail .default-table-header__row_head {
  max-height: 80px;
}
sim-audit-trail .mat-slide-toggle-label {
  align-items: baseline;
}
sim-audit-trail .mat-slide-toggle-bar {
  top: 4px;
}

.filters-dialog .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}

lib-less-greater-side-filter .mat-form-field-appearance-fill .mat-form-field-flex {
  height: 3.2rem;
}

sim-settings-users-list tr.disabled .role-label {
  background: rgba(35, 51, 81, 0.06) !important;
  padding: 8px !important;
}
sim-settings-users-list tr.disabled .role-label:before {
  display: none;
}

bd-nav-breadcrumbs .mdc-list-item__primary-text {
  font-size: 10px !important;
  line-height: 12px !important;
}

sim-usage-filter-item .usage-value-input .mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0;
}

sim-device-operations-dialog .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(35, 51, 81, 0.16);
}

.table-header-filter-panel-popover {
  overflow-y: auto;
}

sim-notification-logs .default-table-header__row_head {
  min-height: 6.5em;
}

lib-registration .mat-mdc-form-field-infix {
  display: flex;
}
lib-registration .mat-mdc-text-field-wrapper {
  height: auto !important;
}

.form-disabled-styles .mat-form-field-disabled .mdc-floating-label {
  color: rgba(26, 26, 38, 0.6) !important;
}
.form-disabled-styles .mat-mdc-select-disabled {
  color: rgba(26, 26, 38, 0.87);
}
.form-disabled-styles .mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(26, 26, 38, 0.16) !important;
}
.form-disabled-styles .mdc-text-field--filled.mdc-text-field--disabled {
  background: none;
}
.form-disabled-styles .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(26, 26, 38, 0.87);
}
.form-disabled-styles .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  border-color: rgba(26, 26, 38, 0.16);
}

.field-label .mat-mdc-text-field-wrapper .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 20px;
}

.ec-minicart__icon {
  color: rgba(26, 26, 38, 0.6) !important;
}

.ec-minicart__icon svg {
  width: 70% !important;
  height: 70% !important;
}

.ec-minicart__icon .icon-default path[stroke], html#ecwid_html body#ecwid_body .ec-minicart__icon .icon-default circle[stroke] {
  stroke: rgba(26, 26, 38, 0.6) !important;
  transition: stroke 0.2s ease 0s;
}

.ec-minicart--no-shape {
  padding: 0 0 1px 0 !important;
}

.nested-plan .flo-button.flo-special-icon-text-btn {
  min-width: 40px !important;
  padding: 6px 7px !important;
}

.mdc-dialog__actions {
  padding: 16px !important;
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mdc-list-item label {
  font-size: 16px;
}

.ec-minicart__counter {
  position: absolute;
  top: 0 !important;
  left: 22px !important;
  font-size: 9px !important;
  height: 14px;
  width: 14px;
}
.ec-minicart__counter::after {
  background-color: #F44336 !important;
}

sim-product-allocation-list bd-lazy-table {
  border: 0;
}
sim-product-allocation-list .default-table-header__row {
  background-color: transparent;
}
sim-product-allocation-list .default-table-header__row_head {
  display: none !important;
}

.select-options-with-info .mdc-list-item__primary-text {
  display: flex;
  gap: 5px;
}

sim-product-allocation-edit .flo-dialog__header {
  justify-content: flex-start;
  padding: 10px 0;
}
sim-product-allocation-edit .flo-dialog__header button {
  position: absolute;
  right: 16px;
}
sim-product-allocation-edit .flo-dialog__header flo-chip-text {
  margin-top: 3px;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}