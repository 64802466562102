@use '../abstract/mixins' as m;
@use '@angular/material' as mat;
@mixin plans-list-component($theme) {
  $foreground: map-get($theme, foreground);
  $primary-theme: map-get($theme, primary);
  $white-color: mat.get-color-from-palette($primary-theme, 50);
  $divider-color: mat.get-color-from-palette($foreground, divider);

  .plans-list {
   @include m.column;
    height: 100%;
    .header-wrapper {
      box-shadow: 0 2px 1px -1px $divider-color, 0 1px 1px 0px $divider-color, 0 1px 3px 0px $divider-color;
      border-top: 1px solid $divider-color;
      border-bottom: 1px solid $divider-color;
      margin-bottom: -10px;
      background: $white-color;
      padding: 20px 20px 12px;
    }

    &__content {
      margin-top: 16px;
      padding: 3px;
      @include m.grow-hidden;
    }
  }
}
