@use "sass:list";
@use '@angular/material' as mat;
@use "../../components/colors" as colors;
@import "./typography_variables";
@import "./typography_fonts";

@mixin set-font-style($style-key) {
  /* If any of the values are set to `inherit`, we can't use the shorthand
   so we fall back to passing in the individual properties.
  */
  $style: map-get($typography-styles, $style-key);
  $font-size: map-get($style, 'font-size');
  $line-height: map-get($style, 'line-height');
  $font-weight: map-get($style, 'font-weight');
  $font-family: map-get($style, 'font-family');
  $letter-spacing: map-get($style, 'letter-spacing');

  @if ($font-size == inherit or $font-size == null or
      $font-weight == inherit or $font-weight == null or
      $line-height == inherit or $line-height == null or
      $font-family == inherit or $font-family == null or
      $letter-spacing == inherit or $letter-spacing == null) {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    line-height: $line-height !important;
    font-family: $font-family !important;
    letter-spacing: $letter-spacing !important;
  } @else {
    /*
     Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values.
    */
    font: $font-weight list.slash($font-size, $line-height) $font-family !important;
    letter-spacing: $letter-spacing !important;
  }
}

$bd-default-typography-config: mat.define-typography-config(
  $font-family: $font-family-primary,
  $headline-1: mat.define-typography-level($font-size-96, $line-height-112, $light, $font-family-secondary, $letter-spacing--1_5),
  $headline-2: mat.define-typography-level($font-size-60, $line-height-72, $light, $font-family-secondary, $letter-spacing-05),
  $headline-3: mat.define-typography-level($font-size-48, $line-height-58, $regular, $font-family-secondary),
  $headline-4: mat.define-typography-level($font-size-32, $line-height-36, $regular, $font-family-secondary),
  $headline-5: mat.define-typography-level($font-size-24, $line-height-24, $regular, $font-family-secondary, $letter-spacing-04),
  $headline-6: mat.define-typography-level($font-size-20, $line-height-24, $regular, $font-family-secondary, $letter-spacing-04),
  $subtitle-1: mat.define-typography-level($font-size-16, $line-height-24, $regular, $font-family-secondary, $letter-spacing-04),
  $subtitle-2: mat.define-typography-level($font-size-18, $line-height-24, $regular, $font-family-secondary, $letter-spacing-04),
  $body-1: mat.define-typography-level($font-size-16, $line-height-24, $medium),
  $body-2: mat.define-typography-level($font-size-14, $line-height-18, $regular),
  $caption: mat.define-typography-level($font-size-11, $line-height-12, $regular, $font-family-primary, $letter-spacing-04),
  $button: mat.define-typography-level($font-size-14, $line-height-16, $semi-bold, $font-family-secondary, $letter-spacing-05),
  $overline: mat.define-typography-level(inherit, 1.125, $regular)
);

.sidenav-typography {
  @include set-font-style($FONT_SECONDARY_16_24_400_02);
}

.button-little-typography {
  /*Should be always uppercase
   */
  @include set-font-style($FONT_SECONDARY_12_14_600_1);
}

.button-typography {
  @include set-font-style($FONT_SECONDARY_14_16_600_05);
}

.table-title-typography {
  /* Should be always uppercase
   */
  @include set-font-style($FONT_PRIMARY_11_16_500_04);
}

.table-data-typography {
  @include set-font-style($FONT_PRIMARY_13_24_400_025);
}

.table-counter-typography {
  @include set-font-style($FONT_PRIMARY_14_24_400_025);
}

.flo-thin-input-typography {
  @include set-font-style($FONT_PRIMARY_14_24_400_025);
}

.table-filter-selected-chip {
  @include set-font-style($FONT_PRIMARY_12_16_400_04);
}

.label-typography {
  @include set-font-style($FONT_PRIMARY_12_16_400_04);
}

.label-caption-typography {
  @include set-font-style($FONT_PRIMARY_11_12_400_04);
}

.info-text-typography {
  @include set-font-style($FONT_PRIMARY_13_24_400_025);
}

.list-text-typography {
  @include set-font-style($FONT_PRIMARY_14_24_400_025);
}

.note-text-typography {
  @include set-font-style($FONT_PRIMARY_14_21_400_025);
}

.markup-chip-content-typography {
  @include set-font-style($FONT_PRIMARY_14_24_400_025);
}

.custom-markup-chip-typography {
  @include set-font-style($FONT_PRIMARY_12_16_500_04);
}

.helper-typography {
  @include set-font-style($FONT_PRIMARY_10_12_400_04);
}

.mat-mdc-form-field-error {
  @include set-font-style($FONT_PRIMARY_10_12_400_04);
}

.breadcrumbs-typography {
  /* Should be always uppercase
   */
  @include set-font-style($FONT_PRIMARY_10_12_500_05);
}

.subtitle-summary-typography {
  /*
  Should be always uppercase
  */
  @include set-font-style($FONT_SECONDARY_12_14_600_1);
}

.text-align-center {
  text-align: center;
}

.text-direction-rtl {
  direction: rtl;
}

.text-red {
  color: red;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-family-primary {
  font-family: $font-family-primary;
}

.font-family-secondary {
  font-family: $font-family-secondary;
}

.font-weight-light {
  font-weight: $light !important;
}

.font-weight-regular {
  font-weight: $regular !important;
}

.font-weight-medium {
  font-weight: $medium !important;
}

.font-weight-semi-bold {
  font-weight: $semi-bold !important;
}

.font-weight-bold {
  font-weight: $bold !important;
}

.font-weight-extra-bold {
  font-weight: $extra-bold !important;
}

.mat-typography h1 {
  font-size: $font-size-32;

  &.smaller {
    font-size: $font-size-24;
  }
}
