@use '@angular/material' as mat;
@use "colors";

@mixin order-sims($theme) {
  $foreground: map-get($theme, foreground);
  $primary-theme: map-get($theme, primary);
  $accent-theme: map-get($theme, accent);
  $divider-color: colors.$gray-16;
  $secondary-text: colors.$gray-60;
  $icon-color: colors.$gray-38;
  $white-color: mat.get-color-from-palette($primary-theme, 50);
  $background-default: map-get($primary-theme, 450);

  .order-sims {
    .wrapper {
      position: relative;
      border-radius: 4px;
      padding: 16px;

      &--bg-blue {
        background: colors.$black-04;

        .mat-flat-button.mat-primary {
          background: mat.get-color-from-palette($accent-theme, 350);
        }
      }

      &--bg-light-gray {
        background: $background-default;
        color: $secondary-text;

        h2 {
          color: colors.$status-green-100;
        }
        mat-icon {
          width: 64px;
          height: 64px;
          line-height: 64px;
          font-size: 53px;
          color: colors.$status-green-100;
        }
      }

      &--bg-dark-gray {
        background: $secondary-text;
        color: $white-color;

        mat-divider {
          border-top-color: $white-color;
        }
      }

      &--bg-outline {
        border: 1px solid $divider-color;
      }

    }

    .mat-card {
      box-shadow: none;
      border: 1px solid $divider-color;
      .mat-card-header {
        padding-left: 24px;
        .mat-card-title {
          margin-bottom: 0;
          .title {
            font-weight: normal;
            font-size: 20px;
          }
        }
      }
      .mat-card-content {
        .mat-icon {
          font-size: 20px;
          color: $icon-color;
        }
      }
    }
    .info-icon {
      font-size: 24px;
      color: colors.$black-60;
    }
    .limit-info {
      font-size: 12px;
      color: colors.$black-60;
    }
  }

  .sim-types {
    line-height: 24px;
    &__title {
      color: colors.$gray-60;
    }
    &__value {
      color: colors.$gray-87;
    }
  }

  .mat-tooltip.tooltip-blue {
    background: colors.$gray-100;
    max-width: 150px;
  }
}
