@mixin service-providers($theme) {
  .service-providers {
    .mat-tab-link {
      padding: 0 10px;
      min-width: 410px;
      opacity: 1;
    }

    .no-data {
      max-height: 240px !important;
      overflow: hidden;
    }
  }
}
