@use '@angular/material' as mat;
@use '../themes/app-theme' as flo-theme-agregation;
@use '../themes/typography/typography' as flo-typography;
@use 'color-palettes/bd-dark-primary-palette' as flo-dark-pallette;


$bd-dark-theme: mat.define-dark-theme((
        color: (
                primary: flo-dark-pallette.$bd-dark-primary,
                accent: flo-dark-pallette.$bd-dark-primary,
        ),
        foreground: flo-dark-pallette.$mat-dark-theme-foreground
));

.dark-theme {
  @include mat.core-color($bd-dark-theme);
  @include mat.all-component-colors($bd-dark-theme);
  @include flo-theme-agregation.app-theme($bd-dark-theme);
}
