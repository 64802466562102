@use '@angular/material' as mat;
@use '../components/colors' as colors;

@mixin main-container-theme($theme, $toolbar-height) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $card-background: mat.get-color-from-palette($background, card);
  .main {
    display: block;

    &--with-margins {
      padding: 20px 40px 40px 40px;
    }
  }

  html {
    height: 100vh;
  }

  body {
    height: 100%;
  }

  .wrapper-sidenav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $toolbar-height;
    padding-left: 10px;
    padding-right: 10px;
  }
}
