@use "projects/shared/src/assets/styles/components/colors" as colors;

mat-selection-list {
  .mat-list-item-content {
    .mat-list-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mat-form-field-inherit-fill {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
}

mat-form-field {
  .mat-mdc-form-field-icon-suffix {
    padding-bottom: 5px;
  }
}

.full-width-expansion-panel-body {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
  color: inherit !important;
}

.mat-ink-bar {
  background: colors.$primary-100 !important;
}

.mat-step-icon-state-edit {
  .mat-icon {
    color: inherit;
  }
}

.mat-mdc-list-option .mdc-checkbox__checkmark {
  color: #{colors.colorWithOpacity(colors.$white-100, colors.$opacity-87)} !important;
}

.mat-mdc-dialog-surface {
  overflow-x: clip;
}

.mdc-list-item--selected {
  .mat-pseudo-checkbox-minimal {
    display: none;
  }
}

.mdc-switch__icons {
  display: none;
}

a:-webkit-any-link {
  color: inherit;
}

.no-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-slide-toggle {
  .mdc-form-field>label {
    padding-left: 8px;
  }
}

.mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full {
  margin-right: 8px !important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: inherit !important;
}

.mat-mdc-form-field.mat-focused:not(.mat-form-field-invalid) {
  .mat-mdc-form-field-label,
  .mat-mdc-form-field-required-marker {
    color: colors.$black-87;
  }

  input[type=number]::-webkit-inner-spin-button {
    color: red;
  }
}

.mat-calendar-body-cell:hover {
  .mat-calendar-body-cell-content {
    color: colors.$white-100;
  }
}

.mdc-tooltip {
  @extend .helper-typography;
}

.mat-mdc-icon-button:disabled {
  color: var(--mdc-icon-button-disabled-icon-color) !important;
}


