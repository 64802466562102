@use "colors" as colors;

@mixin uuid-popover($theme) {
  .uuid-popover {
    &:before {
      display: block;
      content: "";
      position: absolute;
      right: 12px;
      top: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent colors.$white-100;
      border-width: 0 8px 8px;
    }
  }
}
