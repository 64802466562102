@use '@angular/material' as mat;
@use "projects/shared/src/assets/styles/components/colors" as colors;

@mixin default-table-header-v2($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary-theme: map-get($theme, primary);
  $accent-theme: map-get($theme, accent);
  $card-background: mat.get-color-from-palette($background, card);
  $divider-color: mat.get-color-from-palette($foreground, icon);
  $border: 1px solid mat.get-color-from-palette($foreground, divider);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $icon-color: mat.get-color-from-palette($foreground, icon);
  $main-text-color: mat.get-color-from-palette($foreground, text);
  $white: mat.get-color-from-palette($primary-theme, 50);
  $filters-bg-color: mat.get-color-from-palette($accent-theme, 700);
  $table-row-odd: mat.get-color-from-palette($background, background);

  $size-base: 10px;

  .default-table-header {

    &__actions, &__amount {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-shrink: 1;
      gap: 10px;
    }

    &__row {
      height: 6 * $size-base;
      background-color: colors.$white-100;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;

      &_head {
        border-bottom: $border;
        max-height: 4.5em;
        padding: 1.5em 0;

        .header-action-btn-group {
          margin: 0 1em;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 16px;
        }
      }

      &_actions,
      &_filters {
        border-bottom: $border;
      }

      &_filters {
        background-color: $filters-bg-color;
        height: 48px;

        &-parameters {
          width: fit-content;
        }

        &-parameter {
          margin: 5px 0;
          .filtered-by-text {
            color: $secondary-text-color;
            @extend .table-filter-selected-chip;
          }

          .default-table-header__separator {
            font-size: 20px;
            color: colors.$gray-16;
          }
        }

        .filter-counter-group {
          display: flex;
          flex-wrap: nowrap;
        }
      }

      &_additional_title {
        display: flex;
        align-items: center;
        background-color: $white;
        height: 6 * $size-base;
        font-size: 24px;
        padding-left: 2 * $size-base;
        border-bottom: $border;
      }
    }

    &__separator {
      padding: 0 12px;
      font-size: 20px;
      font-weight: 100;
      color: $divider-color;
    }

    &__button {
      &_default {
        font-size: 18px;
      }

      &_search {
        font-size: 18px;
        width: 18px;
      }
    }

    .title-wrapper {
      height: 100%;
      flex-grow: 2;

      .title-text {
        color: $main-text-color;
      }
    }

    .selected-from-text {
      color: $secondary-text-color;
    }

    .filtered-by {
      font-weight: 500;
    }

    .filtered-by-text {
      color: $secondary-text-color;
      font-weight: 500;
      margin-right: $size-base;
    }

    .system-button {
      &__icon {
        font-size: 24px;
        color: $icon-color;
      }

      .header-button-icon {
        color: inherit;
      }

      &.mat-stroked-button {
        background-color: $white;
      }
    }

    .bulk-action-button {
      font-size: 20px;
      color: $icon-color;

      &__icon {
        font-size: 24px;
      }
    }

    .header-padding-20 {
      padding: 0 20px;
    }

    .header-padding-24 {
      padding: 0 24px;
    }
  }

  .default-table-header-v2 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $card-background
  }

  .filters-list-wrapper {
    overflow: hidden;
    width: 100% !important;
  }

  .mat-chips-row {
    .mdc-evolution-chip-set__chips {
      flex-wrap: nowrap !important;
    }
  }
  .more-button {
    align-self: center;
  }

  .more-button:hover {
    cursor: pointer;
    color: $main-text-color;
    transition: all .5s;
  }

  .clear-all-button {
    flex-shrink: 0;
  }

  .filter-chips-panel-popover {
    width: fit-content;
    block-size: fit-content;
  }

  .filter-chips-panel-popover, .filter-chips-panel {
    @extend .flo-chip-v2;

    mat-chip-list {
      .mat-chip-remove {
        line-height: initial;
      }
    }

    padding: 0 24px;

    .filtered-by-text {
      white-space: nowrap;
      color: $secondary-text-color;
      margin: 0 $size-base;
    }
  }

  .filter-chips-panel-popover {
    height: fit-content;
  }

  .collapsed-group-wrapper:last-child {
    margin-bottom: 10px;
  }

  .action-label {
    margin-left: $size-base
  }

  .filter-label {
    margin-left: $size-base * .5;
  }
}
