@use '@angular/material' as mat;
@use "projects/shared/src/assets/styles/components/colors" as colors;

@mixin flo-label-chip($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $text-color: mat.get-color-from-palette($foreground, secondary-text);
  $border-color: mat.get-color-from-palette($foreground, divider);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $chip-background-color: mat.get-color-from-palette($background, card);
  $icon-color: mat.get-color-from-palette($foreground, icon);
  .labels-wrapper {
    padding: 6px 8px;
    max-width: 200px;
    background: $chip-background-color;
    border: solid 1px $border-color !important;
    height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;

    .dot {
      height: 8px;
      width: 8px;
      min-width: 8px;
      background-color: mat.get-color-from-palette($primary);
      border-radius: 50%;
      display: inline-block;
    }

    .labels-text, sim-flo-truncate-tooltip {
      font-size: 12px;
      line-height: 12px;
      color: colors.$gray-60;
    }
  }

  :host-context(.white-labels),
  .white-labels {
    .labels-wrapper {
      background: $chip-background-color;
      border: 1px solid $border-color;
      border-radius: 22px;

      .labels-text {
        color: $text-color;
      }
    }
  }
}
