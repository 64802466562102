@use '@angular/material' as mat;
@use 'projects/shared/src/assets/styles/components/colors' as colors;
@use 'projects/shared/src/assets/styles/themes/typography/typography' as typography;

@mixin angular-material-defaults ($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $dark-primary-text: mat.get-color-from-palette($foreground, text);
  $light-secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $dark-secondary-text: mat.get-color-from-palette($foreground, dark-secondary-text);

  $disabled-text: mat.get-color-from-palette($foreground, disabled-text);
  $primary-icon-color: mat.get-color-from-palette($foreground, icon);
  $disabled-icon-button-color: colors.$black-16;

  :root {
    .default-theme {
      // mat-checkbox
      --mdc-checkbox-selected-checkmark-color: #{colors.colorWithOpacity(colors.$white-100, colors.$opacity-87)};
      --mdc-checkbox-disabled-selected-icon-color: #{colors.$black-16};
      --mdc-checkbox-disabled-unselected-icon-color: #{colors.$black-16};
      --mdc-checkbox-unselected-focus-icon-color: #{$primary-icon-color};
      --mdc-checkbox-unselected-hover-icon-color: #{$primary-icon-color};
      --mdc-checkbox-unselected-icon-color: #{$primary-icon-color};
      --mdc-checkbox-unselected-pressed-icon-color: #{$primary-icon-color};
      --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
      --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
      --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;

      --flo-checkbox-size: 16px;
      --mdc-checkbox-state-layer-size: 32px;

      .mdc-checkbox__background {
        height: var(--flo-checkbox-size);
        width: var(--flo-checkbox-size);
      }
      .mat-pseudo-checkbox {
        width: var(--flo-checkbox-size);
        height: var(--flo-checkbox-size);
      }

      .mat-mdc-radio-button {
        --mdc-radio-disabled-selected-icon-color:  #{colors.$black-16};
        --mdc-radio-disabled-unselected-icon-color:  #{colors.$black-16};
      }

      // mat-chip
      --mdc-chip-with-icon-icon-color: #{$primary-icon-color};


      // mat-table
      --mat-table-header-container-height: 56px;
      --mat-paginator-container-size: 68px;
      --mat-paginator-container-text-color: #{$light-secondary-text};

      .mat-mdc-button.mat-unthemed {
        --mdc-text-button-label-text-color: #{$dark-primary-text};
      }

      .mat-mdc-outlined-button.mat-unthemed {
        --mdc-outlined-button-label-text-color: #{$dark-primary-text};
      }


      .mat-mdc-unelevated-button[disabled][disabled] {
        --mdc-filled-button-disabled-container-color: #{colors.$black-06};
        --mdc-filled-button-disabled-label-text-color: #{$disabled-text};
        --mdc-filled-button-container-color: #{colors.$black-06};
        --mdc-filled-button-label-text-color: #{$disabled-text};
      }

      .mat-mdc-icon-button {
        --mat-mdc-button-persistent-ripple-color: #{colors.$gray-16};
        .mat-mdc-button-base {
          --mdc-icon-button-state-layer-size: 40px;
          padding: 8px;
        }
      }

      .mat-mdc-icon-button .mat-mdc-button-touch-target {
        height: 40px;
        width: 40px;
      }

      // mat-icon default icon color
      --mat-icon-color: #{$primary-icon-color};
      --mdc-icon-button-icon-color: #{$primary-icon-color};

      .mat-mdc-icon-button:disabled {
        mat-icon {
          color: var(--mdc-icon-button-disabled-icon-color);
        }
      }

      .mat-mdc-icon-button[disabled][disabled] {
        --mdc-icon-button-icon-color: #{$disabled-icon-button-color};
        --mdc-icon-button-disabled-icon-color: #{$disabled-icon-button-color};
      }

      // mat-menu
      --mat-menu-item-icon-color: #{$primary-icon-color};

      // Text colors section
      --mat-expansion-header-text-color: #{$dark-primary-text};
      --mat-expansion-container-text-color: #{$dark-primary-text};
      --mdc-list-list-item-label-text-color: #{$dark-primary-text};
      --mat-option-label-text-color: #{$dark-primary-text};
      --mat-optgroup-label-text-color: #{$dark-primary-text};
      --mdc-theme-text-primary-on-background: #{$dark-primary-text};
      --mat-sidenav-content-text-color: #{$dark-primary-text};
      --mat-menu-item-label-text-color: #{$dark-primary-text};
      --mat-stepper-header-selected-state-label-text-color: #{$dark-primary-text};

      --mdc-filled-text-field-label-text-color: #{$dark-secondary-text};
      --mat-stepper-header-label-text-color: #{$dark-secondary-text};
      --mat-stepper-header-icon-background-color: #{$disabled-text};


      // mat-datepicker calendar
      --mat-datepicker-calendar-date-text-color: #{$dark-primary-text};
      --mat-datepicker-calendar-body-label-text-color: #{$dark-secondary-text};
      --mat-datepicker-calendar-header-text-color: #{$disabled-text};


      // mdc-mat-card mat-card -mdc-card
      .mdc-card {
        padding: 16px;
      }

      .mdc-card:not([class*=mat-elevation-z]) {
        border: solid 1px colors.$black-12;
        border-radius: 8px;
        box-shadow: none;
      }

      //tab-link mat-tab mat-mdc-tab default-tabs
      .mat-mdc-tab-link, .mdc-tab__text-label {
        font-family: #{typography.$font-family-secondary};
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 16px;
        font-weight: #{typography.$semi-bold};
      }

      .flo-inner-tabs {
        .mat-mdc-tab-link, .mdc-tab__text-label {
          font-family: #{typography.$font-family-secondary};
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 24px;
          font-weight: #{typography.$regular};
        }
      }

      .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
        --mat-tab-header-inactive-ripple-color: inherit;
        --mat-tab-header-active-ripple-color: inherit;
        --mat-tab-header-inactive-label-text-color: #{$dark-secondary-text};
      }

      // mat-form-field
      @include mat.private-form-field-density(0);
      --mdc-filled-text-field-input-text-color: #{$dark-primary-text};
      --mat-select-enabled-trigger-text-color: #{$dark-primary-text};
      --mat-form-field-container-text-weight: 400;



      // mat-form-field height and label position
      .mat-mdc-text-field-wrapper {
        height: 48px;

        .mat-mdc-floating-label:not(.mdc-floating-label--float-above ) {
          top: 50%;
        }

        .mat-mdc-select-arrow-wrapper {
          transform: translateY(-3px);
        }
      }

      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 26px;
        padding-bottom: 6px;
      }

      .mat-mdc-option-ripple {
        display: none !important;
      }


      // mat-form-field disabled
      --mdc-filled-text-field-disabled-label-text-color: #{$disabled-text};
      --mdc-outlined-text-field-disabled-label-text-color: #{$disabled-text};
      --mdc-filled-text-field-disabled-input-text-color: #{$disabled-text};
      --mdc-outlined-text-field-disabled-input-text-color: #{$disabled-text};

      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }
      // mat-form-field trigger fonts
      --mat-select-trigger-text-font: typography.$font-family-primary;
      --mat-select-focused-arrow-color: #{colors.$black-60};
      --mat-select-invalid-arrow-color: #{colors.$black-60};
      --mat-select-disabled-arrow-color: #{colors.$black-16};
      --mat-select-trigger-text-line-height: 24px;
      --mat-select-trigger-text-size: 16px;
      --mat-select-trigger-text-weight: 400;
      --mat-select-trigger-text-tracking: normal;
      --mat-paginator-select-trigger-text-size: 14px;

      --mdc-filled-text-field-label-text-font: typography.$font-family-primary;
      --mdc-filled-text-field-label-text-size: 16px;
      --mdc-filled-text-field-label-line-height: 24px;
      --mdc-filled-text-field-label-text-weight: 400;
      --mdc-outlined-text-field-label-text-weight: 400;


      //error
      --mdc-outlined-text-field-error-focus-outline-color: #{colors.$status-red-100} !important;
      --mdc-outlined-text-field-error-label-text-color: #{colors.$black-60} !important;
      --mdc-outlined-text-field-error-focus-label-text-color: #{colors.$status-red-100} !important;
      --mdc-outlined-text-field-error-outline-color: #{colors.$black-16} !important;
      --mdc-outlined-text-field-error-hover-outline-color: #{colors.$black-38} !important;

      // focus
      --mdc-outlined-text-field-focus-label-text-color: #{colors.$primary-100} !important;
      --mdc-outlined-text-field-focus-outline-color: #{colors.$primary-100} !important;
      --mat-option-selected-state-label-text-color: #{colors.$primary-100} !important;

      // outline
      --mdc-outlined-text-field-outline-color: #{colors.$black-16} !important;
      --mdc-outlined-text-field-hover-outline-color: #{colors.$black-38} !important;

      --mdc-filled-text-field-label-text-tracking: normal;

      --mat-form-field-subscript-text-line-height: 18px;

      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-width: 1px !important;
      }

      ._form-field-font-config {
        font-family: typography.$font-family-primary;
        letter-spacing: 0.5px;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
      }

      .mdc-list-item__primary-text {
        @extend ._form-field-font-config;
      }

      .mat-mdc-select,
      .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        @extend ._form-field-font-config;
      }

      // mat-option hover effects
      --mat-option-hover-state-layer-color: inherit;
      --mat-option-focus-state-layer-color: none;
      --mat-option-selected-state-layer-color: none;

      .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
        background-color: var(--mat-option-hover-state-layer-color) !important;
      }

      // mat-hint color and font
      mat-hint {
        @include typography.set-font-style(typography.$FONT_PRIMARY_12_16_400_04);
        color: $dark-secondary-text;
      }

      .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
        @extend .mat-body-2 !optional;
      }

      // mat-dialog
      .mat-mdc-dialog-container {
        --mdc-dialog-container-color: #{colors.$white-100};
        --mdc-dialog-subhead-color: #{$dark-primary-text};
        --mdc-dialog-supporting-text-color: #{$dark-primary-text};
        .mdc-dialog__content {
          --mdc-dialog-supporting-text-font: #{typography.$font-family-primary};
          --mdc-dialog-supporting-text-line-height: 18px;
          --mdc-dialog-supporting-text-size: 14px;
          --mdc-dialog-supporting-text-weight: #{typography.$regular};
          --mdc-dialog-supporting-text-tracking: normal;
        }
      }

      //MatSnackbar
      .mat-mdc-snack-bar-container {
        --mdc-snackbar-container-color: inherit;
        --mdc-snackbar-supporting-text-color: inherit;
        --mat-snack-bar-button-color: inherit;
      }

      // mat-slide-toggle handle colors
      .mat-mdc-slide-toggle {
        --mdc-switch-handle-surface-color: #{colors.$primary-100};
        --mdc-switch-selected-handle-color: #{colors.$primary-100};
        --mdc-switch-selected-hover-handle-color: #{colors.$primary-100};
        --mdc-switch-selected-track-color: var(--primary-color-opacity-16);
        --mdc-switch-selected-pressed-track-color: var(--primary-color-opacity-16);
        --mdc-switch-selected-pressed-handle-color: #{colors.$primary-100};
        --mdc-switch-selected-focus-track-color: var(--primary-color-opacity-16);
        --mdc-switch-selected-hover-track-color: var(--primary-color-opacity-16);
        --mdc-switch-unselected-focus-handle-color: #{colors.$white-100};
        --mdc-switch-unselected-handle-color: #{colors.$white-100};
        --mdc-switch-unselected-hover-handle-color: #{colors.$white-100};
        --mdc-switch-disabled-unselected-handle-color: #{colors.$white-100};
        --mdc-switch-disabled-selected-handle-color: var(--primary-color-opacity-50);
        --mdc-switch-disabled-selected-track-color: var(--primary-color-opacity-50);
        --mdc-switch-unselected-pressed-handle-color: var(--primary-color-opacity-50);
        --mdc-switch-unselected-pressed-state-layer-color: #{colors.$primary-100};
      }
      .mat-mdc-slide-toggle.mat-accent {
        --mdc-switch-selected-focus-handle-color: #{colors.$primary-100};
      }
      .mdc-switch:disabled {
        --mdc-switch-handle-surface-color: #{colors.$white-100};
        .mdc-switch__shadow {
          box-shadow: var(--mdc-switch-handle-elevation-shadow);
        }
      }

      // mat-progress-bar
      .mat-mdc-progress-bar {
        --mdc-linear-progress-active-indicator-color: #{colors.$primary-100};
        --mdc-linear-progress-track-color: var(--primary-color-opacity-50);
      }

      .mat-mdc-list-base {
        --mdc-list-list-item-hover-state-layer-color: unset;
        --mdc-list-list-item-hover-state-layer-opacity: unset;
        --mdc-list-list-item-focus-state-layer-color: unset;
        --mdc-list-list-item-focus-state-layer-opacity: unset;
      }
    }
  }
}
