.messaging {
  &__bell-icon {
    .mat-badge-content {
      right: -2px !important;
      top: -2px !important;
      line-height: 15px !important;
      width: 14px;
      height: 14px;
    }
  }
}
