.row--form-row {
  margin: 0 -5px;

  & > *[class*="col-"] {
    padding: 0 5px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
