@use '@angular/material' as mat;
@use "projects/shared/src/assets/styles/components/colors" as colors;

@mixin flo-chip-text($theme) {
  .chip-wrapper {
    padding: 3px 8px;
    background: colors.$white-100;
    border: solid 1px colors.$gray-16 !important;
    height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    .chip-content {
      border-left: 1px solid colors.$gray-16;
      padding-left: 6px;
      margin-left: 6px;

      &:first-child {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
      }
    }

    .chip-title, .chip-text, sim-flo-truncate-tooltip {
      font-size: 12px;
      line-height: 18px;
      color: colors.$gray-60;
    }

    .chip-title {
      padding-right: 5px;
    }

    .flo-icon-color {
      color: colors.$gray-38;
    }
  }
}
