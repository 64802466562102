@use '@angular/material' as mat;

@mixin sim-list-component($theme) {
  $foreground: map-get($theme, foreground);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  .sim-list {
    bd-nav-breadcrumbs {
      display: block;
      margin-bottom: 0.5em;
    }

    .iccid {
      display: flex;
      flex-direction: column;

      span {
        line-height: 1.2;

        &.alias {
          font-size: 11px;
          color: $secondary-text-color;
        }
      }
    }

    sim-flo-truncate-tooltip span,
    .mat-column-country bd-icon-column-cell {
      white-space: nowrap;
    }

    bd-lazy-table {
      .table-wrapper {
        .mat-mdc-table {
          .mat-mdc-header-cell.bulk-checkbox-row-header-cell,
          .mat-mdc-cell.bulk-checkbox-row-cell {
            border-right: none !important;
          }

          .mat-mdc-header-row,
          .mat-mdc-row {
            .additional-cell-padding:nth-child(2) {
              padding-left: 0 !important;
            }
          }

          .starting-column {
            padding-left: 24px !important;
            .mat-mdc-icon-button {
              //width: 24px;
              //height: 24px;
              //line-height: 24px;
            }
          }
        }
      }
    }
  }
}
