@use '@angular/material' as mat;

@mixin system-labels($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $chip-background: mat.get-color-from-palette($background, card);
  $chip-text-color: mat.get-color-from-palette($foreground, disabled);

  .system-labels {
    .mat-chip.mat-standard-chip {
      @extend .label-typography;
      @extend .font-weight-medium;
      color: $chip-text-color;
      background-color: $chip-background;
      border-radius: 6px;
      border: 1px solid $chip-text-color;
      max-width: 195px;
    }
  }
}
