// Font-family
$font-family-primary: Roboto, sans-serif;
$font-family-secondary: Open Sans, sans-serif;

// Font-weight
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// font-size
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-11_5: 11.5px; // variable not from typography list
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-48: 48px; // variable not from typography list
$font-size-55: 55px;
$font-size-60: 60px; // variable not from typography list
$font-size-96: 96px; // variable not from typography list

// line-height
$line-height-12: 12px;
$line-height-14: 14px;
$line-height-16: 16px;
$line-height-18: 18px;
$line-height-24: 24px;
$line-height-21: 21px;
$line-height-36: 36px;
$line-height-58: 58px;
$line-height-72: 72px; // variable not from typography list
$line-height-112: 112px; // variable not from typography list

// letter-spacing
$letter-spacing-02: 0.2px;
$letter-spacing-025: 0.25px;
$letter-spacing-04: 0.4px;
$letter-spacing-05: 0.5px;
$letter-spacing-1: 1px;
$letter-spacing--1_5: -1.5px; // variable not from typography list
