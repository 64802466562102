@use '@angular/material' as mat;

@mixin flo-input-options-component($theme) {
  $field-height: 36px;
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary));
  $border-color: mat.get-color-from-palette($foreground, divider);
  $primary-text-color: mat.get-color-from-palette(map-get($theme, primary), 700);
  $hover-btn-color: mat.get-color-from-palette($background, hover);
  $focus-btn-color: mat.get-color-from-palette($background, focused-button);
  $disabled-btn-color: mat.get-color-from-palette($background, disabled-button);

  .flo-input-options {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: $field-height;
    border: solid 1px $border-color;
    transition: border-color .1s ease-in;
    border-radius: 4px;
    background: mat.get-color-from-palette($background, card);

    .input-field {
      min-width: 150px;
      padding: 0;
      border: none;
      background: none;
      color: $primary-text-color;
      border-right: solid 1px $border-color !important;

      &:focus-visible {
        outline: none;
      }
    }

    .input-icon {
      flex-shrink: 0;
      margin: 0 10px;
      height: 20px;
      width: 20px;
      color: inherit;
    }

    .option-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100px;
      height: $field-height;
      border: none;
      background: none;
      padding: 5px 5px;
      cursor: pointer;

      .arrow-down {
        transition: color .2s ease-out;
      }

      &:hover {
        .arrow-down {
          color: $primary-text-color;
        }
      }

      &:focus {
        .arrow-down {
          color: $primary-color;
        }
      }

      &__text {
        color: $primary-text-color;
        letter-spacing: 0.5px;
      }
    }
  }

  .flo-input-menu-item {
    color: $primary-text-color;
  }

  .active-menu-item {
      color: $primary-color !important;
      background: transparent !important;
  }

  .disable-field {
    pointer-events: none;
    color: $border-color;
  }

  .input-auto-sizer {
    width: fit-content;
    max-width: 420px;
  }

}

