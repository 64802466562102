.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-initial {
  pointer-events: initial;
}
