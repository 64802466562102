@use "sass:math";
@import "colors-bss";

// Opacity options
$opacity-100: 1;
$opacity-87: 0.87;
$opacity-60: 0.6;
$opacity-38: 0.38;
$opacity-24: 0.24;
$opacity-16: 0.16;
$opacity-12: 0.12;
$opacity-08: 0.08;
$opacity-06: 0.06;
$opacity-04: 0.04;
$opacity-02: 0.02;

// Neutral colours
$background-100: #FCFCFC;
$background-local: #FAFAFA;
$background-row: #F1F1F1;

$white-100: #FFFFFF;
$white-08: rgba($white-100, $opacity-08);

$gray-100: #233351;
$gray-87: rgba($gray-100, $opacity-87);
$gray-60: rgba($gray-100, $opacity-60);
$gray-38: rgba($gray-100, $opacity-38);
$gray-16: rgba($gray-100, $opacity-16);
$gray-06: rgba($gray-100, $opacity-06);

$black-100: #1A1A26;
$black-87: rgba($black-100, $opacity-87);
$black-60: rgba($black-100, $opacity-60);
$black-38: rgba($black-100, $opacity-38);
$black-12: rgba($black-100, $opacity-12);
$black-16: rgba($black-100, $opacity-16);
$black-08: rgba($black-100, $opacity-08);
$black-06: rgba($black-100, $opacity-06);
$black-04: rgba($black-100, $opacity-04);
$black-02: rgba($black-100, $opacity-02);

// Customizable colours
$primary-100: var(--primary-color, #F69C00);
$primary-16: var(--primary-color-opacity-16, #F69C0029);
$primary-02: var(--primary-color-opacity-02, #F69C0005);

$secondary-100: var(--secondary-color, #2C3D5E);

$graphs-100: var(--chart-color, #64ADF0);

// Status colours
$status-green-100: #3FAA19;
$status-dark-green-100: #307219;
$status-red-100: #F44336;
$status-blue-100: #428BFF;
$status-light-blue-100: #23BBDD;
$status-purple-100: #935DD7;
$status-gray-100: #1A1A26;
$status-light-blue-100: #23BBDD;
$status-orange-100: #DF8D00;

$status-green-06: rgba($status-green-100, $opacity-06);
$status-red-06: rgba($status-red-100, $opacity-06);
$status-blue-06: rgba($status-blue-100, $opacity-06);
$status-light-blue-06: rgba($status-light-blue-100, $opacity-06);
$status-purple-06: rgba($status-purple-100, $opacity-06);
$status-gray-06: rgba($status-gray-100, $opacity-06);
$status-light-blue-06: rgba($status-light-blue-100, $opacity-06);

$priority-colors: (
  "critical": #F44336,
  "high": #FF9900,
  "medium": #F6CF00,
  "low": #A6A6A6,
);

$theme-colors: (
  "primary": $primary-100,
  "secondary": $secondary-100,
  "chart": $graphs-100,
  "black": $black-100,
  "gray-dark": $gray-100,
  "light-gray": $background-100,
  "white": $white-100,
  "green": $status-green-100,
  "red": $status-red-100,
  "blue": $status-blue-100,
  "purple": $status-purple-100,
);

$scrollbar: (
  'track': #f1f1f1,
  'thumb': #c1c1c1,
  'thumb-hover': #a8a8a8
);

$opacity-collection: (
  "opacity-100": $opacity-100,
  "opacity-87": $opacity-87,
  "opacity-60": $opacity-60,
  "opacity-38": $opacity-38,
  "opacity-24": $opacity-24,
  "opacity-16": $opacity-16,
  "opacity-12": $opacity-12,
  "opacity-08": $opacity-08,
  "opacity-06": $opacity-06,
  "opacity-04": $opacity-04,
  "opacity-02": $opacity-02,
);

@mixin bd-palette-for-property-with-opacity($selector, $property) {
  @each $color-name, $color-value in $theme-colors {
    @each $opacity-name, $opacity-value in $opacity-collection {
      #{$selector}.#{$color-name}.#{$opacity-name} {
        #{$property}: colorWithOpacity($color-value, $opacity-value);
      }
    }
  }
}

@mixin bd-palette-for-property($selector, $property) {
  @each $color-name, $color in $theme-colors {
    #{$selector}.#{$color-name} {
      #{$property}: #{$color};
    }
  }
}

@function colorWithOpacity($color, $opacity) {
  $result: decToHex(round($opacity * 255));
  $length: str-length($result);
  @return unquote($color + if($length == 2, $result, '0' + $result));
}

@function decToHex($dec) {
  $hex: "0123456789ABCDEF";
  $first: math.div($dec - $dec % 16, 16)+1;
  $second: ($dec % 16)+1;
  @return str-slice($hex, $first, $first) + str-slice($hex, $second, $second);
}
