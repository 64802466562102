@import "@mdi/font/css/materialdesignicons.min.css";
@import "bootstrap/dist/css/bootstrap-grid.min.css";
@import "flag-icon-css/css/flag-icon.min.css";

@import "./fonts/roboto";
@import "./fonts/poppins";
@import "./fonts/lato";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@import "./styles/abstract/mixins";

@import "./styles/base/fixes";

@import "./styles/base/reset";

@import "./styles/helpers/actions";
@import "./styles/helpers/layout";
@import "./styles/helpers/colors-classes";
@import "./styles/components/icon-hover";
@import "./styles/components/gojs";

/* important to first import the default theme since the dark one uses its typography */
@import "./styles/themes/default";
@import "./styles/themes/dark";
