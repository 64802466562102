@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Poppins Black'), url('/assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Poppins BlackItalic'), url('/assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins BoldItalic'), url('/assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Poppins ExtraBold'), url('/assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local('Poppins ExtraBoldItalic'), url('/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Poppins ExtraLight'), url('/assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Poppins ExtraLightItalic'), url('/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Italic'), url('/assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Poppins Light'), url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Poppins LightItalic'), url('/assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins MediumItalic'), url('/assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBoldItalic'), url('/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Poppins Thin'), url('/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Poppins ThinItalic'), url('/assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}
