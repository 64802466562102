@use '../components/colors' as colors;

.color-background-100 {color: colors.$background-100}
.color-background-local {color: colors.$background-local}
.color-background-row {color: colors.$background-row}

.color-white-100 {color: colors.$white-100}
.color-white-08 {color: colors.$white-08}

.color-gray-100 {color: colors.$gray-100}
.color-gray-87 {color: colors.$gray-87}
.color-gray-60 {color: colors.$gray-60}
.color-gray-38 {color: colors.$gray-38}
.color-gray-16 {color: colors.$gray-16}
.color-gray-06 {color: colors.$gray-06}

.color-black-100 {color: colors.$black-100}
.color-black-87 {color: colors.$black-87}
.color-black-60 {color: colors.$black-60}
.color-black-38 {color: colors.$black-38}
.color-black-16 {color: colors.$black-16}
.color-black-12 {color: colors.$black-12}
.color-black-08 {color: colors.$black-08}
.color-black-06 {color: colors.$black-06}
.color-black-04 {color: colors.$black-04}
.color-black-02 {color: colors.$black-02}
