@use '@angular/material' as mat;

.flo-chip {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
  display: inline-block;
  padding: 0 10px;
}

.flo-chip-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  margin: -1px 10px -1px -11px;
  width: 32px;
  height: 32px;
}

@mixin flo-chip($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $add-button-background-color: mat.get-color-from-palette($accent, 500);
  $chip-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $close-icon-chip-color: mat.get-color-from-palette($foreground, hint-text);

  .flo-chip-v2 {
    .mdc-evolution-chip-set__chips {
      align-items: center;
    }

    .mdc-evolution-chip__text-label {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
     background-color: mat.get-color-from-palette($background, card);
      border: 1px solid mat.get-color-from-palette($foreground, divider);
      --mdc-chip-label-text-color: #{$chip-text-color};
      --mdc-chip-container-height: 26px;
      min-height: 26px;
      @extend .table-filter-selected-chip;

      &::after {
        background: transparent;
      }

      .mat-mdc-chip-remove {
        color: $close-icon-chip-color;
        opacity: 1;
      }
    }

    .add-new-chip-button {
      border: none;
      background-color: $add-button-background-color;
      height: 22px;
      width: 22px;
      padding: 0;
      border-radius: 4px;
      cursor: pointer;

      mat-icon {
        height: 22px;
        width: 22px;
        color: mat.get-color-from-palette($primary, 50);
      }
    }
  }
}
