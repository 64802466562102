@use '@angular/material' as mat;
@use './colors' as colors;

@mixin invoices-component($theme) {
  $foreground: map-get($theme, foreground);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  .invoices-list {
    padding: 0 !important;
    overflow: hidden;

    bd-lazy-table {
      box-shadow: none;
      border: none;

      .default-table-header {
        border-top: 1px solid colors.$black-16;
      }

      .mat-header-cell {
        border-bottom: 1px solid colors.$gray-38 !important;
      }

      .hidden-table,
      .default-table-header__row_head {
        display: none !important;
      }
    }

    .download-column-container a {
      cursor: pointer;
      color: colors.$gray-60;
    }
    .download-column-container > mat-icon {
      color: rgba(colors.$gray-100, colors.$opacity-24);
    }
  }
}
