@use '@angular/material' as mat;
@use 'colors';

@mixin drag-and-drop-component($theme) {
  $primary-theme: map-get($theme, primary);
  $accent-theme: map-get($theme, accent);
  $warn-theme: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $cloud-color: mat.get-color-from-palette($foreground, divider);
  $background-default: map-get($primary-theme, 450);
  $background-active: map-get($accent-theme, 700);
  $background-reject: map-get($warn-theme, 50);
  $border-default: map-get($primary-theme, 150);
  $border-active: map-get($primary-theme, 250);
  $success: colors.$status-green-100;
  $reject: map-get($warn-theme, 500);


  .drag-and-drop-menu-container {
    #uploader__input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .uploader {
      background-color: white;
      border: 1px dashed colors.$black-16;
      box-sizing: border-box;
      border-radius: 6px;
      position: relative;
      height: 100%;
      width: 100%;
      min-height: 160px;
      color: colors.$black-38;

      .gap {
        gap: 4px;
      }

      &__label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 1em;

        .uploader__icon {
          margin-bottom: 10px;
          width: 45px;
          height: 30px;
        }

        .uploader__icon svg path {
          fill: colors.$black-16;
        }

        .uploader__title {
          margin-bottom: 0;
          white-space: nowrap;
          color: colors.$black-60;
        }

        .uploader__subtitle {
          margin-bottom: 0;
          color: colors.$black-38;
          font-weight: normal;
        }

        .description-divider {
          padding-top: 10px;
          margin-top: 10px;
          border-top: 1px solid colors.$black-08;
          color: colors.$black-60;
          display: inline-block;
          text-align: center;
        }
      }

      &--active {
        border-color: $border-active;
        background-color: $background-active;

        .uploader__icon {
          opacity: .8;
        }
      }

      &--reject {
        background-color: $background-reject;
      }
    }

    .button {
      padding: 10px 0 10px 10px;
      text-align: end;
    }

    .icon {
      text-align: center;
      width: 35px;
      height: 35px;
      font-size: 30px;
    }

    .success {
      color: $success;
    }

    .reject {
      color: $reject;
    }
  }

  .flo-dialog__content.p-0 {
    padding: 0 !important;

    .uploading-state {
      margin: 32px 0 14px !important;
    }
  }

  .dialog-content__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;

    mat-icon {
      height: 32px;
      width: 32px;
    }

    button {
      margin-top: 15px;

      span {
        margin-top: unset;
      }
    }

    span {
      margin-outside: 20px;
      margin-top: 8px;
      color: colors.$black-87;
      text-align: center;

      &.success-text {
        color: colors.$status-green-100;
        font-size: 13px;
      }

      &.error-text {
        color: colors.$status-red-100;
        font-size: 13px;
      }
    }
  }
}
