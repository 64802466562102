@use "projects/shared/src/assets/styles/components/colors" as colors;

.messaging__snack-bar-container {
  background-color: colors.$black-87;
  color: colors.$white-100;

  &--info {
    background-color: colors.$primary-100 !important;
  }

  &--success {
    background-color: colors.$status-green-100 !important;
  }

  &--error {
    background-color: colors.$status-red-100 !important;
  }
}
