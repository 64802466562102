.flo-form {

  &--toggle {
    height: 1.34375em + .75em + 0.84375em + 1em !important;
  }

  &--checkbox {
    height: 1.34375em + .75em + 0.84375em + 1em !important;
    line-height: 1.34375em + .75em + 0.84375em + 1em !important;
  }
}
