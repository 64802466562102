@font-face {
  font-family: 'Lato Black';
  src: url('/assets/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato BlackItalic';
  src: url('/assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato BoldItalic';
  src: url('/assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato HairLine';
  src: url('/assets/fonts/Lato/Lato-HairLine.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-HairLine.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato HairLineItalic';
  src: url('/assets/fonts/Lato/Lato-HairLineItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-HairLineItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Italic';
  src: url('/assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato LightItalic';
  src: url('/assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  src: url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}
