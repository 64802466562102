@mixin flo-dialog($theme) {
  $warn-theme: map-get($theme, warn);
  $warn-color: map-get($warn-theme, 500);

  .flo-dialog {
    h3.mat-mdc-dialog-title {
      margin-bottom: 8px;
    }

    .dialog-warning-icon {
      color: $warn-color;
    }

    &__dialog-warning-line {
      border-left: 12px solid $warn-color;

      .mdc-dialog__content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    &__header {
      margin-top: -34px;
      margin-bottom: -10px;
      margin-right: -10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-warn {
        color: $warn-color;
      }
    }

    &__divider {
      margin-left: -24px !important;
      margin-right: -24px !important;
    }

    &__content {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      align-items: stretch;
      padding: 16px 20px !important;
      position: relative;
      line-height: 24px !important;
    }

    &__actions {
      padding: 16px 8px !important;
    }

    &__section-label {
      &:first-child {
        margin: 0 0 24px 0 !important;
      }

      &:not(:first-child) {
        margin: 24px 0 !important;
      }
    }
  }
}
