.icon-hover {
  mat-icon, .mdi {
    visibility: hidden;
    font-size: 1.2em;
  }

  &:hover {
    mat-icon, .mdi {
      visibility: initial;
    }
  }
}
