@use '@angular/material' as mat;
@use 'projects/shared/src/assets/styles/components/colors' as colors;

@mixin flo-search-input-field($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $border-color: mat.get-color-from-palette($foreground, divider);
  $search_field-height: 36px;
  $search_field-width: 250px;
  $search-field-color: colors.$gray-38;

  .search {

    &__field {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      height: $search_field-height;
      width: $search_field-width;
      border: solid 1px $border-color;
      transition: border-color .1s ease-in;
      border-radius: 4px;
      background: mat.get-color-from-palette($background, card);

      mat-icon {
        color: $search-field-color;
      }

      &:focus {
        border-color: colors.$primary-100;
      }

      &:hover {
        border-color: colors.$primary-100;
      }
    }

    &__input {
      @extend .flo-thin-input-typography;
      width: 250px;
      background-color: unset;
      border: none;
      outline: none;
      padding: 5px;
      color: inherit;

      &::placeholder {
        color: $search-field-color;
      }
    }

    &__container {
      .search__close-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 16px;
          width: 16px;
          font-size: 16px;
          text-align: center;
        }
      }

      .default-table-header__button_default {
        &.search__search-icon {
          margin: 8px 8px 6px 9px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
