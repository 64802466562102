@use '@angular/material' as mat;
@use 'colors' as colors;

@mixin flo-mat-form-field($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary-color: mat.get-color-from-palette($primary, 500);

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .flo-slim-select-panel,
  .flo-date-range-selector {
    display: flex;
    flex-direction: column;

    div.mat-mdc-select-panel {
      padding-top: 0 !important;
    }

    .mat-mdc-select-panel {
      min-width: 112px;
      max-width: 280px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding-top: 0;
      padding-bottom: 0;
      max-height: 256px;
      border-radius: 4px;
      outline: 0;
    }

    .mat-mdc-form-field-hint {
      margin-bottom: 4px;
    }

    .mat-mdc-text-field-wrapper {
      height: 36px !important;
      padding-bottom: 0;

      .mat-mdc-floating-label {
        top: 50% !important;
      }

      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        font-size: 14px !important;
      }

      .mat-mdc-form-field-infix {
        padding-bottom: 16px !important;
      }

      .mdc-floating-label--float-above {
        margin-top: 12px !important;
      }
    }

    .mat-mdc-select {
      @extend .flo-thin-input-typography;
    }

    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
      border-color: colors.$black-12 !important;
    }

    .mdc-text-field--outlined {
      background: mat.get-color-from-palette($background, card);
    }

    .mat-mdc-form-field-infix {
      min-height: unset;
      display: flex;
      align-items: center;
      justify-content: stretch;
      padding: unset;
      padding-top: 0 !important;

      mat-select {
        transform: translateY(-2px);

        .mat-mdc-select-arrow-wrapper {
          transform: translateY(-1px) !important;
        }
      }
    }
  }

  .flo-textarea-panel {
    .mat-mdc-text-field-wrapper {
      height: auto !important;

    }
  }

  .flo-thin-picker-overlay {
    padding-top: 0 !important;

    .mat-mdc-option {
      min-height: 42px;
    }

    span.mdc-list-item__primary-text {
      @extend .flo-thin-input-typography;
    }
  }

  .mat-mdc-select-panel {
    padding-top: 0 !important;
  }

  .datepicker-hidden {
    display: flex;
    flex-direction: column;
    height: 0;
    justify-content: flex-start;
    overflow: hidden;
    width: 0;
  }

  .custom-pseudo-checkbox-mat-option {
    display: flex !important;
    align-items: center !important;
    padding-left: 16px;

    .mdc-checkbox__native-control {
      display: none;
    }

    .mdc-checkbox {
      padding: 0;
      margin-right: 8px;

      &__background {
        position: static;
      }

      .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        transform: scale(0.7);
      }
    }

    .mdc-form-field > label {
      padding-left: 0;
    }
  }
}
