@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto-Black'), url('/assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto-BlackItalic'), url('/assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto-Bold'), url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto-BoldItalic'), url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto-Italic'), url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto-Light'), url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto-LightItalic'), url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto-Medium'), url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto-MediumItalic'), url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto-Regular'), local(Roboto), url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto-Thin'), url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto-ThinItalic'), url('/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}
